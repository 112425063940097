<div class="website-checklist">

  <button mat-icon-button (click)="close.emit()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="website-checklist__brand-color"></div>

  <p class="website-checklist__welcome">Welcome {{ service.user$ | userDisplayname | async }}</p>

  <p class="website-checklist__message">Easily manage your fitness business with our app. Create your website, set up memberships, schedule classes and more.</p>

  <website-checklist-percentage
    [percentage$]="service.percentage$">
  </website-checklist-percentage>

  <div class="website-checklist__selection-list" *ngIf="service.websiteChecklistDrawerOpened$ | async">

    <website-checklist-selection
      [selections]="(service.websiteChecklist$ | async)! | filter: 'required'">
    </website-checklist-selection>

    <p class="website-checklist__selection-label">Optional</p>

    <website-checklist-selection
      [selections]="(service.websiteChecklist$ | async)! | filter: 'optional'"
      (completed)="service.completeSelection($event)">
    </website-checklist-selection>
    
  </div>
  
  <website-checklist-brand-image>
  </website-checklist-brand-image>

</div>
