import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { PushTitleHeight, ResetTitleHeight, ResetStateToDefault } from './application.action';

import { ApplicationStateModel } from './application.model';

export const stateStateDefaults: ApplicationStateModel = {
  titleHeights: []
};

@State<ApplicationStateModel>({
  name: 'application',
  defaults: stateStateDefaults
})

@Injectable()
export class ApplicationState implements NgxsOnInit {

  @Selector()
  static maxHeight(state: ApplicationStateModel): number {
    return Math.max(...state.titleHeights);
  }

  constructor(
  ) { }

  ngxsOnInit(): void {
  }

  @Action(PushTitleHeight)
  setMaxHeight(ctx: StateContext<ApplicationStateModel>, action: PushTitleHeight): void {
    const state = ctx.getState();
    const value = produce(state, draft => {
      draft.titleHeights.push(action.height)
    });
    ctx.patchState(value);
  }
  
  @Action(ResetTitleHeight)
  resetTitleHeight(ctx: StateContext<ApplicationStateModel>, action: ResetTitleHeight): void {
    const state = ctx.getState();
    const value = produce(state, draft => {
      draft.titleHeights = [];
    });
    ctx.patchState(value);
  }

  @Action(ResetStateToDefault)
  resetStateToDefault(ctx: StateContext<ApplicationStateModel>) {
    ctx.patchState(stateStateDefaults);
  }

}
