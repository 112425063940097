import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'selection-indicator',
  template: `
    <div
      class="website-checklist__selection-indicator" matRipple
      [ngClass]="{ completed }"
      (click)="selected.emit(completed)">
      <mat-icon>{{ completed ? 'check' : 'priority_high' }}</mat-icon>
    </div>
  `,
  styleUrls: ['./indicator.component.scss'],
})
export class IndicatorComponent {

  @Output() selected = new EventEmitter<boolean>();
  @Input() completed: boolean;

}
