import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService as FUserTrackingService } from '@angular/fire/analytics';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { AdminWebFeatureRootModule } from '@fitscovery/admin-web/feature-root';
import { AdminWebLogoutPluginProvider } from '@fitscovery/utils/ngxs-plugins/logout';
import { AuthTokenInterceptorModule } from '@fitscovery/auth/token-interceptor';
import { AzAppInsightsLoggerModule, SegmentLoggerModule, AuthTrackingService, UserTrackingService, PartnersTrackingService, PartnerWebsitesTrackingService, EventsTrackingService, MembershipsTrackingService, ReportsTrackingService, PaymentsTrackingService, BookingsTrackingService, WorkoutsTrackingService, NotificationsTrackingService } from '@fitscovery/utils/logger';

import { environment as env } from '../environments/environment';
import { APP_PROVIDER, AppProvider } from '@fitscovery/common/providers';
import { AngularFireRemoteConfigModule, SETTINGS as REMOTE_CONFIG_SETTINGS} from '@angular/fire/remote-config';

const MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  AngularFireAuthModule,
  AngularFireAnalyticsModule,
  MatSnackBarModule,
  AngularFireRemoteConfigModule
];

const LIBRARIES = [
  AdminWebFeatureRootModule
];

const PROVIDERS = [
  ScreenTrackingService,
  FUserTrackingService,
  AdminWebLogoutPluginProvider
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES,
    AngularFireModule.initializeApp(env.firebase),
    AuthTokenInterceptorModule.forRoot(env),
    GoogleTagManagerModule.forRoot({
      id: env.apiKeys.googleTagManager!.id,
      gtm_auth: env.apiKeys.googleTagManager!.authKey,
      gtm_preview: env.apiKeys.googleTagManager!.preview
    }),
    SegmentLoggerModule.forRoot({ app: 'admin', environment: env }),
    AzAppInsightsLoggerModule.forRoot(env.apiKeys.appInsights, [
      AuthTrackingService,
      UserTrackingService,
      PartnersTrackingService,
      PartnerWebsitesTrackingService,
      EventsTrackingService,
      MembershipsTrackingService,
      ReportsTrackingService,
      PaymentsTrackingService,
      BookingsTrackingService,
      WorkoutsTrackingService,
      NotificationsTrackingService
    ])
  ],
  providers: [
    ...PROVIDERS,
    { provide: APP_PROVIDER, useValue: { app: 'admin', environment: env } as AppProvider },
    { provide: REMOTE_CONFIG_SETTINGS, useValue: { minimumFetchIntervalMillis: 30000, fetchTimeoutMillis: 60000  } },
  ]
})
export class AppLibrariesModule { }
