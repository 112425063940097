import { Post } from '@fitscovery/posts/data-access';
import { Guid } from '@fitscovery/common/types';

export class CreatePost {
  static readonly type = '[Post] CreatePost';

  constructor(public post: Post) { }
}

export class DeletePost {
  static readonly type = '[Post] DeletePost';

  constructor(public postId: Guid | string) { }
}

export class GetPostById {
  static readonly type = '[Post] GetPostById';

  constructor(public postId: Guid | string) { }
}

export class GetPostsByUserId {
  static readonly type = '[Post] GetPostsByUserId';

  constructor(public userId: Guid | undefined, public pageNumber: number = 1, public pageSize: number = 5) { }
}

export class GetMemberPostsByPartnerId {
  static readonly type = '[Post] GetMemberPostsByPartnerId';

  constructor(public partnerId: Guid | string, public pageNumber: number, public pageSize: number) { }
}

export class GetUserPostsPointsByPartnerId {
  static readonly type = '[GetUserPostsPointsByPartnerIdResponse] GetUserPostsPointsByPartnerId';

  constructor(public partnerId: Guid | string, public startDate: Date | string, public endDate: Date | string) { }
}

export class UpdatePost {
  static readonly type = '[Post] UpdatePost';

  constructor(public post: Post) { }
}

export class GetPostsPointsByUserId{
  static readonly type = '[Get] GetPostsPointsByUserId';

  constructor(public userId: Guid | undefined) { }
}

export class GetPublicPostsByPartnerId {
  static readonly type = '[Post] GetPublicPostsByPartnerId';

  constructor(public partnerId: Guid | string, public pageNumber: number, public pageSize: number) { }
}
