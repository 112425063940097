import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { RouterState } from '@ngxs/router-plugin';
import { Observable, filter, takeUntil } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';

import { MenuOption } from '../../common';
import { PartnerOfferings, PartnerState } from '@fitscovery/partners/data-access';
import { SubscriptionStore } from '@fitscovery/common/classes';

import { SidenavOptionDialogComponent } from '../sidenav-option-dialog/sidenav-option-dialog.component';
import { opacityFade } from '@fitscovery/common/animations';

@Component({
  selector: 'fitscovery-sidenav-option',
  templateUrl: './sidenav-option.component.html',
  styleUrls: ['./sidenav-option.component.scss'],
  animations: [ opacityFade(350) ]
})
export class SidenavOptionComponent extends SubscriptionStore implements OnInit, OnDestroy {

  @Select(PartnerState.offerings) offerings$: Observable<PartnerOfferings>;
  @Select(RouterState.state) routerState$: Observable<RouterStateSnapshot>;

  @Input() data: MenuOption;
  @Input() isLast: boolean;

  isAllowNavigation: boolean;

  constructor(
    @Inject(WINDOW) private window: any,
    private router: Router,
    private dialog: MatDialog,
    private store: Store
  ) {
    super();
    
    this.isAllowNavigation = false;
  }

  ngOnInit(): void {
    this.offerings$.pipe(
      filter(Boolean),
      takeUntil(this.subscription$)
    ).subscribe(() => (this.isAllowNavigation = true));
  }

  ngOnDestroy() {
    this.destroySubscription();
  }

  optionClickEvent() {

    if (this.data?.isInProgress) {
      this.dialog.open(SidenavOptionDialogComponent, {
        data: this.data,
        maxWidth: 400
      });
      return;
    }
    
    if (this.data?.dialogPopUpType === 'how-to-guide') {
      this.window.open('https://www.fitscovery.com/how-it-works', '_blank');
      return;
    }
    
    if (this.data?.dialogPopUpType === 'view-your-website') {
      const partner = this.store.selectSnapshot(PartnerState.partner);
      this.window.open('https://'+partner.subdomain+'.fitscovery.com', '_blank');
      return;
    }
    this.router.navigate([ this.data.route ], {
      queryParamsHandling: 'merge'
    });

  }

}
