import { Component } from '@angular/core';

import { MaintenancePageService } from './../maintenance-page.service';
import { opacityFade } from '@fitscovery/common/animations';

@Component({
  selector: 'loading-screen',
  template: `
    <ng-container *ngIf="!(maintenancePage.loaded$ | async)">
      <div class="loading-screen" [@opacityFade]>
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
  `,
  styles: [`
    @use './abstracts/colors' as colors;

    :host .loading-screen {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: colors.$content_bg;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 100;
    }
  `],
  animations: [ opacityFade() ]
})
export class LoadingScreenComponent {
  
  constructor(
    public maintenancePage: MaintenancePageService
  ) { }

}
