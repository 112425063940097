import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { WebsiteChecklistBubbleService } from './website-checklist-bubble.service';

import { WebsiteChecklistBubbleComponent } from './website-checklist-bubble.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  MatButtonModule
];

const PROVIDERS = [
  WebsiteChecklistBubbleService
];

const COMPONENTS = [
  WebsiteChecklistBubbleComponent
];

@NgModule({
  imports: [
    ...MODULES
  ],
  providers: [
    ...PROVIDERS
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    WebsiteChecklistBubbleComponent
  ]
})
export class WebsiteChecklistBubbleModule {}
