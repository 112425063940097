import { Application } from './app-environment.interface';

export const appInsights: Application = {
  partner: '6837b508-7206-4988-97c7-ead92dbfbfb4',
  account: 'd1e7907c-0440-47c6-bb04-7094f89bd173',
  onboarding: 'b3461761-e625-461a-8cae-2714f4eba022',
  admin: '263b2d17-3438-4aa8-87a6-7ee43038fc3f',
  my: '',
  backoffice: '',
  embed: ''
};

export const segmentKey: Application = {
  partner: 'Iam7weBrmcaMHz6VUlqnfFDKZLi6hNhF',
  account: 'Iam7weBrmcaMHz6VUlqnfFDKZLi6hNhF',
  onboarding: 'Ss7CSsNrMG9HnHSXQFhwnRitlx9JJcPb',
  admin: 'M9UEKeG3mIBC5LnwWwzyDdRuJvuXzeNT',
  my: 'M9UEKeG3mIBC5LnwWwzyDdRuJvuXzeNT',
  backoffice: '',
  embed: ''
};

export const pushKey: Application = {
  partner: '',
  account: '',
  onboarding: '',
  admin: 'BALV6uTa98r-yWo6lnVGQymYT5tbOAy2EL-Pf2-2NmjmsCL12ZQdj9v67DhAHAD7PHMXDpfZCp8jQmLwwaVst2I',
  my: '',
  backoffice: '',
  embed: ''
};
