import { trigger, state, style, animate, transition } from '@angular/animations';

export const expand = trigger('expand', [
  state('collapsed', style({ height: 0, opacity: 0, overflow: 'hidden' })),
  state('expanded', style({ height: '*', opacity: 1, 'margin-top': '8px', overflow: 'hidden' })),
  transition('collapsed <=> expanded', animate('250ms ease-out'))
]);

export const flipState = trigger('flipState', [
  state('active', style({
    transform: 'rotateX(180deg)'
  })),
  state('inactive', style({
    transform: 'rotateX(0)'
  })),
  transition('active => inactive', animate('500ms ease-out')),
  transition('inactive => active', animate('500ms ease-in'))
]);
