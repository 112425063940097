import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'completionBarFill'
})
export class CompletionBarFillPipe implements PipeTransform {

  transform(percentage$: Observable<number>): Observable<Record<string, string>> {
    return percentage$.pipe(
      map((percent: number) => {
        return {
          width: `calc(${percent || 0}% - 6px)`,
          transition: 'all 0.5s ease'
        };
      })
    );
  }

}
