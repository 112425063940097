import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MenuOption } from '../../common';

@Component({
  selector: 'fitscovery-sidenav-option-dialog',
  templateUrl: './sidenav-option-dialog.component.html',
  styleUrls: ['./sidenav-option-dialog.component.scss']
})
export class SidenavOptionDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MenuOption,
    public ref: MatDialogRef<SidenavOptionDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

}
