import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { PartnerOfferings, PartnerState } from '@fitscovery/partners/data-access';
import { MenuOption } from './common';

@Injectable()
export class SidenavService {

  @Select(PartnerState.offerings) offerings$: Observable<PartnerOfferings>;

  private _menuOptions: MenuOption[];

  constructor() {
    this._menuOptions = <MenuOption[]>[
      
      { title: 'BROWSE', type: 'header', class: 'header' },
      
      {
        title: 'Dashboard',
        route: '/dashboard',
        icon: 'dashboard',
        type: 'menu'
      },
      {
        title: 'Manage Your Website',
        route: '/manage-website',
        icon: 'home',
        type: 'menu'
      },
      {
        title: 'View Your Website',
        route: '/',
        icon: 'launch',
        type: 'menu',
        dialogPopUpType: 'view-your-website'
      },
      {
        title: 'Analytics',
        route: '/analytics',
        icon: 'show_chart',
        type: 'menu'
      },
      {
        title: 'Integrations',
        route: '/integrations',
        icon: 'link',
        type: 'menu'
      },
      {
        title: 'Settings',
        route: '/settings',
        icon: 'settings',
        type: 'menu',
        class: 'last'
      },

      { title: 'YOUR OFFERINGS', type: 'header', class: 'header' },
      
      {
        title: 'Memberships',
        route: '/memberships',
        icon: 'badge',
        type: 'menu',
        isDialogPopUp: false
      },
      {
        title: 'Schedule',
        route: '/events',
        icon: 'event',
        type: 'menu'
      },
      {
        title: 'Attendance Check-in',
        route: '/attendance',
        icon: 'assignment_turned_in',
        type: 'menu'
      },
      {
        title: 'On-Demand Workouts',
        route: '/workouts',
        icon: 'fitness_center',
        type: 'menu'
      },
      {
        title: 'Direct Booking',
        route: '/bookings',
        icon: 'edit_calendar',
        type: 'menu',
        class: 'last'
      },

      { title: 'EXPLORE', type: 'header', class: 'header' },

      {
        title: 'How to Guide',
        route: '/',
        icon: 'auto_stories',
        type: 'menu',
        dialogPopUpType: 'how-to-guide'
      }

    ];
  }

  get menuOptions(): MenuOption[] {
    return this._menuOptions;
  }

}
