import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, ActionType, Store } from '@ngxs/store';

import { ApiAuthAction, AuthAction, AuthAzureAction, FirebaseAction, SessionAction } from '@fitscovery/auth/data-access';
import { AzureLoggerService } from '../azure-logger.service';
import { TrackingService } from '../tracking-service';

@Injectable()
export class AuthTrackingService extends TrackingService {

  constructor(
    store: Store,
    actions$: Actions,
    logger: AzureLoggerService
  ) {

    super(store, actions$, logger);
    
    const actions = [ ApiAuthAction.SignOut, AuthAction.HealthCheck, AuthAzureAction.SignUp, AuthAzureAction.UpdateUser, AuthAzureAction.ConfirmEmailVerification, AuthAzureAction.SendEmailVerification, FirebaseAction.SignUpWithPassword, FirebaseAction.SignInWithGoogle, FirebaseAction.SignInWithPassword, FirebaseAction.SignInWithFacebook, SessionAction.SetAccessToken, SessionAction.CreateSession, SessionAction.CheckSession, SessionAction.SignInWithCustomToken, SessionAction.Authenticate ] as ActionType[];

    actions.forEach((action: ActionType) => this.trackDispatch(action));
  }
 
}

@NgModule()
export class AuthProviderModule {
  constructor(
    @Optional() service: AuthTrackingService
  ) { }
}
