import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateInvoiceByAdminRequest,
  CreateInvoiceByAdminResponse,
  GetInvoicesByAdminRequest,
  GetInvoicesByAdminResponse,
  GetInvoicesCountByAdminRequest,
  GetInvoicesCountByAdminResponse,
} from '../dtos';
import { APP_ENVIRONMENT } from '../environment-injection';
import { Invoice } from '../models';

@Injectable()
export class ApiAdminInvoiceService {

  constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

  createInvoiceByAdmin(requestBody: CreateInvoiceByAdminRequest): Observable<CreateInvoiceByAdminResponse> {
    return this.http.post<CreateInvoiceByAdminResponse>(`${this.env.nodeApi.payments}/admin/invoices`, requestBody)
  }

  getInvoicesCountByAdmin(requestQuery: GetInvoicesCountByAdminRequest): Observable<number> {
    const query = qs.stringify(requestQuery);
    return this.http.get(`${this.env.nodeApi.payments}/admin/invoices/count${query ? `?${query}` : ''}`).pipe(
      map((result: Object) => {
        return (result as GetInvoicesCountByAdminResponse).data.count
      })
    )
  }

  getInvoicesByAdmin(requestQuery: GetInvoicesByAdminRequest): Observable<Invoice[]> {
    const query = qs.stringify(requestQuery);
    return this.http.get(`${this.env.nodeApi.payments}/admin/invoices${query ? `?${query}` : ''}`).pipe(
      map((result: Object) => {
        return (result as GetInvoicesByAdminResponse).data.invoices
      })
    )
  }
}
