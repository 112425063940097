import { Guid } from '@fitscovery/common/types';

export namespace PartnerMemberAction {

  export class GetEventActiveMembers {
    static readonly type = '[Memberships API] GetEventActiveMembers';
    constructor(public partnerId: Guid, public eventStart: Date, public numberOfTicket: number) { }
  }

}
