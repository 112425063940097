import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { SharedDummyImageModule } from '@fitscovery/shared/dummy-image';

import { UserPartnerDetailsComponent } from './user-partner-details.component';

const MODULES = [
  CommonModule,
  FlexLayoutModule,
  MatDividerModule,
  MatButtonModule,
  MatIconModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  SharedDummyImageModule
];

const COMPONENTS = [
  UserPartnerDetailsComponent
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    UserPartnerDetailsComponent
  ]
})
export class UserPartnerDetailsModule {}
