import { Guid } from "@fitscovery/common/types";

export enum PaymentMethodStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  ComingSoon = 'COMING_SOON',
}

export interface PartnerPaymentMethod {
  id: Guid;
  partnerId: Guid;
  paymentMethod: PaymentMethod
  paymentMethodId: Guid;
  updatedAt: string;
  createdAt: string;
}

export interface PaymentMethod {
	id: string;
  name: string;
  description: string;
	status: PaymentMethodStatus;
  parentPaymentMethodId: Guid;
  childPaymentMethods: PaymentMethod[];
  createdAt: string;
  updatedAt: string;
}
