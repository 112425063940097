<div
  class="website-checklist__selection"
  [ngClass]="{ first: isFirst }"
  *ngFor="let selection of selections;let i = index;let isFirst = first">

  <selection-indicator
    [completed]="selection.completed"
    (selected)="completed.emit({ item: selection.websiteChecklistItem, completed: $event })">
  </selection-indicator>

  <selection-header
    [selection]="selection">
  </selection-header>

  <selection-caret
    [visibility]="selection.visibility"
    (click)="selection.visibility = !selection.visibility">
  </selection-caret>

  <div
    class="website-checklist__selection-content"
    [@expand]="selection.visibility ? 'expanded' : 'collapsed'">

    <p class="website-checklist__selection-content-text">{{ selection.content }}</p>

    <div
      class="website-checklist__selection-content-video"
      [ngStyle]="{ 'background-image': 'url(' + (selection.youtubeId | thumbnail) + ')' }">
      <iframe
        title="YouTube video player" allowfullscreen
        [src]="selection.youtubeId | youtubeUrl">
      </iframe>
    </div>

    <selection-content-action
      [selection]="selection">
    </selection-content-action>

  </div>
</div>
