import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { AdminAppStateAction } from '@fitscovery/admin-web/shared/store';
import { Selection } from '../../../common';

@Component({
  selector: 'selection-content-action',
  template: `
    <div
      class="website-checklist__selection-content-action" matRipple
      (click)="navigate(selection.path)">
      <div class="website-checklist__selection-content-action-content">
        {{ selection.action }}
        <mat-icon>arrow_right</mat-icon>
      </div>
    </div>
  `,
  styleUrls: ['./content-action.component.scss'],
})
export class ContentActionComponent {

  @Input() selection: Selection;

  constructor(
    private router: Router,
    private store: Store
  ) { }

  navigate(path: string): void {
    setTimeout(() => {
      this.router.navigate([ path ], {
        queryParams: { [this.selection.queryName!]: this.selection.queryValue },
        queryParamsHandling: 'merge'
      });
      this.store.dispatch(new AdminAppStateAction.DrawerState(false, 'checklist'));
    }, 1000);
  }

}
