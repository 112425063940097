import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { catchError, Observable } from 'rxjs';

import { UserState } from '@fitscovery/user/data-access';
import { PartnerState } from '@fitscovery/partners/data-access';
import { AzureLoggerService } from './azure-logger.service';
import { APP_PROVIDER, AppProvider } from '@fitscovery/common/providers';
import { AZ_APP_INSIGHTS_LOGGER_HEADER_ERROR, AZ_APP_INSIGHTS_LOGGER_HEADER_KEY } from './common';

@Injectable()
export class TrackingInterceptor implements HttpInterceptor {

  constructor(
    @Inject(APP_PROVIDER) private appProvider: AppProvider,
    private store: Store,
    private logger: AzureLoggerService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const loggerHeader = request.headers.get(AZ_APP_INSIGHTS_LOGGER_HEADER_KEY);
    const instrumentationKey = this.appProvider.environment.apiKeys.appInsights;

    if (loggerHeader === instrumentationKey) {
      return this.logRequest(request, next);
    }

    return next.handle(request);
  }
  
  logRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const clone = request.clone({
      headers: request.headers.delete(AZ_APP_INSIGHTS_LOGGER_HEADER_KEY)
    });

    return next.handle(clone).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        const message = errorResponse.error.error.message;
        this.logger.trackException(
          message,
          {
            Name: `Error: ${AZ_APP_INSIGHTS_LOGGER_HEADER_ERROR}`,
            Payload: errorResponse.error.error,
            UserId: this.store.selectSnapshot(UserState.userId) || '',
            PartnerDomain: this.store.selectSnapshot(PartnerState.subdomain) || '',
            Status: errorResponse.error.error.code,
            ErrorMessage: message
          }
        );

        throw errorResponse;
      })
    );
  }

}
