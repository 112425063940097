<button [type]="type" [disabled]="disabled" [ngStyle]="{ 'border-radius': buttonStyles['border-radius'] }" matRipple [class]="elevation" [ngClass]="{ 'flex-button': flexButton }">
  <div [ngStyle]="buttonStyles" [class]="buttonStyle" [ngClass]="{ 'flex-button': flexButton, 'form-button': formButton, disabled: disabled }">
    <mat-icon *ngIf="!!iconLeft && !!!fontSet" [ngStyle]="buttonStyles._icon">{{ iconLeft }}</mat-icon>
    <mat-icon *ngIf="!!iconLeft && !!fontSet" [ngStyle]="buttonStyles._icon" [fontSet]="fontSet" [fontIcon]="fontIcon"></mat-icon>
    <span><ng-content></ng-content></span>
    <mat-icon *ngIf="!!iconRight && !!!fontSet" [ngStyle]="buttonStyles._icon">{{ iconRight }}</mat-icon>
    <mat-icon *ngIf="!!iconRight && !!fontSet" [ngStyle]="buttonStyles._icon" [fontSet]="fontSet" [fontIcon]="fontIcon"></mat-icon>
  </div>
</button>

