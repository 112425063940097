import { Guid } from "@fitscovery/common/types";

export namespace AdminRecurringPaymentActions {

  export class GetRecurringPaymentsCount {
    static readonly type = '[AdminRecurringPaymentActions] GetRecurringPaymentsCount';
    constructor(public readonly partnerId: Guid) {}
  }

  export class GetRecurringPayments {
    static readonly type = '[AdminRecurringPaymentActions] GetRecurringPayments';
    constructor(public readonly partnerId: Guid, public readonly loadMore?: boolean) {}
  }
}
