import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { UiButtonsModule } from '@fitscovery/ui/buttons';

import { SidenavComponent } from './sidenav.component';
import { SidenavOptionComponent, SidenavOptionDialogComponent } from './components';
import { UtilPipe, StatePipe, UpdateMenuOptionsPipe } from './common';

const MODULES = [
  CommonModule,
  RouterModule,
  FlexLayoutModule,
  MatIconModule,
  MatRippleModule,
  MatDialogModule,
  MatTooltipModule,
  MatProgressSpinnerModule
];

const LIBRARIES = [
  UiButtonsModule
];

const COMPONENTS = [
  SidenavComponent,
  SidenavOptionComponent,
  SidenavOptionDialogComponent,
];

const PIPES = [
  UtilPipe,
  StatePipe,
  UpdateMenuOptionsPipe
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES
  ],
  imports: [
    ...MODULES,
    ...LIBRARIES
  ],
  exports: [
    SidenavComponent
  ]
})
export class SidenavModule {}
