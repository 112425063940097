export * from './navbar.model';
export * from './navbar';

import { PartnerRolePipe } from './partner-role.pipe';
import { WebsiteUrlPipe } from './website-url.pipe';

export const pipes = [
  PartnerRolePipe,
  WebsiteUrlPipe
];
