import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, ActionType, Store } from '@ngxs/store';

import { BookingsActions } from '@fitscovery/bookings/data-access';
import { AzureLoggerService } from '../azure-logger.service';
import { TrackingService } from '../tracking-service';

@Injectable()
export class BookingsTrackingService extends TrackingService {

  constructor(
    store: Store,
    actions$: Actions,
    logger: AzureLoggerService
  ) {

    super(store, actions$, logger);
    
    const actions = [ BookingsActions.GetPartnerBooking,BookingsActions.GetUpcomingBookings,BookingsActions.UpdatePartnerBooking,BookingsActions.UpdateMemberBooking,BookingsActions.GetBookingsByUserId ] as ActionType[];

    actions.forEach((action: ActionType) => this.trackDispatch(action));
  }
 
}

@NgModule()
export class BookingsProviderModule {
  constructor(
    @Optional() service: BookingsTrackingService
  ) { }
}
