import { Guid } from '@fitscovery/common/types';
import { Event } from '../models';

export namespace EventsActions {

  export class GetEventById {
    static readonly type = '[Events API] GetEventById';
    constructor(public eventId: Guid) { }
  }

  export class GetEventByIdWithPartnerId {
    static readonly type = '[Events API] GetEventByIdWithPartnerId';
    constructor(public eventId: Guid, public partnerId: Guid) { }
  }

  export class GetEventByIdAndSubdomain {
    static readonly type = '[Events API] GetEventByIdAndSubdomain';
    constructor(public eventId: Guid, public subdomain: string) { }
  }

  export class GetEventsBySubdomain {
    static readonly type = '[Events API] GetEventsBySubdomain';
    constructor(public subdomain: string) { }
  }
  
  export class CreateEvent {
    static readonly type = '[Events API] CreateEvent';
    constructor(public event: Event) { }
  }
  
  export class UpdateEvent {
    static readonly type = '[Events API] UpdateEvent';
    constructor(public event: Event) { }
  }
  
  export class DuplicateEvent {
    static readonly type = '[Events API] DuplicateEvent';
    constructor(public event: Event) { }
  }
  
  export class CancelEvent {
    static readonly type = '[Events API] CancelEvent';
    constructor(public eventId: Guid, public partnerId: Guid) { }
  }
  
  export class DeleteEvent {
    static readonly type = '[Events API] DeleteEvent';
    constructor(public eventId: Guid, public partnerId: Guid) { }
  }
  
  export class GetAttendeesWithIdByEventId {
    static readonly type = '[Events API] GetAttendeesWithIdByEventId';
    constructor(public eventId: Guid) { }
  }
  
  export class AddEventAttendees {
    static readonly type = '[Events API] AddEventAttendees';
    constructor(public eventId: Guid, public userList: Guid[]) { }
  }
  
  export class UpdateEventAttendee {
    static readonly type = '[Events API] UpdateEventAttendee';
    constructor(public userId: Guid, public partnerId: Guid, public eventId: Guid, public isPresent: boolean) { }
  }
  
  export class UpdateAllEventAttendee {
    static readonly type = '[Events API] UpdateAllEventAttendee';
    constructor(public partnerId: Guid, public eventId: Guid, public isPresent: boolean) { }
  }
  
  export class DownloadCsvEventAttendees {
    static readonly type = '[Events API] DownloadCsvEventAttendees';
    constructor(public eventId: Guid, public partnerId: Guid) { }
  }

  export class BookNow {
    static readonly type = '[Events API] BookNow';
    constructor(public eventId: Guid, public quantity: number) { }
  }

  export class SoftReserve {
    static readonly type = '[Events API] SoftReserve';
    constructor(public eventId: Guid, public quantity: number) { }
  }

  export class CancelFreeEventSlot {
    static readonly type = '[Events API] CancelFreeEventSlot';
    constructor(public eventId: Guid) { }
  }

  export class CancelEventSlot {
    static readonly type = '[Events API] CancelEventSlot';
    constructor(public eventId: Guid) { }
  }

  export class RemoveSelectedEvent {
    static readonly type = '[Events API] RemoveSelectedEvent';
    constructor() { }
  }

  export class RemoveEventAttendees {
    static readonly type = '[Events API] RemoveEventAttendees';
    constructor() { }
  }
  
  export class GetLatestAttendeesByEventId {
    static readonly type = '[Events API] GetLatestAttendeesByEventId';
    constructor(public eventId: Guid) { }
  }

}
