
import { trigger, style, animate, transition } from '@angular/animations';

export const inOutAnimation = (height: number = 77.63) => trigger(
  'inOutAnimation', 
  [
    transition(
      ':enter', 
      [
        style({ height: 0, opacity: 0 }),
        animate(
          '0.3s ease-out',
          style({ height, opacity: 1 }) 
        )
      ]
    ),
    transition(
      ':leave', 
      [
        style({ height, opacity: 1 }),
        animate(
          '0.3s ease-in', 
          style({ height: 0, opacity: 0 })
        ),
      ]
    )
  ]
);

// TODO
export const inOutAnimationDynamic = (height: number = 77.63) => trigger(
  `inOutAnimation${height}`, 
  [
    transition(
      ':enter', 
      [
        style({ height: 0, opacity: 0 }),
        animate(
          '0.3s ease-out',
          style({ height, opacity: 1 }) 
        )
      ]
    ),
    transition(
      ':leave', 
      [
        style({ height, opacity: 1 }),
        animate(
          '0.3s ease-in', 
          style({ height: 0, opacity: 0 })
        ),
      ]
    )
  ]
);

// TODO: PREVENT THIS KIND OF IMPLEMENTATION
export const inOutAnimation20 = trigger(
  'inOutAnimation20', 
  [
    transition(
      ':enter', 
      [
        style({ height: 0, opacity: 0 }),
        animate(
          '0.3s ease-out',
          style({ height: 20, opacity: 1 }) 
        )
      ]
    ),
    transition(
      ':leave', 
      [
        style({ height: 20, opacity: 1 }),
        animate(
          '0.3s ease-in', 
          style({ height: 0, opacity: 0 })
        ),
      ]
    )
  ]
);
