import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SessionState } from '@fitscovery/auth/data-access';
import { AdminAppStateAction } from '@fitscovery/admin-web/shared/store';
import { environment } from 'apps/admin-web/src/environments/environment';

@Injectable()
export class WebsiteChecklistBubbleService {

  @Select(SessionState.authenticated) authenticated$: Observable<boolean>;

  constructor(
    private store: Store
  ) { }

  get production(): boolean {
    return environment.production === "true";
  }

  openWebsiteChecklistDrawer(): void {
    this.store.dispatch(new AdminAppStateAction.DrawerState(true, 'checklist'));
  }

}
