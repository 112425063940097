import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { tap } from 'rxjs';

import { ApiUserService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';

import { UserActions } from '../actions';
import { UserStateModel, User, UserResponse, FitnessConsumerProfile, FitnessConsumerProfileResponse, UserAndProfileResponse } from '../models';
import { PartnerRoleResponse, VendorAdminResponse } from '@fitscovery/user/data-access';
import { errorHandler } from '@fitscovery/common/providers';
import { patch, updateItem } from '@ngxs/store/operators';
import { Guid } from '@fitscovery/common/types';

export const userStateDefaults: UserStateModel = {
  info: null,
  fitnessConsumerProfile: null,
  partners: null
};

export const API_USER_STATE_TOKEN = new StateToken<UserStateModel>('api_user');

@State<UserStateModel>({
  name: API_USER_STATE_TOKEN,
  defaults: userStateDefaults
})

@Injectable()
export class UserState implements NgxsOnInit {

  @Selector()
  static loaded(state: UserStateModel): boolean {
    return !!state.info;
  }

  @Selector()
  static userId(state: UserStateModel): Guid {
    return state.info?.id!;
  }

  @Selector()
  static user(state: UserStateModel): User {
    return state.info!;
  }

  @Selector()
  static partners(state: UserStateModel): PartnerRoleResponse[] {
    return state.partners!;
  }

  @Selector()
  static fitnessConsumerProfile(state: UserStateModel): FitnessConsumerProfile {
    return state.fitnessConsumerProfile!;
  }

  @Selector()
  static hasVendorPartners(state: UserStateModel): boolean {
    return state.partners!?.length > 0;
  }

  @Selector()
  static userProfileInfo(state: UserStateModel): { user: User, profile: FitnessConsumerProfile } {
    return { user: state.info!, profile: state.fitnessConsumerProfile! }
  }

  constructor(
    private userService: ApiUserService,
    private snackbar: SnackbarService
  ) { }

  ngxsOnInit(): void {
  }

  @Action(UserActions.GetCurrentUser)
  getCurrentUser(ctx: StateContext<UserStateModel>) {
    return this.userService.getCurrentUser().pipe(
      errorHandler(this.snackbar),
      tap((response: UserResponse) => {
        const info = {              // TODO: Fix in the back end
          ...response.data[0],      // TODO: Fix in the back end
          firebaseId: (<any>response.data[0])['firestoreId'] 
        };                          // TODO: Fix in the back end
        delete (<any>info)['firestoreId'];
        ctx.patchState({ info })
      }),
    );
  }

  @Action(UserActions.GetUserPartnersByUserId)
  getUserPartners(ctx: StateContext<UserStateModel>, action: UserActions.GetUserPartnersByUserId) {
    return this.userService.getUserPartners(action.id).pipe(
      errorHandler(),
      tap((response: VendorAdminResponse) => ctx.patchState({ partners: response.data.partners })),
    );
  }

  @Action(UserActions.GetFitnessConsumerProfileByUserId)
  getFitnessConsumerProfileByUserId(ctx: StateContext<UserStateModel>, action: UserActions.GetFitnessConsumerProfileByUserId) {
    return this.userService.getFitnessConsumerProfileByUserId(action.id).pipe(
      errorHandler(this.snackbar),
      tap((response: FitnessConsumerProfileResponse) => ctx.patchState({ fitnessConsumerProfile: response.data }))
    );
  }

  @Action(UserActions.UpdateFitnessConsumerProfile)
  @ImmutableContext()
  updateFitnessConsumerProfile(ctx: StateContext<UserStateModel>, action: UserActions.UpdateFitnessConsumerProfile) {
    return this.userService.updateFitnessConsumerProfile(action.profile).pipe(
      errorHandler(this.snackbar),
      tap(() => ctx.setState((state: UserStateModel) => {
        state.fitnessConsumerProfile!.username = action.profile.username
        state.fitnessConsumerProfile!.isProfilePrivate = action.profile.isProfilePrivate
        return state;
      }))
    );
  }

  @Action(UserActions.UpdateUserPartnerLogoUrlById)
  updateUserPartnerLogoUrlById(ctx: StateContext<UserStateModel>, action: UserActions.UpdateUserPartnerLogoUrlById) {
    const partnerRole = ctx.getState().partners?.find(e => e.partnerData.id === action.id)!
    ctx.setState(patch<any>({
      partners: updateItem<any>(
        response => response?.partnerData.id === action.id,
        { ...partnerRole, partnerData: { ...partnerRole.partnerData, logoUrl: action.logoUrl } }
      )
    }));
  }
  
  @Action(UserActions.GetFitnessConsumerProfileByUsername)
  getFitnessConsumerProfileByUsername(ctx: StateContext<UserStateModel>, action: UserActions.GetFitnessConsumerProfileByUsername) {
    return this.userService.getFitnessConsumerProfileByUsername(action.username).pipe(
      errorHandler(this.snackbar),
      tap((response: UserAndProfileResponse) => ctx.patchState({ fitnessConsumerProfile: response.fitnessConsumerProfile, info: response.user }))
    );
  }

}
