import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { AddEventAttendeesResponse, EventResponse, CancelEventResponse, EventAttendeeResponse, EventDeleteResponse, EventsResponse, EventDuplicateResponse, Event } from '../models';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiEventService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getEventById(eventId: Guid): Observable<EventsResponse> {
    return this.http.get<EventsResponse>(`${this.env.azureFunctionsApi.events}/events/${eventId}`);
  }

  getEventByIdWithPartnerId(eventId: Guid, partnerId: Guid): Observable<EventsResponse> {
    return this.http.get<EventsResponse>(`${this.env.azureFunctionsApi.events}/events/${eventId}/partners/${partnerId}`);
  }

  getEventByIdAndSubdomain(eventId: Guid, subdomain: string): Observable<EventsResponse> {
    return this.http.get<EventsResponse>(`${this.env.azureFunctionsApi.events}/events/${eventId}/partners/subdomain/${subdomain}`);
  }

  createEvent(event: Event): Observable<EventResponse> {
    return this.http.post<EventResponse>(`${this.env.azureFunctionsApi.events}/${event.organizerId}/events`, event);
  }

  updateEvent(event: Event): Observable<EventResponse> {
    return this.http.put<EventResponse>(`${this.env.azureFunctionsApi.events}/events/${event.eventId}`, event);
  }

  duplicateEvent(event: Event): Observable<EventDuplicateResponse> {
    return this.http.post<EventDuplicateResponse>(`${this.env.azureFunctionsApi.events}/${event.organizerId}/events/${event.id}/duplicate`, event);
  }

  cancelEvent(eventId: Guid, partnerId: Guid): Observable<CancelEventResponse> {
    return this.http.put<CancelEventResponse>(`${this.env.azureFunctionsApi.events}/${partnerId}/events/${eventId}/cancel`, {
      eventId
    });
  }

  deleteEvent(eventId: Guid, partnerId: Guid): Observable<EventDeleteResponse> {
    return this.http.delete<EventDeleteResponse>(`${this.env.azureFunctionsApi.events}/${partnerId}/events/${eventId}`);
  }

  getAttendeesWithIdByEventId(eventId: Guid): Observable<EventAttendeeResponse> {
    return this.http.get<EventAttendeeResponse>(`${this.env.azureFunctionsApi.events}/events/${eventId}/attendees`);
  }

  addEventAttendees(eventId: Guid, userList: Guid[]): Observable<AddEventAttendeesResponse> {
    return this.http.post<AddEventAttendeesResponse>(`${this.env.azureFunctionsApi.events}/events/${eventId}/add-attendees`, {
      eventId, userList
    });
  }

  bookNow(eventId: Guid, quantity: number): Observable<EventAttendeeResponse> {
    return this.http.post<EventAttendeeResponse>(`${this.env.azureFunctionsApi.events}/events/${eventId}/attendees`, {
      quantity
    });
  }

  softReserve(eventId: Guid, quantity: number): Observable<EventAttendeeResponse> {
    return this.http.post<EventAttendeeResponse>(`${this.env.azureFunctionsApi.events}/events/paid/${eventId}/attendees`, {
      quantity
    });
  }

  cancelFreeEventSlot(eventId: Guid): Observable<EventAttendeeResponse> {
    return this.http.put<EventAttendeeResponse>(`${this.env.azureFunctionsApi.events}/events/free/${eventId}/attendees/cancel`, {});
  }

  cancelEventSlot(eventId: Guid): Observable<EventAttendeeResponse> {
    return this.http.put<EventAttendeeResponse>(`${this.env.azureFunctionsApi.events}/events/${eventId}/attendees/cancel`, {});
  }

  getLatestAttendeesByEventId(eventId: Guid): Observable<EventAttendeeResponse> {
    return this.http.get<EventAttendeeResponse>(`${this.env.azureFunctionsApi.events}/events/${eventId}/latest-attendees`);
  }

}
