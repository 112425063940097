import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';

import { User } from '@fitscovery/user/data-access';

@Pipe({
  name: 'userDisplayname'
})
export class UserDisplaynamePipe implements PipeTransform {

  transform(user$: Observable<User>): Observable<string> {
    return user$.pipe(
      map((user: User) => {
        return user?.firstName || user?.displayName?.split(' ')[0];
      })
    );
  }

}
