import { Pipe, PipeTransform } from '@angular/core';

import { websiteUrl } from './navbar';

@Pipe({
  name: 'websiteUrl'
})
export class WebsiteUrlPipe implements PipeTransform {

  transform(subdomain: string | null): string {
    return websiteUrl(subdomain || 'Fitscovery');
  }

}
