// import { inject } from '@angular/core'; Upgrade to Angular v14 or 15
import { Platform } from '@angular/cdk/platform';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

import { SnackbarService } from '@fitscovery/ui/snackbar';

// const auth = inject(AngularFireAuth); Upgrade to Angular v14 or 15
// const snackbar = inject(SnackbarService); Upgrade to Angular v14 or 15

export async function emailAndPasswordAuth(snackbar: SnackbarService, callback: () => Promise<void>): Promise<void> {
  try {
    await callback();
  } catch (error) {
    snackbar.openSnack({ message: error.message, duration: 5000 });
    throw new Error(error.message);
  }
}

export async function socialMediaPopUpOrRedirectAuth(
  provider: firebase.auth.AuthProvider,
  auth: AngularFireAuth,
  platform: Platform,
  snackbar: SnackbarService): Promise<void> {
  
  // const platform = inject(Platform); Upgrade to Angular v14 or 15
  // iOS: iPhone devices; Safari: Safari browsers; WebKit: MacOS browser engines
  const webkit = platform.IOS || platform.SAFARI || platform.WEBKIT;
  const authentication = webkit ? auth.signInWithPopup(provider) : auth.signInWithRedirect(provider);

  try {
    await authentication;
  } catch (error) {
    snackbar.openSnack({ message: error.message, duration: 5000 });
    throw new Error(error.message);
  }
}
