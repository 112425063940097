import { Guid } from '@fitscovery/common/types';

export namespace MemberAction {

  export class GetMemberByPartnerId {
    static readonly type = '[Memberships API] GetMemberByPartnerId';
    constructor(public partnerId: Guid, public memberId: Guid) { }
  }

}
