<div class="maintenance-page">

  <div class="maintenance-page--content" *ngIf="(service.maintenance$$ | async) as maintenance">

    <a href="https://www.fitscovery.com/">
      <img src="https://uploads-ssl.webflow.com/625446168230dd2faf65dfc1/628af9da3e6eda5cb9d39d91_Fitscovery%203x.png">
    </a>

    <h3 *ngIf="maintenance.title">{{ maintenance.title }}</h3>
    <span *ngIf="maintenance.description">{{ maintenance.description }}</span>
    <span *ngIf="maintenance.duration">{{ maintenance.duration }}</span>
    <span *ngIf="maintenance.date">{{ maintenance.date }}</span>

  </div>

</div>
