import { Injectable } from '@angular/core';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { catchError, from, map, Observable } from 'rxjs';
import { AdminMembershipInvoiceActions, AdminRecurringPaymentActions } from '../actions';
import { CreateInvoiceByAdminResponse } from '../dtos';
import { PaymentFor } from '../enums';
import { Invoice, RecurringPayment } from '../models';
import { ApiAdminInvoiceService, ApiAdminRecurringPaymentService } from '../services';

export interface ApiAdminMembershipInvoiceStateModel {
  membershipInvoicesCount: number;
  gettingMembershipInvoicesCount: boolean;
  membershipInvoices: Invoice[];
  gettingMembershipInvoices: boolean;
  recurringPaymentsCount: number;
  gettingRecurringPaymentsCount: boolean;
  recurringPayments: RecurringPayment[];
  gettingRecurringPayments: boolean;
  creatingMembershipInvoice: boolean;
}

export const adminMembershipInvoiceStateDefaults: ApiAdminMembershipInvoiceStateModel = {
  membershipInvoicesCount: 0,
  gettingMembershipInvoicesCount: true,
  membershipInvoices: [],
  gettingMembershipInvoices: true,
  recurringPaymentsCount: 0,
  gettingRecurringPaymentsCount: true,
  recurringPayments: [],
  gettingRecurringPayments: true,
  creatingMembershipInvoice: true,
};

export const API_ADMIN_MEMBERSHIP_INVOICE_STATE_TOKEN = new StateToken<ApiAdminMembershipInvoiceStateModel>('api_admin_membership_invoice');

@State<ApiAdminMembershipInvoiceStateModel>({
  name: API_ADMIN_MEMBERSHIP_INVOICE_STATE_TOKEN,
  defaults: adminMembershipInvoiceStateDefaults,
})
@Injectable()
export class AdminMembershipInvoiceState {
  @Selector()
  static membershipInvoicesCount(state: ApiAdminMembershipInvoiceStateModel): number {
    return state.membershipInvoicesCount;
  }

  @Selector()
  static gettingMembershipInvoicesCount(state: ApiAdminMembershipInvoiceStateModel): boolean {
    return state.gettingMembershipInvoicesCount;
  }

  @Selector()
  static membershipInvoices(state: ApiAdminMembershipInvoiceStateModel): Invoice[] {
    return state.membershipInvoices;
  }

  @Selector()
  static gettingMembershipInvoices(state: ApiAdminMembershipInvoiceStateModel): boolean {
    return state.gettingMembershipInvoices;
  }
  @Selector()
  static recurringPaymentsCount(state: ApiAdminMembershipInvoiceStateModel): number {
    return state.recurringPaymentsCount;
  }

  @Selector()
  static gettingRecurringPaymentsCount(state: ApiAdminMembershipInvoiceStateModel): boolean {
    return state.gettingRecurringPaymentsCount;
  }

  @Selector()
  static recurringPayments(state: ApiAdminMembershipInvoiceStateModel): RecurringPayment[] {
    return state.recurringPayments;
  }

  @Selector()
  static gettingRecurringPayments(state: ApiAdminMembershipInvoiceStateModel): boolean {
    return state.gettingRecurringPayments;
  }

  constructor(private apiAdminInvoiceService: ApiAdminInvoiceService, private apiAdminRecurringPaymentService: ApiAdminRecurringPaymentService, private snackbar: SnackbarService) { }

  @Action(AdminMembershipInvoiceActions.CreateMembershipInvoice)
  createMembershipInvoice(ctx: StateContext<ApiAdminMembershipInvoiceStateModel>, action: AdminMembershipInvoiceActions.CreateMembershipInvoice): Observable<CreateInvoiceByAdminResponse> {
    ctx.patchState({
      creatingMembershipInvoice: true,
    });

    return this.apiAdminInvoiceService.createInvoiceByAdmin({
      itemId: action.pmsId.toString(),
      payerEmail: action.email,
      paymentFor: PaymentFor.Membership,
    }).pipe(
      map((createInvoiceByAdminResponse: CreateInvoiceByAdminResponse) => {
  
        ctx.patchState({
          creatingMembershipInvoice: false,
        });
        
        return createInvoiceByAdminResponse;
      }),
      catchError((err) => {
        ctx.patchState({
          creatingMembershipInvoice: false,
        });
  
        throw err;
      })
    )
  }

  @Action(AdminMembershipInvoiceActions.GetMembershipInvoicesCount)
  getMembershipInvoicesCount(ctx: StateContext<ApiAdminMembershipInvoiceStateModel>, action: AdminMembershipInvoiceActions.GetMembershipInvoicesCount): Observable<number> {
    if (!action.partnerId) {
      ctx.patchState({
        membershipInvoicesCount: 0,
        gettingMembershipInvoicesCount: false,
      });
      return from(Promise.resolve(0));
    }

    ctx.patchState({
      gettingMembershipInvoicesCount: true,
    });

    return this.apiAdminInvoiceService
      .getInvoicesCountByAdmin({
        payment_for: PaymentFor.Membership,
        partner_id: action.partnerId.toString(),
      })
      .pipe(
        map((membershipInvoicesCount: number) => {
          ctx.patchState({
            membershipInvoicesCount,
            gettingMembershipInvoicesCount: false,
          });
          
          return membershipInvoicesCount;
        }),
        catchError((err) => {
          ctx.patchState({
            membershipInvoicesCount: 0,
            gettingMembershipInvoicesCount: false,
          });

          throw err;
        })
      );
  }

  @Action(AdminMembershipInvoiceActions.GetMembershipInvoices)
  getMembershipInvoices(ctx: StateContext<ApiAdminMembershipInvoiceStateModel>, action: AdminMembershipInvoiceActions.GetMembershipInvoices): Observable<Invoice[]> {
    if (action.loadMore && ctx.getState().gettingMembershipInvoices) {
      return from(Promise.resolve([]));
    }
    
    if (!action.partnerId) {
      ctx.patchState({
        membershipInvoices: [],
        gettingMembershipInvoices: false,
      });
      return from(Promise.resolve([]));
    }

    ctx.patchState({
      gettingMembershipInvoices: true,
    });

    return this.apiAdminInvoiceService
      .getInvoicesByAdmin({
        payment_for: PaymentFor.Membership,
        partner_id: action.partnerId.toString(),
        limit: '30',
        offset: action.loadMore ? ctx.getState().membershipInvoices.length.toString() : '0',
      })
      .pipe(
        map((membershipInvoices: Invoice[]) => {
          if (action.loadMore) {
            const currentMembershipInvoices = <Invoice[]>JSON.parse(JSON.stringify(ctx.getState().membershipInvoices))
            ctx.patchState({
              membershipInvoices: [
                ...currentMembershipInvoices,
                ...membershipInvoices,
              ],
              gettingMembershipInvoices: false,
            });
          } else {
            ctx.patchState({
              membershipInvoices,
              gettingMembershipInvoices: false,
            });
          }
          
          return membershipInvoices;
        }),
        catchError((err) => {
          ctx.patchState({
            membershipInvoices: [],
            gettingMembershipInvoices: false,
          });

          throw err;
        })
      );
  }

  @Action(AdminRecurringPaymentActions.GetRecurringPaymentsCount)
  getRecurringPaymentsCount(ctx: StateContext<ApiAdminMembershipInvoiceStateModel>, action: AdminRecurringPaymentActions.GetRecurringPaymentsCount): Observable<number> {
    if (!action.partnerId) {
      ctx.patchState({
        recurringPaymentsCount: 0,
        gettingRecurringPaymentsCount: false,
      });
      return from(Promise.resolve(0));
    }

    ctx.patchState({
      gettingRecurringPaymentsCount: true,
    });

    return this.apiAdminRecurringPaymentService
      .getRecurringPaymentsCountByAdmin({
        partner_id: action.partnerId.toString(),
      })
      .pipe(
        map((recurringPaymentsCount: number) => {
          ctx.patchState({
            recurringPaymentsCount,
            gettingRecurringPaymentsCount: false,
          });
          
          return recurringPaymentsCount;
        }),
        catchError((err) => {
          ctx.patchState({
            recurringPaymentsCount: 0,
            gettingRecurringPaymentsCount: false,
          });

          throw err;
        })
      );
  }

  @Action(AdminRecurringPaymentActions.GetRecurringPayments)
  getRecurringPayments(ctx: StateContext<ApiAdminMembershipInvoiceStateModel>, action: AdminRecurringPaymentActions.GetRecurringPayments): Observable<RecurringPayment[]> {
    if (action.loadMore && ctx.getState().gettingRecurringPayments) {
      return from(Promise.resolve([]));
    }
    
    if (!action.partnerId) {
      ctx.patchState({
        recurringPayments: [],
        gettingRecurringPayments: false,
      });
      return from(Promise.resolve([]));
    }

    ctx.patchState({
      gettingRecurringPayments: true,
    });

    return this.apiAdminRecurringPaymentService
      .getRecurringPaymentsByAdmin({
        partner_id: action.partnerId.toString(),
        limit: '30',
        offset: action.loadMore ? ctx.getState().recurringPayments.length.toString() : '0',
      })
      .pipe(
        map((recurringPayments: RecurringPayment[]) => {
          if (action.loadMore) {
            const currentRecurringPayments = <RecurringPayment[]>JSON.parse(JSON.stringify(ctx.getState().recurringPayments))
            ctx.patchState({
              recurringPayments: [
                ...currentRecurringPayments,
                ...recurringPayments,
              ],
              gettingRecurringPayments: false,
            });
          } else {
            ctx.patchState({
              recurringPayments,
              gettingRecurringPayments: false,
            });
          }
          
          return recurringPayments;
        }),
        catchError((err) => {
          ctx.patchState({
            recurringPayments: [],
            gettingRecurringPayments: false,
          });

          throw err;
        })
      );
  }
}