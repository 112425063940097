import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { User, UserState } from '@fitscovery/user/data-access';

@Component({
  selector: 'user-partner-details',
  templateUrl: './user-partner-details.component.html',
  styleUrls: ['./user-partner-details.component.scss']
})
export class UserPartnerDetailsComponent implements OnInit {

  @Select(UserState.user) user$: Observable<User>;

  @Output() logout = new EventEmitter();
  @Output() closeOverlay = new EventEmitter();

  @Input() overlayState: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
