import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';

const MODULES = [
  CommonModule,
  RouterModule,
  MatProgressSpinnerModule
];

const COMPONENTS = [
  MaintenancePageComponent
];

@NgModule({
  imports: [
    ...MODULES,
    RouterModule.forChild([{
      path: '', component: MaintenancePageComponent
    }])
  ],
  declarations: [
    ...COMPONENTS
  ]
})
export class SharedMaintenancePageModule {}
