import { trigger, style, animate, transition } from '@angular/animations';

export const opacityFade = (speed: number = 300) => trigger(
  `opacityFade`, 
  [
    transition(
      ':enter', 
      [
        style({ opacity: 0 }),
        animate(
          `${speed}ms ease-out`,
          style({ opacity: 1 }) 
        )
      ]
    ),
    transition(
      ':leave', 
      [
        style({ opacity: 1 }),
        animate(
          `${speed}ms ease-in`, 
          style({ opacity: 0 })
        ),
      ]
    )
  ]
);
