
export class RegexProvider {
  
  public static priceRegex: RegExp = /(^[0-9]{0,14}\.{1}[0-9]{0,2}$|^[0-9]{0,14}$)/;
  public static quantityRegex: RegExp = /(^[0-9]{0,10}$)/;

  public static email: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static fullName: RegExp = /^[-'a-zA-Z ]+$/;
  public static subdomain: RegExp = /^(([a-zA-Z0-9])([-]{0,1}))+$/;
  public static domain: RegExp = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
  public static url: RegExp = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  public static htmlTags: RegExp = /<[^>]+>/g;
  
  public static alpha: RegExp = /^[a-zA-Z ]+$/;
  public static numeric: RegExp = /^[0-9]+$/;
  public static alphaNumeric: RegExp = /^[a-zA-Z0-9 ]+$/;
  public static alphaNumericDashUnderscorePeriod: RegExp = /^[a-zA-Z0-9.\-_\s|\u00c0-\u01ff|\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]+$/;
  public static contactNumber: RegExp = /^[+0-9]+$/;
  public static accountNumber: RegExp = /^[0-9* ]+$/;
  
  // Internalization compatible
  public static alphaI18n: RegExp = /^[a-zA-Z\s|\u00c0-\u01ff|\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]+$/;
  public static alphaNumericI18n: RegExp = /^[a-zA-Z0-9\s|\u00c0-\u01ff|\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]+$/;
  public static alphaNumericSpecialI18n: RegExp = /^[a-zA-Z0-9,.<>?/\\:;'"\{\}\[\]\-`~_=+!@#$%^&*\(\)\s|\u00c0-\u01ff|\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]+$/;

  // Utilities
  public static specialCharacters: RegExp = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;

}
