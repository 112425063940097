import { Pipe, PipeTransform } from '@angular/core';
import { PartnerChecklist } from '@fitscovery/partners/data-access';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(checklist: PartnerChecklist[], value: string): any[] {
    switch (value) {
      case 'required': return this.required(checklist);
      case 'optional': return this.optional(checklist);
      default:         return this.required(checklist);
    }
  }

  required(items: PartnerChecklist[]): PartnerChecklist[] {
    return items.filter((checklist: PartnerChecklist) => {
      return checklist.websiteChecklistItem !== 'CREATE_WORKOUT'
       && checklist.websiteChecklistItem !== 'ACTIVATE_DIRECT_BOOKING'
    })
  }

  optional(items: PartnerChecklist[]): PartnerChecklist[] {
    return items.filter((checklist: PartnerChecklist) => {
      return checklist.websiteChecklistItem === 'CREATE_WORKOUT'
        || checklist.websiteChecklistItem === 'ACTIVATE_DIRECT_BOOKING'
    })
  }

}
