import { Workout } from './workout.model';
import { Guid } from '@fitscovery/common/types';

export class Program {
	id: string;
	description: string;
	title: string;
	isForMembersOnly: boolean;
	isDisplayed: boolean;
	mediaUrl: string;
	partnerId: Guid;
	partner: any;
	isArchived: boolean;
	programSections: ProgramSection[];
  orderNumber?: number;
}

export class WorkoutContent extends Workout {
	programSectionWorkoutId: string;
	override orderNumber: number;
	public programSectionWorkoutHasCompleted: boolean;
}

export class ProgramSection {
	id: string;
	title: string;
	description: string;
	orderNumber: number;
	workouts: WorkoutContent[];
}
