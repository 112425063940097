import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken, createSelector } from '@ngxs/store';
import { insertItem, patch } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import * as _ from 'lodash';

import { ApiPartnerMembershipService, ApiPartnerService, ApiMembershipService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { PartnerMembershipAction } from '../actions';
import { PartnerMembershipsStateModel, MembershipSubscription, Membership, ApplyMembershipResponse } from '../models';
import { Guid } from '@fitscovery/common/types';
import { MembershipStatus } from '../enums';
import { errorHandler } from '@fitscovery/common/providers';

export const partnerMembershipsStateDefaults: PartnerMembershipsStateModel = {
	subscriptions: null,
  members: null,
  loaded: false
};

export const PARTNER_MEMBERSHIPS_STATE_TOKEN = new StateToken<PartnerMembershipsStateModel>('partnerMemberships');

function findSubscription(memberships: Membership[], subscriptionId: Guid): Membership {
  return memberships.find((membership: Membership) => {
    return membership.partnerMembershipSubscriptionId.toString() === subscriptionId.toString()
  })!;
}

@State<PartnerMembershipsStateModel>({
	name: PARTNER_MEMBERSHIPS_STATE_TOKEN,
	defaults: partnerMembershipsStateDefaults
})

@Injectable()
export class PartnerMembershipsState implements NgxsOnInit {

	@Selector()
	static subscriptions(state: PartnerMembershipsStateModel): MembershipSubscription[] | null {
		return state.subscriptions;
	}

	@Selector()
	static activeSubscriptions(state: PartnerMembershipsStateModel): MembershipSubscription[] {
    const subscriptions = state.subscriptions?.filter(s => s.isActive && s.isDisplayed) ?? null;
    return subscriptions ? _.orderBy(subscriptions, ['orderNumber'], ['asc']) : [];
	}

	@Selector()
	static members(state: PartnerMembershipsStateModel): Membership[] | null {
		return state.members;
	}

	@Selector()
	static hasActiveMember(state: PartnerMembershipsStateModel): boolean {
		return state.members!.some(f => f.status === MembershipStatus.Active);
	}

  static subscription(subscriptionId: Guid): (state: PartnerMembershipsStateModel) => Membership {
    return createSelector([PartnerMembershipsState], (state: PartnerMembershipsStateModel): Membership => {
      return findSubscription(state.members!, subscriptionId);
    });
  }

  static subscriptionStatus(subscriptionId: Guid): (state: PartnerMembershipsStateModel) => MembershipStatus {
    return createSelector([PartnerMembershipsState], (state: PartnerMembershipsStateModel): MembershipStatus => {
      return findSubscription(state.members!, subscriptionId)?.status;
    });
  }

	constructor(
    private snackbar: SnackbarService,
    private partnerMembershipService: ApiPartnerMembershipService,
    private partnerService: ApiPartnerService,
    private membershipService: ApiMembershipService
  ) { }

	ngxsOnInit(): void {
	}

  @Action(PartnerMembershipAction.GetActiveMembershipSubscriptionsByPartnerId)
  getActiveMembershipSubscriptionsByPartnerId(ctx: StateContext<PartnerMembershipsStateModel>, action: PartnerMembershipAction.GetActiveMembershipSubscriptionsByPartnerId) {
    return this.partnerMembershipService.getActiveMembershipSubscriptionsByPartnerId(action.partnerId).pipe(
      errorHandler(this.snackbar),
      tap((subscriptions: MembershipSubscription[]) => ctx.patchState({ subscriptions, loaded: true }))
    )
  }

  @Action(PartnerMembershipAction.GetMembershipsByPartnerIdAndMemberId)
  getMembershipsByPartnerIdAndMemberId(ctx: StateContext<PartnerMembershipsStateModel>, action: PartnerMembershipAction.GetMembershipsByPartnerIdAndMemberId) {
    return this.partnerService.getMembershipsByPartnerIdAndMemberId(action.partnerId, action.memberId).pipe(
      errorHandler(this.snackbar),
      tap((members: Membership[]) => ctx.patchState({ members }))
    )
  }

  @Action(PartnerMembershipAction.ApplyDefaultMembership)
  applyDefaultMembership(ctx: StateContext<PartnerMembershipsStateModel>, action: PartnerMembershipAction.ApplyDefaultMembership) {
    return this.membershipService.applyDefaultMembership(action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: ApplyMembershipResponse) => ctx.setState(
        patch<any>({ members: insertItem(response.data) })
      ))
    )
  }

  @Action(PartnerMembershipAction.ApplyMembership)
  applyMembership(ctx: StateContext<PartnerMembershipsStateModel>, action: PartnerMembershipAction.ApplyMembership) {
    return this.membershipService.applyMembership(action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: ApplyMembershipResponse) => ctx.setState(
        patch<any>({ members: insertItem(response.data) })
      ))
    )
  }

}
