
import { FormGroup } from '@angular/forms';
import { RegexFactory } from '@fitscovery/common/providers';

export class FormErrorValidatorLogger {

  private _formControls: string[];
  private _form: FormGroup;

  constructor(
    formControls: string[],
    form: FormGroup
  ) {
    this._formControls = formControls;
    this._form = form;
  }

  get mapControlErrors(): any {

    const controlObj = {} as any;

    this._formControls.forEach((formControl: string) => {
      
      const errors = this._form.get(formControl)!.errors;

      if (!errors) return null;

      controlObj[formControl] = {};
      const validators = Object.entries(errors);
      
      validators.forEach((validator: any[]) => {
        const error = validator[0];
        const config = validator[1];
        const errorMessage = (RegexFactory as any)[error](config);
        
        controlObj[formControl][error] = errorMessage;
      });
      return undefined;
    });

    return this.errorsFirstKey(controlObj);
  }

  getFirstKeyMessage(obj: any): any {
    return (Object.values(
      (Object.values(obj)[0] as any)
    )[0] as any).message;
  }

  private errorsFirstKey(controlErrors: any) {

    if (!!!controlErrors) return null;

    const controlObj = {} as any;

    this._formControls.forEach((formControl: string) => {
      const errors = controlErrors[formControl];
      controlObj[formControl] = this.getFirstKey(errors);
    });
    
    return this.getFirstKey(
      this.cleanObject(controlObj)
    );
  }

  getFirstKey(obj: any) {
    if (!!!obj) return null;
    const objFirstKeyEntry = [ Object.entries(obj)[0] ];
    return Object.fromEntries(objFirstKeyEntry);
  }

  private cleanObject(obj: any) {
    for (const propName in obj)
      if (obj[propName] === null || obj[propName] === undefined)
        delete obj[propName];
    return this.parseEmptyObject(obj);
  }

  private parseEmptyObject(obj: any) {
    return JSON.stringify(obj) === '{}' ? null : obj;
  }

}