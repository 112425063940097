import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { catchError, from, map, Observable } from 'rxjs';
import { MyWebInvoiceActions } from '../actions';
import { Invoice } from '../models';
import { ApiInvoiceService } from '../services';

export interface ApiMyWebInvoiceStateModel {
  invoicesCount: number;
  gettingInvoicesCount: boolean;
  invoices: Invoice[];
  gettingInvoices: boolean;
  limit: number;
}

export const apiMyWebInvoicesStateDefaults: ApiMyWebInvoiceStateModel = {
  invoicesCount: 0,
  gettingInvoicesCount: true,
  invoices: [],
  gettingInvoices: true,
  limit: 5,
};

export const API_MY_WEB_INVOICE_STATE_TOKEN = new StateToken<ApiMyWebInvoiceStateModel>('api_my_web_invoice');

@State<ApiMyWebInvoiceStateModel>({
  name: API_MY_WEB_INVOICE_STATE_TOKEN,
  defaults: apiMyWebInvoicesStateDefaults,
})
@Injectable()
export class MyWebInvoiceState {
  @Selector()
  static invoicesCount(state: ApiMyWebInvoiceStateModel): number {
    return state.invoicesCount;
  }

  @Selector()
  static gettingInvoicesCount(state: ApiMyWebInvoiceStateModel): boolean {
    return state.gettingInvoicesCount;
  }

  @Selector()
  static invoices(state: ApiMyWebInvoiceStateModel): Invoice[] {
    return state.invoices;
  }

  @Selector()
  static gettingInvoices(state: ApiMyWebInvoiceStateModel): boolean {
    return state.gettingInvoices;
  }

  constructor(private apiInvoiceService: ApiInvoiceService) { }


  @Action(MyWebInvoiceActions.GetInvoicesCount)
  getMembershipInvoicesCount(ctx: StateContext<ApiMyWebInvoiceStateModel>): Observable<number> {
    ctx.patchState({
      gettingInvoicesCount: true,
    });

    return this.apiInvoiceService
      .getInvoicesCount({
        paid_at: {
          null: 'false'
        }
      })
      .pipe(
        map((invoicesCount: number) => {
          ctx.patchState({
            invoicesCount,
            gettingInvoicesCount: false,
          });
          
          return invoicesCount;
        }),
        catchError((err) => {
          ctx.patchState({
            invoicesCount: 0,
            gettingInvoicesCount: false,
          });

          throw err;
        })
      );
  }

  @Action(MyWebInvoiceActions.GetInvoices)
  getMembershipInvoices(ctx: StateContext<ApiMyWebInvoiceStateModel>, action: MyWebInvoiceActions.GetInvoices): Observable<Invoice[]> {
    if (action.loadMore && ctx.getState().gettingInvoices) {
      return from(Promise.resolve([]));
    }
    
    ctx.patchState({
      gettingInvoices: true,
    });

    return this.apiInvoiceService
      .getInvoices({
        paid_at: {
          null: 'false'
        },
        limit: ctx.getState().limit.toString(),
        offset: action.loadMore ? ctx.getState().invoices.length.toString() : '0',
      })
      .pipe(
        map((invoices: Invoice[]) => {
          if (action.loadMore) {
            const currentMembershipInvoices = <Invoice[]>JSON.parse(JSON.stringify(ctx.getState().invoices))
            ctx.patchState({
              invoices: [
                ...currentMembershipInvoices,
                ...invoices,
              ],
              gettingInvoices: false,
            });
          } else {
            ctx.patchState({
              invoices,
              gettingInvoices: false,
            });
          }
          
          return invoices;
        }),
        catchError((err) => {
          ctx.patchState({
            invoices: [],
            gettingInvoices: false,
          });

          throw err;
        })
      );
  }
}
