import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'website-checklist-percentage',
  template: `

    <div class="website-checklist__completion">
      
      <p class="website-checklist__percentage">{{ percentage$ | completionPercentage | async }}</p>
      <div class="website-checklist__bar">
        <div
          class="website-checklist__bar-fill"
          [ngStyle]="percentage$ | completionBarFill | async">
        </div>
      </div>

    </div>

  `,
  styleUrls: ['./percentage.component.scss'],
})
export class PercentageComponent {

  @Input() percentage$: Observable<number>;

}
