import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

import { UiButtonsModule } from '@fitscovery/ui/buttons';

import { SocialMediaAuthEmailDialogService } from './social-media-auth-email-dialog/social-media-auth-email-dialog.service';
import { SocialMediaAuthEmailDialogEventService } from './social-media-auth-email-dialog-event.service';

import { SocialMediaAuthEmailDialogComponent } from './social-media-auth-email-dialog/social-media-auth-email-dialog.component';
import { AuthNoEmailObserverDirective } from './auth-no-email-observer.directive';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatIconModule,
  FlexLayoutModule,
  MatInputModule
];

const LIBRARIES = [
  UiButtonsModule
];

const PROVIDERS = [
  SocialMediaAuthEmailDialogService,
  SocialMediaAuthEmailDialogEventService
];

const COMPONENTS = [
  SocialMediaAuthEmailDialogComponent
];

const DIRECTIVES = [
  AuthNoEmailObserverDirective
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES
  ],
  providers: [
    ...PROVIDERS
  ],
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  exports: [
    AuthNoEmailObserverDirective
  ]
})
export class AuthSocialMediaAuthEmailDialogModule {
  static forRoot(): ModuleWithProviders<AuthSocialMediaAuthEmailDialogModule> {
    return {
      ngModule: AuthSocialMediaAuthEmailDialogModule,
      providers: [
        SocialMediaAuthEmailDialogEventService
      ]
    };
  }
}
