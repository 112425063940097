import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { AccountsAction } from '@fitscovery/auth/data-access';
import { SocialMediaAuthEmailDialogService } from './social-media-auth-email-dialog.service';

@Component({
  selector: 'fitscovery-social-media-auth-email-dialog',
  templateUrl: './social-media-auth-email-dialog.component.html',
  styleUrls: ['./social-media-auth-email-dialog.component.scss'],
})
export class SocialMediaAuthEmailDialogComponent implements OnInit {

  constructor(
    private ref: MatDialogRef<SocialMediaAuthEmailDialogComponent>,
    private store: Store,
    public service: SocialMediaAuthEmailDialogService
  ) { }

  ngOnInit(): void {
  }

  async submit(): Promise<void> {
    if (this.service.form.invalid) return;
    try {
      await firstValueFrom(this.store.dispatch(new AccountsAction.UpdateEmail(this.service.form.value.email)));
      this.ref.close();
    } catch (error) { }
  }

}
