import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToLocalTimePipeModule } from '@fitscovery/common/pipes';
import { FeatureNotificationButtonComponent } from './feature-notification-button/feature-notification-button.component';

const MODULES = [
  CommonModule,
  FlexLayoutModule,
  MatIconModule,
  MatMenuModule,
  MatBadgeModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  ToLocalTimePipeModule
];

@NgModule({
  imports: [...MODULES],
  declarations: [FeatureNotificationButtonComponent],
  exports: [FeatureNotificationButtonComponent],
})
export class NotificationFeatureNotificationButtonModule {}
