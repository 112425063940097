import { Directive, Input, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[sizeObserver]'
})
export class SizeObserverDirective implements AfterViewInit {

  @Input() sizeObserver: string;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit(): void {
    if (this.sizeObserver === 'custom') return;
    const parentElement = this.renderer.parentNode(this.elementRef.nativeElement) as HTMLDivElement;
    const parentWidth = this.sizeObserver === 'auto' ? parentElement.clientWidth : +this.sizeObserver;
    const element = this.elementRef.nativeElement as HTMLParagraphElement;
    const marginY = (parentWidth - (parentWidth*0.6))/2;
  
    this.renderer.setStyle(parentElement, 'width', `${parentWidth}px`);
    this.renderer.setStyle(parentElement, 'border-radius', `${parentWidth}px`);
    this.renderer.setStyle(element, 'font-size', `${parentWidth*0.6}px`);
    this.renderer.setStyle(element, 'line-height', `${parentWidth*0.6}px`);
    this.renderer.setStyle(element, 'margin', `${marginY}px 0`);
  }

}
