import { Component, Inject, Input, OnInit } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

import { ActionType } from '../common';

@Component({
  selector: 'information-page',
  templateUrl: './information-page.component.html',
  styleUrls: ['./information-page.component.scss'],
})
export class InformationPageComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() actionText: string;
  @Input() actionType: ActionType;

  constructor(
    @Inject(WINDOW) private window: Window
  ) { 
    this.actionType = this.actionType ? this.actionType : 'defaultPage';
    this.actionText = this.actionText ? this.actionText : 'default';
  }

  ngOnInit(): void {
  }

  reloadBrowser() {
    this.window.location.reload();
  }

}
