import { Component, Input } from '@angular/core';

import { flipState } from '../../../common';

@Component({
  selector: 'selection-caret',
  template: `
    <mat-icon 
      class="website-checklist__selection-caret"
      [@flipState]="visibility ? 'active' : 'inactive'">
      expand_more
    </mat-icon>
  `,
  styles: [`
    .website-checklist__selection-caret {
      position: absolute;
      inset: 0;
      margin: 10px 8px auto auto;
      cursor: pointer;
    }
  `],
  animations: [ flipState ]
})
export class CaretComponent {

  @Input() visibility: boolean;

}
