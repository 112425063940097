export enum PaymongoErrorCode {
    generic_decline,
    fraudulent,
    processor_blocked,
    pickup_card,
    lost_card,
    stolen_card,
    restricted_card,
    blocked,
    highest_risk_level,
    card_expired,
    card_number_invalid,
    cvc_invalid,
    insufficient_funds,
    credit_limit_exceeded,
    processor_unavailable,
    resource_succeeded_state,
    parameter_invalid
}

export enum PaymongoUnknownErrorCode {
    config_invalid_or_missing,
    processor_timeout,
    system_error,
    server_timeout,
    service_timeout,
    unknown_error
}
