import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thumbnail'
})
export class ThumbnailPipe implements PipeTransform {

  transform(id: string): string {
    return `https://img.youtube.com/vi/${id}/sddefault.jpg`;
  }

}
