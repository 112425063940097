import { Inject, Injectable } from '@angular/core';
import { environment } from 'apps/partner-web/src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { XendItInvoiceResponse, XendItInvoiceRequest } from '../models';
import { Select } from '@ngxs/store';
import { SessionState } from '@fitscovery/auth/data-access';
import { HttpClient } from '@angular/common/http';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiXendItService {

    @Select(SessionState.accessToken) userToken$: Observable<string>;
    token: string;

    constructor(
        @Inject(APP_ENVIRONMENT) private environment: Environment,
        private httpClient: HttpClient,
    ) {
        this.userToken$.subscribe(async (token: string) => {
            this.token = token;
        });
    }

    async createInvoice(xendItRequest: XendItInvoiceRequest): Promise<XendItInvoiceResponse> {
        return this.httpClient.post(`${this.environment.nodeApi.payments}/invoices`, xendItRequest)
            .pipe(
                map((result: any) => {
                    return result.data;
                }),
                catchError((error: any) => {
                    return throwError(error);
                })
            ).toPromise();
    }
}
