import { Component, OnInit } from '@angular/core';
import { Actions, ofActionDispatched, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { delay, filter, Observable, tap, distinctUntilChanged } from 'rxjs';

import { SessionState } from '@fitscovery/auth/data-access';
import { PartnerAction, PartnerState, WebsiteChecklistService } from '@fitscovery/partners/data-access';
import { PartnerWebsiteAction } from '@fitscovery/partner-websites/data-access';
import { EventsActions } from '@fitscovery/events/data-access';
import { SubscriptionAction } from '@fitscovery/memberships/data-access';
import { CreateProgram, CreateWorkout } from '@fitscovery/workouts/data-access';
import { BookingsActions } from '@fitscovery/bookings/data-access';
import { AdminAppStateAction } from '@fitscovery/admin-web/shared/store';
import { FeatureRootService } from './feature-root.service';
import { MaintenancePageService } from '@fitscovery/shared/maintenance-page';
import { opacityFade } from '@fitscovery/common/animations';

@Component({
  selector: 'fitscovery-root',
  templateUrl: './feature-root.component.html',
  styleUrls: ['./feature-root.component.scss'],
  providers: [ FeatureRootService ],
  animations: [ opacityFade() ]
})
export class FeatureRootComponent implements OnInit {

  @Select(SessionState.authenticated) authenticated$: Observable<boolean>;

  constructor(
    actions$: Actions,
    private store: Store,
    private service: FeatureRootService,
    private websiteChecklistService: WebsiteChecklistService,
    public maintenancePage: MaintenancePageService
  ) {

    actions$.pipe(
      ofActionDispatched(PartnerAction.GetPartnerBySubdomain),
      tap(() => store.dispatch(new AdminAppStateAction.SwitchPartnerState(true)))
    ).subscribe()

    actions$.pipe(
      ofActionSuccessful(PartnerWebsiteAction.GetPartnerWebsiteBySubdomain), delay(200),
      tap(() => store.dispatch(new AdminAppStateAction.SwitchPartnerState(false)))
    ).subscribe()

    actions$.pipe(
      ofActionSuccessful(PartnerAction.GetPartnerBySubdomain),
      tap((payload: { subdomain: string }) => localStorage.setItem('selectedPartner', payload.subdomain))
    ).subscribe();

    actions$.pipe(
      ofActionSuccessful(PartnerWebsiteAction.UpdatePartnerWebsiteBanner),
      tap(() => websiteChecklistService.refreshWebsiteChecklist('UPDATE_WEBSITE'))
    ).subscribe()

    actions$.pipe(
      ofActionSuccessful(EventsActions.CreateEvent),
      tap(() => websiteChecklistService.refreshWebsiteChecklist('CREATE_EVENT'))
    ).subscribe()

    actions$.pipe(
      ofActionSuccessful(SubscriptionAction.CreateMembershipSubscriptionWithPMSEmailTemplate),
      tap(() => websiteChecklistService.refreshWebsiteChecklist('CREATE_MEMBERSHIP_SUBSCRIPTION'))
    ).subscribe()

    actions$.pipe(
      ofActionSuccessful(BookingsActions.UpdatePartnerBooking),
      tap(() => websiteChecklistService.refreshWebsiteChecklist('ACTIVATE_DIRECT_BOOKING'))
    ).subscribe()

    actions$.pipe(
      ofActionSuccessful(CreateWorkout),
      tap(() => websiteChecklistService.refreshWebsiteChecklist('CREATE_WORKOUT'))
    ).subscribe()

    actions$.pipe(
      ofActionSuccessful(CreateProgram),
      tap(() => websiteChecklistService.refreshWebsiteChecklist('CREATE_WORKOUT'))
    ).subscribe()

  }

  ngOnInit(): void {

    this.service.handleNewVendorFromOnboarding();
    this.service.partnerCheckOnRouteChange();
    this.maintenancePage.maintenancePageObserver();

    this.maintenancePage.maintenance
    && this.store.dispatch(new AdminAppStateAction.WebsiteChecklistState(true));
    
    this.service.googleTagManagerInit();

    this.store.select(PartnerState.partnerId).pipe(
      filter(Boolean),
      distinctUntilChanged(),
      tap(() => this.websiteChecklistService.loadWebsiteChecklist())
    ).subscribe();

  }

}
