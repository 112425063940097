import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, ActionType, Store } from '@ngxs/store';

import { PartnerReportAction } from '@fitscovery/reports/data-access';
import { AzureLoggerService } from '../azure-logger.service';
import { TrackingService } from '../tracking-service';
import { NotificationActions, WebPushNotificationActions } from '@fitscovery/notification/data-access';

@Injectable()
export class NotificationsTrackingService extends TrackingService {

  constructor(
    store: Store,
    actions$: Actions,
    logger: AzureLoggerService
  ) {

    super(store, actions$, logger);
    
    const actions = [ NotificationActions.InitializeNotifications, NotificationActions.GetUnseenNotificationIds, NotificationActions.LoadMoreNotifications, NotificationActions.SeenNotification, NotificationActions.SeenAllNotification, WebPushNotificationActions.InitializeWebPushNotification ] as ActionType[];

    actions.forEach((action: ActionType) => this.trackDispatch(action));
  }
 
}

@NgModule()
export class NotificationsProviderModule {
  constructor(
    @Optional() service: NotificationsTrackingService
  ) { }
}
