import { Directive, Inject, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators'

import { UpdateTabTitleService, MetaTagService, RoutePathService } from '../services';
import { AccountWebProvider, AdminWebProvider, EmbedWebProvider, OnboardingWebProvider, PartnerWebProvider } from '../providers';
import { APP_TOKEN, Token } from '../environment-injection';
import { EventData } from '../interfaces';

@Directive({
  selector: '[routeObserver]'
})
export class RouteObserverDirective implements OnInit {

  constructor(
    @Inject(APP_TOKEN) private token: Token,
    private updateTabTitleService: UpdateTabTitleService,
    private metaTagService: MetaTagService,
    private routePathService: RoutePathService,
  ) { }

  ngOnInit(): void {

    this.routePathService.eventData$.pipe(
      map((eventData: EventData) => new this.provider(eventData)),
      tap((provider: AdminWebProvider) => {
        this.metaTagService.updateMetaTagDetails(provider.routeMetadata);
        this.updateTabTitleService.setTitle(provider.routeMetadata.title!);
      })
    ).subscribe();
  }

  private get provider(): any {
    switch (this.token.app) {
      case 'account':    return AccountWebProvider;
      case 'admin':      return AdminWebProvider;
      case 'onboarding': return OnboardingWebProvider;
      case 'partner':    return PartnerWebProvider;
      case 'embed':      return EmbedWebProvider;
      default:           return AccountWebProvider;
    }
  }

}
