import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';

import { WebsiteChecklistService } from './website-checklist.service';

import { WebsiteChecklistComponent } from './website-checklist.component';
import { Components } from './components';
import { Pipes } from './common';

const MODULES = [
  CommonModule,
  BrowserAnimationsModule,
  MatIconModule,
  MatButtonModule,
  MatRippleModule
];

const PROVIDERS = [
  WebsiteChecklistService
];

const COMPONENTS = [
  WebsiteChecklistComponent
];

@NgModule({
  imports: [
    ...MODULES
  ],
  providers: [
    ...PROVIDERS
  ],
  declarations: [
    ...COMPONENTS,
    ...Components,
    ...Pipes
  ],
  exports: [
    WebsiteChecklistComponent
  ]
})
export class WebsiteChecklistModule {}
