import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Store } from '@ngxs/store';

import { PushTitleHeight } from '@fitscovery/partner-web/store/application';

@Directive({
  selector: '[elementHeight]'
})
export class ElementHeightDirective implements AfterViewInit {

  constructor(
    private store: Store,
    private element: ElementRef
  ) { }

  ngAfterViewInit(): void {
    const clientHeight = this.element.nativeElement.clientHeight;
    setTimeout(() => {
      this.store.dispatch(new PushTitleHeight(clientHeight));
    });
  }

}
