import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiEmailTemplateService } from '../../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { EmailTemplateAction, EmailTemplateStateModel } from '.';
import { EmailTemplate } from '../../models';
import { errorHandler } from '@fitscovery/common/providers';

export const emailTemplateStateDefaults: EmailTemplateStateModel = null;

export const EMAIL_TEMPLATE_STATE_TOKEN = new StateToken<EmailTemplateStateModel>('api_memberships_email_template');

@State<EmailTemplateStateModel>({
  name: EMAIL_TEMPLATE_STATE_TOKEN,
  defaults: emailTemplateStateDefaults
})

@Injectable()
export class EmailTemplateState {

  @Selector()
  static emailTemplates(state: EmailTemplateStateModel): EmailTemplate[] {
    return state!.emailTemplates || [];
  }

  constructor(
    private emailTemplateService: ApiEmailTemplateService,
    private snackbar: SnackbarService
  ) { }

  @Action(EmailTemplateAction.GetEmailTemplates)
  getEmailTemplates(ctx: StateContext<EmailTemplateStateModel>, action: EmailTemplateAction.GetEmailTemplates) {
    return this.emailTemplateService.getEmailTemplatesByPartnerId(action.partnerId).pipe(
      errorHandler(this.snackbar),
      tap((response: EmailTemplate[]) => ctx.setState({ emailTemplates: response }))
    );
  }

}
