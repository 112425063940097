import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, ActionType, Store } from '@ngxs/store';

import { PartnerReportAction } from '@fitscovery/reports/data-access';
import { AzureLoggerService } from '../azure-logger.service';
import { TrackingService } from '../tracking-service';

@Injectable()
export class ReportsTrackingService extends TrackingService {

  constructor(
    store: Store,
    actions$: Actions,
    logger: AzureLoggerService
  ) {

    super(store, actions$, logger);
    
    const actions = [ PartnerReportAction.GetPartnerReports ] as ActionType[];

    actions.forEach((action: ActionType) => this.trackDispatch(action));
  }
 
}

@NgModule()
export class ReportsProviderModule {
  constructor(
    @Optional() service: ReportsTrackingService
  ) { }
}
