export * from './user-displayname.pipe';
export * from './animations';
export * from './selections';

import { UserDisplaynamePipe } from './user-displayname.pipe';
import { CompletionPercentagePipe } from './completion-percentage.pipe';
import { CompletionBarFillPipe } from './completion-bar-fill.pipe';
import { YoutubeUrlPipe } from './youtube-url.pipe';
import { ThumbnailPipe } from './thumbnail.pipe';
import { FilterPipe } from './filter.pipe';

export const Pipes = [
  UserDisplaynamePipe,
  CompletionPercentagePipe,
  CompletionBarFillPipe,
  YoutubeUrlPipe,
  ThumbnailPipe,
  FilterPipe
];
