import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { BookingActions } from './store.action';

import { StateModel } from './store.model';

export const bookingsDefaults: StateModel = {
  allowDirectBooking: false,
};

export const ADMIN_BOOKING_STATE_TOKEN = new StateToken<StateModel>('domain_bookings');

@State<StateModel>({
  name: ADMIN_BOOKING_STATE_TOKEN,
  defaults: bookingsDefaults
})
@Injectable()
export class AdminWebBookingState implements NgxsOnInit {

  @Selector()
  static allowDirectBooking(state: StateModel): boolean {
    return state.allowDirectBooking;
  }

  constructor() { }

  ngxsOnInit(): void { }

  @Action(BookingActions.AllowDirectBookingState)
  allowDirectBooking( ctx: StateContext<StateModel>, action: BookingActions.AllowDirectBookingState): void {
    ctx.patchState({ allowDirectBooking: action.state });
  }

}
