import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GetPaymentMethodsResponse } from '../interfaces';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiPaymentMethodsService {
    
  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getPaymentMethods(): Observable<GetPaymentMethodsResponse> {
    return this.http.get<GetPaymentMethodsResponse>(`${this.env.nodeApi.payments}/payment-methods`);
  }

}
