export namespace AccountsAction {

  export class GetAuthDetails {
    static readonly type = '[Auth API] GetAuthDetails';
    constructor() { }
  }

  export class UpdateEmail {
    static readonly type = '[Auth API] UpdateEmail';
    constructor(public email: string) { }
  }

}
