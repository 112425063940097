import { Pipe, PipeTransform } from '@angular/core';

import { PartnerOfferings } from '@fitscovery/partners/data-access';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {

  transform(offerings: PartnerOfferings, route: string): boolean {
    switch (route) {
      case '/memberships': return !offerings?.isMembershipsEnabled;
      case '/events':      return !offerings?.isEventsEnabled;
      case '/workouts':    return !offerings?.isWorkoutsEnabled;
      case '/bookings':    return !offerings?.isBookingsEnabled;
      default:             return false;
    }
  }

}
