import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiAdminPartnersService } from '../../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { CreatePartnerAction, CreatePartnerStateModel } from '.';
import { Partner } from '..';
import { CreatePartnerResponse } from '../../interfaces';
import { errorHandler } from '@fitscovery/common/providers';

export const createPartnerStateDefaults: CreatePartnerStateModel = null;

export const CREATE_PARTNER_STATE_TOKEN = new StateToken<CreatePartnerStateModel>('api_partners_create_partner');

@State<CreatePartnerStateModel>({
  name: CREATE_PARTNER_STATE_TOKEN,
  defaults: createPartnerStateDefaults
})

@Injectable()
export class CreatePartnerState {

  @Selector()
  static created(state: CreatePartnerStateModel): Partner {
    return state!.created;
  }

  constructor(
    private adminPartners: ApiAdminPartnersService,
    private snackbar: SnackbarService
  ) { }

  @Action(CreatePartnerAction.CreatePartner)
  createPartner(ctx: StateContext<CreatePartnerStateModel>, action: CreatePartnerAction.CreatePartner) {
    return this.adminPartners.createPartner(action.partner).pipe(
      errorHandler(this.snackbar),
      tap((response: CreatePartnerResponse) => ctx.setState({ created: response.data.partner }))
    );
  }

}
