import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function insertGoogleMapApiNamespace(isProd: boolean = true) {
  if (!window) return;
  const scriptEl = window.document.createElement('script');
  scriptEl.src = isProd 
    ? 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCG6h0oTrQpMGJg-GvllPAPiFhxIbiiqQk&libraries=visualization,places'
    : 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDQUPOClCBqAJUmBd3-FkcG6tZJaHL_SoI&libraries=visualization,places';
  window.document.body.appendChild(scriptEl);
}

if (environment.production === "true") {
  enableProdMode();
  insertGoogleMapApiNamespace();
} else {
  insertGoogleMapApiNamespace(false);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
