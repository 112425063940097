import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiAdminPartnersService } from '../../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { AdminPartnersAction, AdminPartnersStateModel } from '.';
import { Partner } from '..';
import { GetEntitiesResponse } from '../../interfaces';
import { errorHandler } from '@fitscovery/common/providers';

export const adminPartnersStateDefaults: AdminPartnersStateModel = null;

export const ADMIN_PARTNERS_STATE_TOKEN = new StateToken<AdminPartnersStateModel>('api_partners_admin_partners');

@State<AdminPartnersStateModel>({
  name: ADMIN_PARTNERS_STATE_TOKEN,
  defaults: adminPartnersStateDefaults
})

@Injectable()
export class AdminPartnersState {

  @Selector()
  static adminPartners(state: AdminPartnersStateModel): Partner[] {
    return state!.partners || [];
  }

  constructor(
    private adminPartners: ApiAdminPartnersService,
    private snackbar: SnackbarService
  ) { }

  @Action(AdminPartnersAction.GetAdminPartners)
  getAdminPartners(ctx: StateContext<AdminPartnersStateModel>, action: AdminPartnersAction.GetAdminPartners) {
    return this.adminPartners.getAdminPartners(action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: GetEntitiesResponse) => ctx.setState(response.data))
    );
  }

}
