<div id="user-partner-details" fxLayout="row wrap" fxLayoutAlign="center" class="animate__animated animate__faster" [ngClass]="{ 'animate__slideInDown': overlayState, 'animate__fadeOut': !overlayState }">

  <ng-container *ngIf="(user$ | async)?.imageUrl;else dummyImage">
    <img
      alt="user-admin-photo"
      defaultImage="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/default-image.png" 
      errorImage="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/default-image.png" 
      [lazyLoad]="(user$ | async)?.imageUrl!">
  </ng-container>
  <ng-template #dummyImage>
    <dummy-image
      size="150"
      [value]="(user$ | async)?.firstName!">
    </dummy-image>
  </ng-template>

  <span fxFlex="grow" [ngStyle]="{ margin: '8px 0 0 0' }">{{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}</span>
  <span fxFlex="grow" [ngStyle]="{ margin: '0 0 16px 0' }">{{ (user$ | async)?.email }}</span>

  <mat-divider fxFlex="grow"></mat-divider>
  
  <div fxFlex="grow" class="partners">
    <ng-content></ng-content>
  </div>
  
  <mat-divider fxFlex="grow"></mat-divider>

  <div fxFlex="grow" fxLayout="row" fxLayoutAlign="center" class="logout" (click)="logout.emit()">
    <span>LOGOUT</span>
  </div>

  <button mat-icon-button (click)="closeOverlay.emit()">
    <mat-icon>close</mat-icon>
  </button>

</div>
