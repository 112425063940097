import { PartnerRequest } from "../../interfaces";

export namespace CreatePartnerAction {

  export class CreatePartner {
    static readonly type = '[Partners API] CreatePartner';
    constructor(public partner: PartnerRequest) { }
  }

}
