import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[mouseOver]'
})
export class MouseOverDirective {

  @Output() mouseOverBuyNow = new EventEmitter();

  constructor() { }

  @HostListener('mouseenter') mouseover() {
    this.mouseOverBuyNow.emit(true);
  }
  
  @HostListener('mouseleave') mouseleave() {
    this.mouseOverBuyNow.emit(false);
  }

}
