import { Injectable, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { RegexProvider } from '@fitscovery/common/providers';

@Injectable()
export class SocialMediaAuthEmailDialogService {
  
  form: FormGroup;
  
  constructor(
    @Inject(FormBuilder) public fb: FormBuilder
  ) {
    this.form = fb.group({
      'email': [ '', [ Validators.required, Validators.pattern(RegexProvider.email) ] ]
    })
  }

}
