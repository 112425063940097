import { NgModule } from '@angular/core';

import { AppStoreModule } from './app-store.module';
import { AppRoutingModule } from './app-routing.module';
import { AppLibrariesModule } from './app-libraries.module';

import { FeatureRootComponent } from '@fitscovery/admin-web/feature-root';

const MODULES = [
  AppStoreModule,
  AppRoutingModule,
  AppLibrariesModule
];

@NgModule({
  imports: [
    ...MODULES
  ],
  bootstrap: [
    FeatureRootComponent
  ]
})
export class AppModule { }
