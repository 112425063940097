import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngxs/store';

import { SessionState } from '@fitscovery/auth/data-access';
import { MaintenancePageService } from '@fitscovery/shared/maintenance-page';

@Injectable()
export class NotAuthenticatedGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private store: Store,
    private maintenancePage: MaintenancePageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const authenticated = this.store.selectSnapshot(SessionState.authenticated);
    authenticated && !this.maintenancePage.maintenance ? this.router.navigate([ '/', 'dashboard' ], { queryParamsHandling: 'merge' }) : null;
    return !authenticated;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

}
