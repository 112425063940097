import { Actions, ActionType, ofActionCompleted } from '@ngxs/store';
import { tap } from 'rxjs';

export abstract class TrackingService {

  constructor(
    private actions$: Actions
  ) { }

  protected actionListener(action: ActionType, trackEvent: () => void) {
      this.actions$.pipe(
        ofActionCompleted(action),
        tap(trackEvent)
      ).subscribe();
    }
 
}
