import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { SessionState } from '@fitscovery/auth/data-access';
import { UserState, User } from '@fitscovery/user/data-access';
import { SubscriptionStore } from '@fitscovery/common/classes';
import { Notification, NotificationActions, NotificationState } from '@fitscovery/notification/data-access';
import { Select, Store } from '@ngxs/store';
import { Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'fitscovery-feature-notification-button',
  templateUrl: './feature-notification-button.component.html',
  styleUrls: ['./feature-notification-button.component.scss'],
})
export class FeatureNotificationButtonComponent extends SubscriptionStore implements OnInit, OnDestroy, OnChanges {
  @Input() userId: string;
  @Input() partnerId: string;
  @Input() application: string;

  @Select(SessionState.accessToken) token$: Observable<string>;
  token: string;

  @Select(UserState.user) user$: Observable<User>;
  user: User;

  @Select(NotificationState.unseenNotificationIds) unseenNotificationIds$: Observable<string[]>;
  unseenNotificationIds: string[];

  @Select(NotificationState.gettingNotifications) gettingNotifications$: Observable<boolean>;
  gettingNotifications: boolean;

  @Select(NotificationState.notifications) notifications$: Observable<Notification[]>;
  notifications: Notification[];

  @Select(NotificationState.hasMoreNotification) hasMoreNotification$: Observable<boolean>;
  hasMoreNotification: boolean;

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.token$.pipe(takeUntil(this.subscription$)).subscribe((token: string) => {
      this.token = token;
    });

    this.user$.pipe(takeUntil(this.subscription$)).subscribe((user: User) => {
      this.user = user;
    });

    this.unseenNotificationIds$.pipe(takeUntil(this.subscription$)).subscribe((unseenNotificationIds: string[]) => {
      this.unseenNotificationIds = unseenNotificationIds;
    });

    this.gettingNotifications$.pipe(takeUntil(this.subscription$)).subscribe((gettingNotifications: boolean) => {
      this.gettingNotifications = gettingNotifications;
    });

    this.notifications$.pipe(takeUntil(this.subscription$)).subscribe((notifications: Notification[]) => {
      this.notifications = notifications;
    });

    this.hasMoreNotification$.pipe(takeUntil(this.subscription$)).subscribe((hasMoreNotification: boolean) => {
      this.hasMoreNotification = hasMoreNotification;
    });
  }

  ngOnDestroy(): void {
    this.destroySubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const authenticated = this.store.selectSnapshot(SessionState.authenticated);
    if (authenticated && (changes['userId'] || changes['partnerId'] || changes['application'])) {
      setTimeout(() => {
        this.store.dispatch(new NotificationActions.GetUnseenNotificationIds(this.userId, this.partnerId, this.application));
        this.store.dispatch(new NotificationActions.LoadMoreNotifications(this.userId, this.partnerId, this.application, true));
        this.store.dispatch(new NotificationActions.InitializeNotifications(this.userId, this.partnerId, this.application));
      }, 15000);
    }
  }

  loadMoreNotifications($event: MouseEvent) {
    $event.stopPropagation();
    this.store.dispatch(new NotificationActions.LoadMoreNotifications(this.userId, this.partnerId, this.application));
  }

  goToNotification($event: MouseEvent, notification: Notification) {
    $event.stopPropagation();

    if (!notification.seenAt) {
      this.store.dispatch(new NotificationActions.SeenNotification(notification.id));
    }
  }

  seenAllNotifications($event: MouseEvent) {
    $event.stopPropagation();
    this.store.dispatch(new NotificationActions.SeenAllNotification(this.application, this.partnerId));
  }
}
