import { NgModule } from '@angular/core';

import { AnimationToDefaultDirective } from './animation-to-default.directive';

const DIRECTIVES = [
  AnimationToDefaultDirective
];

@NgModule({
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    AnimationToDefaultDirective
  ]
})
export class SharedAnimationToDefaultModule {}
