import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, Store } from '@ngxs/store';

import { SegmentAnalytics } from '../segment-analytics';
import { UserActions, UserState } from '@fitscovery/user/data-access';
import { TrackingService } from '../tracking-service';

@Injectable()
export class OnboardingWebSegmentTrackingService extends TrackingService {

  constructor(
    actions$: Actions,
    store: Store,
    logger: SegmentAnalytics
  ) {

    super(actions$);

    this.actionListener(
      UserActions.GetCurrentUser,
      () => logger.identify(store.selectSnapshot(UserState.userId).toString(), {
        email: store.selectSnapshot(UserState.user)?.email, 
        firstName: store.selectSnapshot(UserState.user)?.firstName,
        lastName: store.selectSnapshot(UserState.user)?.lastName
      })
    );
  }

}

@NgModule()
export class OnboardingWebSegmentProviderModule {
  constructor(
    @Optional() service: OnboardingWebSegmentTrackingService
  ) { }
}
