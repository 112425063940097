import { InjectionToken } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import { App } from '@fitscovery/common/types';

export interface AppProvider {
  app: App;
  environment: Environment
}

export const APP_PROVIDER = new InjectionToken<AppProvider>('common-providers-app-provider');
