import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { InformationPageComponent } from './information-page/information-page.component';
import { RouterModule } from '@angular/router';

const MODULES = [
  CommonModule,
  FlexLayoutModule,
  RouterModule
];

const COMPONENTS = [
  InformationPageComponent
];

@NgModule({
  imports: [
    ...MODULES
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    InformationPageComponent
  ]
})
export class SharedInformationPageModule {}
