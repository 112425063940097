<div id="information-page" fxLayout="row" fxLayoutAlign="center center">

  <div fxLayout="column" fxLayoutAlign="center center">
    <a href="https://www.fitscovery.com/">
      <img src="https://uploads-ssl.webflow.com/625446168230dd2faf65dfc1/628af9da3e6eda5cb9d39d91_Fitscovery%203x.png">
    </a>
    <h2>{{ title }}</h2>
    <p>{{ description }}</p>

    <ng-container [ngSwitch]="actionType">
      <div
        *ngSwitchCase="'defaultPage'"
        fxLayoutAlign="center"
        routerLink="/"
        queryParamsHandling="preserve">
        {{ actionText }}
      </div>
      <div
        *ngSwitchCase="'reloadBrowser'"
        fxLayoutAlign="center"
        (click)="reloadBrowser()">
        {{ actionText }}
      </div>
    </ng-container>

  </div>

</div>
