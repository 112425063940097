import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { SidenavModule } from '../sidenav';
import { WebsiteChecklistModule } from '../website-checklist';

import { DrawerService } from './drawer.service';

import { DrawerComponent } from './drawer.component';
import { ScrollListenerDirective } from './scroll-listener.directive';

const MODULES = [
  CommonModule,
  MatSidenavModule,
  MatButtonModule,
  MatIconModule
];

const LIBRARIES = [
  SidenavModule,
  WebsiteChecklistModule
];

const PROVIDERS = [
  DrawerService
];

const COMPONENTS = [
  DrawerComponent,
  ScrollListenerDirective
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES
  ],
  providers: [
    ...PROVIDERS
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports:      [
    DrawerComponent
  ],
})
export class DrawerModule {}
