import { Component, OnInit } from '@angular/core';

import { MaintenancePageService } from './../maintenance-page.service';

@Component({
  selector: 'fitscovery-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent implements OnInit {

  constructor(
    public service: MaintenancePageService
  ) { }

  ngOnInit(): void {
  }

}
