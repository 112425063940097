import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthDetailsResponse } from '../models';
import { APP_TOKEN, Token } from '../environment-injection';

@Injectable()
export class ApiAccountService {

  constructor(
    @Inject(APP_TOKEN) private appToken: Token,
    private http: HttpClient
  ) { }

  getAuthDetails(): Observable<AuthDetailsResponse> {
    return this.http.get<AuthDetailsResponse>(`${this.appToken.environment.nodeApi.auth}/accounts/me`, {
      withCredentials: true
    });
  }

  updateEmail(email: string): Observable<any> {
    return this.http.put<any>(`${this.appToken.environment.nodeApi.auth}/accounts/me/email`, { email }, {
      withCredentials: true
    });
  }

}
