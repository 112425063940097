<mat-drawer-container
  autosize
  [ngClass]="{
    opened: service.opened,
    checklist: (service.drawerState$ | async)!.type === 'checklist'
  }">

  <mat-drawer 
    #sidenav class="sidenav"
    mode="over"
    position="start"
    autoFocus="false"
    (openedStart)="service.saveScrollPosition()"
    (closedStart)="service.goToLastScrollPosition()">
    <button mat-icon-button (click)="sidenav.close()">
      <mat-icon>close</mat-icon>
    </button>
    <sidenav [hasLogo]="true" (click)="sidenav.close()"></sidenav>
  </mat-drawer>

  <mat-drawer 
    #checklist class="checklist"
    mode="over"
    position="end"
    autoFocus="false"
    (openedStart)="service.saveScrollPosition()"
    (closedStart)="service.goToLastScrollPosition()">
    <website-checklist
      (close)="checklist.close()">
    </website-checklist>
  </mat-drawer>

  <mat-drawer-content>
    <div class="scrollable-drawer-content" scrollListener>
      <ng-content></ng-content>
    </div>
  </mat-drawer-content>

</mat-drawer-container>
