import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiPartnerMemberService } from '../../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { PartnerMemberAction, PartnerMemberStateModel } from '.';
import { AttendeeDetails, GetEventActiveMembersResponse } from '../../models';
import { errorHandler } from '@fitscovery/common/providers';

export const partnerMemberStateDefaults: PartnerMemberStateModel = null;

export const PARTNER_MEMBER_STATE_TOKEN = new StateToken<PartnerMemberStateModel>('api_memberships_partner_members');

@State<PartnerMemberStateModel>({
  name: PARTNER_MEMBER_STATE_TOKEN,
  defaults: partnerMemberStateDefaults
})

@Injectable()
export class PartnerMemberState {

  @Selector()
  static partnerMembers(state: PartnerMemberStateModel): AttendeeDetails[] {
    return state!.members;
  }

  constructor(
    private partnerMemberService: ApiPartnerMemberService,
    private snackbar: SnackbarService
  ) { }

  @Action(PartnerMemberAction.GetEventActiveMembers)
  getEventActiveMembers(ctx: StateContext<PartnerMemberStateModel>, action: PartnerMemberAction.GetEventActiveMembers) {
    return this.partnerMemberService.getEventActiveMembers(action.partnerId, action.eventStart, action.numberOfTicket).pipe(
      errorHandler(this.snackbar),
      tap((response: GetEventActiveMembersResponse) => ctx.setState({ members: response.data }))
    );
  }

}
