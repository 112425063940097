<div 
  id="sidenav-option" 
  [class]="data!.class!"
  [ngClass]="{
    disable: !isAllowNavigation
  }"
  [ngStyle]="{ cursor: data!.isInProgress ? 'cursor' : 'pointer', 'pointer-events': data!.type !== 'menu' ? 'none' : 'auto' }"
  (click)="optionClickEvent()"
  matTooltip="This is disabled"
  [matTooltipDisabled]="!(data!.type === 'menu' && ((offerings$ | async)! | state: data!.route!))">
  
  <div 
    class="sidenav-option" 
    fxLayout="row" 
    fxLayoutAlign="start center" 
    fxLayoutGap="20px" 
    matRipple 
    [matRippleDisabled]="(data!.type !== 'menu' || data!.isInProgress)!" 
    [ngClass]="{
      active: !data!.isInProgress, selected: (routerState$ | async)?.url! | util: data,
      disabled: data!.type === 'menu' && ((offerings$ | async)! | state: data!.route!)
    }" 
    [ngStyle]="{ 'margin-bottom': isLast && data.icon !== 'auto_stories' ? '64px' : '' }">
    
    <mat-icon 
      [class.material-icons-outlined]="true" 
      *ngIf="data?.type === 'menu'">
      {{ data.icon }}
    </mat-icon>

    <p [class]="data!.type">
      <ng-container *ngIf="data.title;else loading">{{ data.title }}</ng-container>
      <ng-template #loading>
        <mat-spinner diameter="20" strokeWidth="3"></mat-spinner>
      </ng-template>
      <span *ngIf="data?.isInProgress">*</span>
    </p>

  </div>
</div>
