import { Application } from './app-environment.interface';

export const appInsights: Application = {
  partner: 'f1b34d7f-0976-49ef-b69a-fac0b45e26a0',
  account: '30870fa8-d30b-4e13-94af-495f9ca27ed0',
  onboarding: 'de83f5d7-6641-4abe-8bcb-e412bf9ae66c',
  admin: 'c1bc560b-8696-4415-a256-5e4176c55a48',
  my: '',
  backoffice: '',
  embed: ''
};

export const segmentKey: Application = {
  partner: 'tvA6VEzl8gFbmEdEZWhgUGf25l8gekIC',
  account: 'r8ApOBa8D746XsKRbh7CpTqInIuDaDi0',
  onboarding: 'r8ApOBa8D746XsKRbh7CpTqInIuDaDi0',
  admin: 'iqNePGQiJ4mNhx2ik6RbS2yBYpFqIOt0',
  my: 'r8ApOBa8D746XsKRbh7CpTqInIuDaDi0',
  backoffice: '',
  embed: ''
};

export const pushKey: Application = {
  partner: '',
  account: '',
  onboarding: '',
  admin: 'BALV6uTa98r-yWo6lnVGQymYT5tbOAy2EL-Pf2-2NmjmsCL12ZQdj9v67DhAHAD7PHMXDpfZCp8jQmLwwaVst2I',
  my: '',
  backoffice: '',
  embed: ''
};
