import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireRemoteConfig, Parameter } from '@angular/fire/remote-config';
import { BehaviorSubject, Observable, distinctUntilChanged, filter, map, startWith, take, tap } from 'rxjs';

import { Maintenance } from './maintenance.interface';
import { APP_PROVIDER, AppProvider } from '@fitscovery/common/providers';

@Injectable({
  providedIn: 'root'
})
export class MaintenancePageService {

  maintenance$$ = new BehaviorSubject<Maintenance | null>(null);

  constructor(
    @Inject(APP_PROVIDER) private appProvider: AppProvider,
    private router: Router,
    private remoteConfig: AngularFireRemoteConfig
  ) {
    this.remoteConfig.fetchAndActivate(); 
  }

  get maintenance(): boolean { return this.router.url.includes('maintenance') }

  get loaded$(): Observable<boolean> {
    return this.remoteConfig.parameters.pipe(
      map((parameters: Parameter[]) => !!parameters && !!parameters.length),
      startWith(false),
      distinctUntilChanged()
    )
  }

  maintenancePageObserver(): void {
    this.remoteConfig.parameters.pipe(
      filter((parameters: Parameter[]) => !!parameters.length),
      tap((parameters: Parameter[]) => {
        const parameter = this.parameter(parameters);
        (this.parameterValidity(parameter) && this.parameterValue(parameter!))
          ? this.prepareMaintenancePage(parameter!)
          : this.endMaintenancePage();
        this.logger(parameter)
      }),
      take(1)
    ).subscribe();
  }

  private prepareMaintenancePage(parameter: Parameter): void {
    const queryParams = { site: this.appProvider.app };
    this.maintenance || this.router.navigate([ '/maintenance' ], { queryParams });
    this.maintenance$$.next(this.parameterValue(parameter));
  }

  private endMaintenancePage(): void {
    this.maintenance && this.router.navigate([ '/' ], { queryParams: { site: null } });
  }

  private parameterValidity(parameter: Parameter | null): boolean {
    return !!parameter && parameter.key === 'maintenance' && (!parameter._value || parameter._value !== '[]');
  }

  private parameterValue(parameter: Parameter): Maintenance | null {
    if (!parameter) return null;
    const parameterValue = JSON.parse(parameter._value) as Maintenance[];
    return parameterValue.find(e => e.sites === this.appProvider.app) || null;
  }

  private parameter(parameters: Parameter[]): Parameter | null {
    return parameters.find(e => e._value.includes(`"sites":"${this.appProvider.app}"`)) || null;
  }

  private logger(parameter: Parameter | null): void {
    this.appProvider.environment.production !== "true" && parameter
    && console.log('@@@ Remote config (Maintenance)', JSON.stringify(this.parameterValue(parameter), null, 2));
  }

}
