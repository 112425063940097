import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AdminWebBookingState } from './store';

@NgModule({
  imports: [
    NgxsModule.forFeature([ AdminWebBookingState ])
  ]
})
export class AdminWebBookingsSharedStoreModule {}
