import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Store } from '@ngxs/store';
import { delay } from 'rxjs';

import { AdminAppState, DrawerState } from '@fitscovery/admin-web/shared/store';
import { DrawerService } from './drawer.service';

@Component({
  selector: 'drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatDrawer;
  @ViewChild('checklist') checklist: MatDrawer;

  constructor(
    public store: Store,
    public service: DrawerService
  ) { }

  ngOnInit(): void {
    this.store.select(AdminAppState.drawerState).pipe(delay(10)).subscribe((action: DrawerState) => {
      action.state ? this[action.type].open() : this[action.type].close();
    });
  }

}
