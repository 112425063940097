import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Actions, ofActionCanceled, ofActionDispatched, ofActionErrored, ofActionSuccessful, Store } from '@ngxs/store';
import { filter, take, takeUntil, tap } from 'rxjs';

import { SessionAction, SessionState } from '@fitscovery/auth/data-access';
import { PartnerWebsite } from '@fitscovery/partner-websites/data-access';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { NavbarService } from './navbar.service';
import { SubscriptionStore } from '@fitscovery/common/classes';
import { AdminAppStateAction } from '@fitscovery/admin-web/shared/store';
import { opacityFadeIn } from '@fitscovery/common/animations';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [ opacityFadeIn(350) ]
})
export class NavbarComponent extends SubscriptionStore implements OnInit, OnDestroy {

  isUserPartnerDetailsOpened: boolean;
  overlayState: boolean;
  initialized: boolean;

  constructor(
    private auth: AngularFireAuth,
    private store: Store,
    private actions$: Actions,
    private snackbar: SnackbarService,
    public service: NavbarService
  ) {
    super();
  }

  ngOnInit(): void {

    this.initialized = false;

    this.auth.authState.pipe(
      filter(() => !this.initialized),
      filter((state) => 
        (!this.store.selectSnapshot(SessionState.authenticated) && !state)
        || (!this.store.selectSnapshot(SessionState.authenticated) && !!state)
        || (this.store.selectSnapshot(SessionState.authenticated) && !!state)
      ),
      tap(() => this.initialize()),
      take(1)
    ).subscribe();

    this.actions$.pipe(
      ofActionSuccessful(SessionAction.Authenticate),
      take(1)
    ).subscribe(() => {
      localStorage.setItem('authenticate', '1');
      this.initialize();
    });

    this.setUserToPending(ofActionDispatched);
    this.setUserToDefault(ofActionCanceled);
    this.setUserToDefault(ofActionErrored);
  }

  initialize(): void {

    this.initialized = true;
    this.service.checkSession();
    this.service.initializeNotificationState();

    this.service.partnerWebsite$.pipe(
      filter(Boolean),
      tap((partnerWebsite: PartnerWebsite) => this.service.selectedVendorSource.next({
        subdomain: partnerWebsite.subdomain!,
        logoUrl: partnerWebsite.logoUrl!,
        role: this.service.getVendorBySubdomain(partnerWebsite.subdomain!)?.role!
      })),
      takeUntil(this.subscription$)
    ).subscribe();

    if (localStorage.getItem('from_auth')) {
      this.service.initializeState();
      localStorage.removeItem('from_auth');
    } else {
      this.service.checkSessionCookie();
      localStorage.getItem('authenticate')
        ? this.service.initializeState()
        : this.service.currentSubdomainObserver();
      localStorage.removeItem('authenticate');
    }
  }

  private setUserToPending(action: any): void {
    this.actions$.pipe(
      action(SessionAction.Authenticate),
      take(1)
    ).subscribe(() => {
      this.snackbar.openSnack({ message: 'Please wait...', duration: Infinity });
    });
  }

  private setUserToDefault(action: any): void {
    this.actions$.pipe(
      action(SessionAction.Authenticate),
      take(1)
    ).subscribe(() => {
      this.snackbar.destroyAll();
    });
  }

  toggleOverlay(): void {
    this.isUserPartnerDetailsOpened = !this.isUserPartnerDetailsOpened;
    this.overlayState = this.isUserPartnerDetailsOpened;
  }

  closeOverlay(): void {
    this.overlayState = false;
    setTimeout(() => {
      this.isUserPartnerDetailsOpened = false;
    }, 250);
  }

  openSidenavDrawer(): void {
    this.store.dispatch(new AdminAppStateAction.DrawerState(true, 'sidenav'));
  }

  ngOnDestroy(): void {
    this.destroySubscription();
  }

}
