import { NgModule } from '@angular/core';

import { ElementHeightDirective } from './element-height.directive';

const DIRECTIVES = [
  ElementHeightDirective
];

@NgModule({
  declarations: [
    ...DIRECTIVES,
  ],
  exports: [
    ElementHeightDirective
  ]
})
export class ElementHeightModule { }
