import { Inject, Injectable } from '@angular/core';
import { Variable } from '@fitscovery/common/types';
import { ApplicationInsights, ICustomProperties, IDependencyTelemetry, IEventTelemetry, IMetricTelemetry, IPageViewPerformanceTelemetry, IPageViewTelemetry, ITraceTelemetry } from '@microsoft/applicationinsights-web';

import { AZ_APP_INSIGHTS } from './common';
import { AzAppInsights } from './az-app-insights';

@Injectable()
export class AzureLoggerService implements AzAppInsights {

  constructor(
    @Inject(AZ_APP_INSIGHTS) private appInsights: ApplicationInsights
  ) { }

  trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties): void {
    this.appInsights.trackEvent(event, customProperties);
  }
  
  trackPageView(pageView?: IPageViewTelemetry): void {
    this.appInsights.trackPageView(pageView);
  }
  
  trackPageViewPerformance(pageViewPerformance: IPageViewPerformanceTelemetry): void {
    this.appInsights.trackPageViewPerformance(pageViewPerformance);
  }
  
  trackException(error: string, customProperties?: ICustomProperties): void {
    this.appInsights.trackException({ exception: new Error(error) }, customProperties);
  }
  
  trackTrace(trace: ITraceTelemetry, customProperties?: ICustomProperties): void {
    this.appInsights.trackTrace(trace, customProperties);
  }
  
  trackMetric(metric: IMetricTelemetry, customProperties?: ICustomProperties): void {
    this.appInsights.trackMetric(metric, customProperties);
  }
  
  trackDependencyData(dependency: IDependencyTelemetry): void {
    this.appInsights.trackDependencyData(dependency);
  }
  
  startTrackPage(name?: string): void {
    this.appInsights.startTrackPage(name);
  }
  
  stopTrackPage(name?: string, url?: string, customProperties?: Variable<any>, measurements?: Variable<number>): void {
    this.appInsights.stopTrackPage(name, url, customProperties, measurements);
  }
  
  startTrackEvent(name?: string): void {
    this.appInsights.startTrackEvent(name);
  }
  
  stopTrackEvent(name: string, properties?: Variable<string>, measurements?: Variable<number>): void {
    this.appInsights.stopTrackEvent(name, properties, measurements);
  }
  
  flush(async?: boolean): void {
    this.appInsights.flush(async);
  }
 
}
