import { Component, ViewChild, Input, OnInit, AfterViewInit } from '@angular/core';
import { MatRipple } from '@angular/material/core';

import { actionDropdown, actionDropdownFlex, actionGrey, actionNavigation, actionButton, actionGreyButton, actionSiteButton, vendorBackButton, buttonFacebookSignIn, buttonGoogleSignIn, buttonSignInDefault, actionInfoButton, uploadFitnessLogButtonWorkout, uploadFitnessLogButtonNutrition, outlineLightBlue, fullLightBlueMedium, outlinePink, fullGreenMedium } from './button-styles.provider';

@Component({
  selector: 'fitscovery-ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, AfterViewInit {

  @ViewChild(MatRipple) matRipple: MatRipple;
  @Input() type: string;
  @Input() buttonStyles: any;
  @Input() buttonStyle: string;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() fontSet: string;
  @Input() fontIcon: string;
  @Input() elevation: string;
  @Input() disabled: boolean | string;
  @Input() ripple: boolean | string;
  @Input() flexButton: boolean | string;
  @Input() formButton: boolean | string;

  constructor() { }

  ngOnInit(): void {
    this.attachButtonStyles();
    this.type = this.utils(this.type) ? this.type : 'button';
    this.ripple = this.utils(this.ripple);
    this.disabled = this.utils(this.disabled);
    this.iconLeft = this.utils(this.iconLeft);
    this.iconRight = this.utils(this.iconRight);
    this.fontSet = this.utils(this.fontSet);
    this.fontIcon = this.utils(this.fontIcon);
    this.elevation = `mat-elevation-z${this.utils(this.elevation)}`;
    this.flexButton = this.utils(this.flexButton);
    this.formButton = this.utils(this.formButton);
  }

  ngAfterViewInit(): void {
    this.matRipple.disabled = !Boolean(this.ripple);
  }

  attachButtonStyles() {
    const style = <string>this.utils(this.buttonStyle);
    if (style.includes('_')) {
      let split = style.split('_');
      this.buttonStyles = (this.styleProvider as any)[split[0]](split[1]);
    } else {
      this.buttonStyles = (this.styleProvider as any)[style];
    }
  }

  get styleProvider() {
    return {
      actionDropdown, actionDropdownFlex, actionGrey, actionNavigation, actionButton, actionGreyButton,
      actionSiteButton, vendorBackButton, buttonFacebookSignIn, buttonGoogleSignIn, buttonSignInDefault, actionInfoButton, uploadFitnessLogButtonWorkout, uploadFitnessLogButtonNutrition,
      outlineLightBlue, fullLightBlueMedium, outlinePink, fullGreenMedium
    };
  }

  utils(item: any) {
    return item === ''   ? true
    : item === undefined ? false
    : item === 'true'    ? true
    : item === 'false'   ? false
    : item;
  }

}
