import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiMembershipService } from '../../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { MembershipHistoryAction, MembershipHistoryStateModel } from '.';
import { Membership } from '../../models';
import { errorHandler } from '@fitscovery/common/providers';

export const membershipHistoryStateDefaults: MembershipHistoryStateModel = null;

export const MEMBERSHIP_HISTORY_STATE_TOKEN = new StateToken<MembershipHistoryStateModel>('api_memberships_membership_with_history');

@State<MembershipHistoryStateModel>({
  name: MEMBERSHIP_HISTORY_STATE_TOKEN,
  defaults: membershipHistoryStateDefaults
})

@Injectable()
export class MembershipHistoryState {

  @Selector()
  static membershipWithHistory(state: MembershipHistoryStateModel): Membership {
    return state!.membership;
  }

  constructor(
    private membershipService: ApiMembershipService,
    private snackbar: SnackbarService
  ) { }

  @Action(MembershipHistoryAction.GetMembershipWithHistory)
  getMembershipWithHistory(ctx: StateContext<MembershipHistoryStateModel>, action: MembershipHistoryAction.GetMembershipWithHistory) {
    return this.membershipService.getMembershipWithHistory(action.partnerId, action.memberId).pipe(
      errorHandler(this.snackbar),
      tap((response: Membership[]) => ctx.setState({ membership: response[0] }))
    );
  }

}
