<div id="auth-origin" fxLayout="row wrap" fxLayoutAlign="center center" style="height: 100vh">

  <ng-container *ngIf="redirecting;else hasError">
    <mat-spinner></mat-spinner>
  </ng-container>
  <ng-template #hasError>
    <information-page
      title="Request Error"
      description="Something went wrong. Please try again."
      actionType="reloadBrowser"
      actionText="Refresh">
    </information-page>
  </ng-template>

</div>
