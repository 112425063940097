import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { WebsiteChecklistState } from '@fitscovery/partners/data-access';
import { WebsiteChecklistBubbleService } from './website-checklist-bubble.service';
import { opacityFadeIn } from '@fitscovery/common/animations';
import { AdminAppState } from '@fitscovery/admin-web/shared/store';

@Component({
  selector: 'website-checklist-bubble',
  template: `
    <ng-container *ngIf="!(websiteChecklistState$ | async)">
      <ng-container *ngIf="(percentage$ | async) !== undefined">
        <div
          matRipple 
          class="website-checklist-bubble mat-elevation-z7"
          [ngClass]="{ prod: service.production }"
          [@opacityFadeIn]
          (click)="service.openWebsiteChecklistDrawer()"
          *ngIf="service.authenticated$ | async">
          <mat-icon>rocket_launch</mat-icon>
          <div class="website-checklist-bubble__content">
            <p>Instruction guide</p>
            <p>{{ (percentage$ | async) || '0' }}% completed</p>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./website-checklist-bubble.component.scss'],
  animations: [ opacityFadeIn(500) ]
})
export class WebsiteChecklistBubbleComponent {

  @Select(WebsiteChecklistState.percentage) percentage$: Observable<number>;
  @Select(AdminAppState.websiteChecklistState) websiteChecklistState$: Observable<boolean>;

  constructor(
    public service: WebsiteChecklistBubbleService
  ) { }

}
