import { Guid } from "@fitscovery/common/types";

export namespace AdminMembershipInvoiceActions {

  export class CreateMembershipInvoice {
    static readonly type = '[AdminMembershipInvoiceActions] CreateMembershipInvoice';
    constructor(public readonly email: string, public readonly pmsId: Guid) {}
  }

  export class GetMembershipInvoicesCount {
    static readonly type = '[AdminMembershipInvoiceActions] GetMembershipInvoicesCount';
    constructor(public readonly partnerId: Guid) {}
  }

  export class GetMembershipInvoices {
    static readonly type = '[AdminMembershipInvoiceActions] GetMembershipInvoices';
    constructor(public readonly partnerId: Guid, public readonly loadMore?: boolean) {}
  }
}
