import { Pipe, PipeTransform } from '@angular/core';

import { PartnerRole, PartnerRoleResponse } from '@fitscovery/user/data-access';
import { SelectedVendor } from './navbar.model';

@Pipe({
  name: 'partnerRole'
})
export class PartnerRolePipe implements PipeTransform {

  transform(vendor: PartnerRoleResponse | SelectedVendor | null): string {

    if (!vendor) return '';

    switch (vendor.role) {
      case PartnerRole.User: {
        return 'User';
      }
      case PartnerRole.PartnerManager: {
        return 'Partner Manager';
      }
      case PartnerRole.PartnerAdmin: {
        return 'Partner Admin';
      }
      case PartnerRole.SuperAdmin: {
        return 'Super Admin';
      }
      default: {
        return 'Partner Manager';
      }
    }
  }

}
