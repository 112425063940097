import { Pipe, PipeTransform } from '@angular/core';

import { PartnerOfferings } from '@fitscovery/partners/data-access';
import { MenuOption } from './sidenav.type';

@Pipe({
  name: 'updateMenuOptions'
})
export class UpdateMenuOptionsPipe implements PipeTransform {

  transform(menuOptions: MenuOption[], offering: PartnerOfferings): MenuOption[] {
    return menuOptions.map((option: MenuOption) => {
      if (option.route === '/memberships') {
        option.title = offering?.membershipName || 'Memberships';
      }
      if (option.route === '/events') {
        option.title = offering?.eventName || 'Schedule';
      }
      if (option.route === '/workouts') {
        option.title = offering?.workoutName || 'On-Demand Workouts';
      }
      if (option.route === '/bookings') {
        option.title = offering?.bookingName || 'Direct Booking';
      }
      return option;
    });
  }

}
