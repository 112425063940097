import { Inject, Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';

import { AdminPartnersAction, AdminPartnersState } from '@fitscovery/partners/data-access';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { OnboardingStoreActions } from './store.action';
import { StateModel } from './store.model';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

export const onboardingStoreDefaults: StateModel = {
  userPartnersState: false,
  hasVendorPartner: null
};

export const ONBOARDING_STORE_STATE_TOKEN = new StateToken<StateModel>('app');

@State<StateModel>({
  name: ONBOARDING_STORE_STATE_TOKEN,
  defaults: onboardingStoreDefaults
})
@Injectable()
export class OnboardingStoreState implements NgxsOnInit {

  @Selector()
  static userPartnersState(state: StateModel): boolean {
    return state.userPartnersState;
  }

  @Selector()
  static hasVendorPartner(state: StateModel): boolean {
    return state.hasVendorPartner!;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private snackbar: SnackbarService,
    private store: Store
  ) { }

  ngxsOnInit(): void {}

  @Action(OnboardingStoreActions.UserPartnersState)
  userPartnersState(ctx: StateContext<StateModel>, action: OnboardingStoreActions.UserPartnersState): void {
    ctx.patchState({ userPartnersState: action.state });
  }

  @Action(OnboardingStoreActions.HasVendorPartner)
  hasVendorPartner(ctx: StateContext<StateModel>, action: OnboardingStoreActions.HasVendorPartner): void {
    ctx.patchState({ hasVendorPartner: action.state });
  }

  @Action(OnboardingStoreActions.NavigateToAdmin)
  async navigateToAdmin(_: StateContext<StateModel>, action: OnboardingStoreActions.NavigateToAdmin): Promise<void> {

    if (!action.subdomain) {
      this.snackbar.openSnack({ message: 'Redirecting. Please wait...', duration: Infinity });
      try {
        await firstValueFrom(this.store.dispatch(new AdminPartnersAction.GetAdminPartners({})));
        const partner = this.store.selectSnapshot(AdminPartnersState.adminPartners)[0];
        this.window.location.href = `${this.env.fitscoveryApps.adminUrl}/dashboard/?p=${partner.subdomain}`;
      } catch (error) {
        this.window.location.href = `${this.env.fitscoveryApps.adminUrl}`;
      }
      return;
    }

    this.window.location.href = `${this.env.fitscoveryApps.adminUrl}/dashboard/?p=${action.subdomain}&highlight=true`;
  }

}
