import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SharedInformationPageModule } from '@fitscovery/shared/information-page';

import { FeatureOriginComponent } from './feature-origin/feature-origin.component';
import { Token, TOKEN } from './common';

const MODULES = [
  CommonModule,
  FlexLayoutModule,
  MatProgressSpinnerModule
];

const LIBRARIES = [
  SharedInformationPageModule
];

const COMPONENTS = [
  FeatureOriginComponent
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES,
    RouterModule.forChild([{
      path: '',
      component: FeatureOriginComponent
    }])
  ],
  declarations: [
    ...COMPONENTS
  ]
})
export class AuthFeatureOriginModule {
  static forRoot(token: Token): ModuleWithProviders<AuthFeatureOriginModule> {
    return {
      ngModule: AuthFeatureOriginModule,
      providers: [{ provide: TOKEN, useValue: token }]
    };
  }
}
