import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[animationToDefault]'
})
export class AnimationToDefaultDirective implements OnInit {

  constructor(
    private renderer: Renderer2,
    private element: ElementRef
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.renderer.removeClass(this.element.nativeElement, 'pre-load');
    }, 1400);
  }

}
