export class PushTitleHeight {
  static readonly type = '[Application] PushTitleHeight';
  
  constructor(public height: number) { }
}

export class ResetTitleHeight {
  static readonly type = '[Application] ResetTitleHeight';

  constructor() { }
}

export class ResetStateToDefault {
  static readonly type = '[Application] ResetStateToDefault';

  constructor() { }
}
