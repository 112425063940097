import { Guid } from '@fitscovery/common/types';

export namespace PMSEmailTemplateAction {

  export class GetPMSEmailTemplates {
    static readonly type = '[Memberships API] GetPMSEmailTemplates';
    constructor(public partnerId: Guid) { }
  }

}
