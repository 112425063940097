import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';

import { SocialMediaAuthEmailDialogComponent } from './social-media-auth-email-dialog/social-media-auth-email-dialog.component';

@Injectable()
export class SocialMediaAuthEmailDialogEventService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(): Observable<SocialMediaAuthEmailDialogComponent> {
    const ref = this.dialog.open(SocialMediaAuthEmailDialogComponent, {
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      width: '500px',
      maxWidth: 'auto',
      minWidth: 0,
      height: 'auto',
      minHeight: 0,
      panelClass: 'social-media-auth-email-dialog'
    });

    return ref.afterClosed().pipe(
      map(() => ref.componentInstance)
    );
  }

}
