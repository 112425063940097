import { ModuleWithProviders, NgModule, Optional, SkipSelf, Provider } from '@angular/core';

import { ProviderModules } from './modules';
import { SegmentAnalytics } from './segment-analytics';
import { SEGMENT_CONFIG, SEGMENT_DEFAULT_CONFIG } from './segment-config';
import { APP_TOKEN, Token } from './common';

@NgModule({
  imports: ProviderModules
})
export class SegmentLoggerModule {

  constructor(@Optional() @SkipSelf() parentModule: SegmentLoggerModule) {
    if (parentModule) {
      throw new Error('SegmentLoggerModule is already loaded. Import it in the AppModule only.');
    }
  }

  static forRoot(token: Token): ModuleWithProviders<SegmentLoggerModule> {

    const config = {
      apiKey: token.environment.apiKeys.segmentKey,
      debug: token.environment.production !== "true",
      loadOnInitialization: true,
      segmentHost: SEGMENT_DEFAULT_CONFIG.segmentHost,
      segmentUri: SEGMENT_DEFAULT_CONFIG.segmentUri
    };

    return {
      ngModule: SegmentLoggerModule,
      providers: [
        SegmentAnalytics,
        { provide: APP_TOKEN, useValue: token },
        { provide: SEGMENT_CONFIG, useValue: config }
      ],
    };
  }

  static forRootWithProvider(token: Token, provider: Provider): ModuleWithProviders<SegmentLoggerModule> {

    const config = {
      apiKey: token.environment.apiKeys.segmentKey,
      debug: token.environment.production !== "true",
      loadOnInitialization: true,
      segmentHost: SEGMENT_DEFAULT_CONFIG.segmentHost,
      segmentUri: SEGMENT_DEFAULT_CONFIG.segmentUri
    };

    return {
      ngModule: SegmentLoggerModule,
      providers: [
        provider!,
        SegmentAnalytics,
        { provide: APP_TOKEN, useValue: token },
        { provide: SEGMENT_CONFIG, useValue: config }
      ],
    };
  }

}
