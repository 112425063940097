import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DummyImageComponent } from './dummy-image/dummy-image.component';
import { SizeObserverDirective } from './dummy-image/size-observer.directive';

const MODULES = [
  CommonModule
];

const COMPONENTS = [
  DummyImageComponent
];

const DIRECTIVES = [
  SizeObserverDirective
];

@NgModule({
  imports: [
    ...MODULES
  ],
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  exports: [
    DummyImageComponent
  ]
})
export class SharedDummyImageModule {}
