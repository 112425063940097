import { NgModule } from '@angular/core';

import { MouseOverDirective } from './mouse-over.directive';

const DIRECTIVES = [
  MouseOverDirective
];

@NgModule({
  declarations: [
    ...DIRECTIVES,
  ],
  exports: [
    MouseOverDirective
  ]
})
export class MouseOverModule { }
