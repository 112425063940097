import { PartnerChecklist } from "@fitscovery/partners/data-access";

export interface Selection extends PartnerChecklist {
  header: string;
  content: string;
  action: string;
  path: string;
  queryName?: string;
  queryValue?: string;
  optional?: boolean;
  visibility: boolean;
  youtubeId: string;
}

export const selections: Selection[] = [
  {
    websiteChecklistItem: 'UPDATE_WEBSITE',
    header: 'Create your website',
    content: 'Design and customize your fitness website.',
    action: 'Manage your website',
    path: 'manage-website',
    visibility: true,
    completed: false,
    youtubeId: 'zgPrvLb3KKQ'
  },
  {
    websiteChecklistItem: 'CREATE_MEMBERSHIP_SUBSCRIPTION',
    header: 'Set up membership plans',
    content: 'Create and manage membership plans for clients, with discounts and perks.',
    action: 'Go to Memberships',
    path: 'memberships',
    visibility: false,
    completed: false,
    youtubeId: '4UqiQ310u1o'
  },
  {
    websiteChecklistItem: 'CREATE_EVENT',
    header: 'Schedule your classes',
    content: 'Manage your class schedules and availability.',
    action: 'Go to Schedules',
    path: 'events',
    visibility: false,
    completed: false,
    youtubeId: 'QZy11kqJ1RM'
  },
  {
    websiteChecklistItem: 'UPDATE_PAYOUT_DETAILS',
    header: 'Input your bank details',
    content: 'Add or edit your bank account details for receiving payouts.',
    action: 'Go to settings',
    path: 'settings',
    queryName: 'active_tab',
    queryValue: 'payments',
    visibility: false,
    completed: false,
    youtubeId: 'YKBk3j0A7FI'
  },
  {
    websiteChecklistItem: 'CREATE_WORKOUT',
    header: 'Show your online workouts',
    content: 'Create and showcase your workouts and fitness programs.',
    action: 'Go to workouts',
    path: 'workouts',
    optional: true,
    visibility: false,
    completed: false,
    youtubeId: '_9RjWC9-0NQ'
  },
  {
    websiteChecklistItem: 'ACTIVATE_DIRECT_BOOKING',
    header: 'Setup direct booking',
    content: 'Configure your settings for 1-on-1 bookings',
    action: 'Go to direct bookings',
    path: 'bookings',
    optional: true,
    visibility: false,
    completed: false,
    youtubeId: 'furBcAV0rYM'
  }
];
