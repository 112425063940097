import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import { NgxsModule } from '@ngxs/store';
import { APP_ENVIRONMENT } from './environment-injection';
import { ApiAdminInvoiceService, ApiAdminRecurringPaymentService, ApiInvoiceService } from './services';
import { AdminMembershipInvoiceState, MyWebInvoiceState } from './store';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([AdminMembershipInvoiceState])],
  providers: [ApiInvoiceService, ApiAdminInvoiceService, ApiAdminRecurringPaymentService],
})
export class PaymentDataAccessModule {
  static forRoot(environment: Environment): ModuleWithProviders<PaymentDataAccessModule> {
    return {
      ngModule: PaymentDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }],
    };
  }
}
