import { App } from '@fitscovery/common/types';
import { Environment, EnvironmentKeys } from './environment.interface';
import * as prod from './environment.prod';

export function keys(app: App): EnvironmentKeys {
  return {
    get environment(): Environment {
      return {
        ...prod.keys(app).environment,
        azureFunctionsApi: {
          auth: 'https://api-auth-fitscovery.azurewebsites.net/api',
          events: 'https://api-events-fitscovery.azurewebsites.net/api',
          memberships: 'https://functions-memberships-fitscovery.azurewebsites.net/api',
          reports: 'https://prod-functions-reports-fitscovery-sea.azurewebsites.net/api',
          partners: 'https://api-partners-fitscovery.azurewebsites.net/api',
          users: 'https://api-users-fitscovery.azurewebsites.net/api',
          workouts: 'https://api-workouts-fitscovery.azurewebsites.net/api',
          products: 'https://functions-products-fitscovery.azurewebsites.net/api',
          payments: 'https://functions-payments-fitscovery.azurewebsites.net/api',
          posts: 'https://functions-posts-fitscovery.azurewebsites.net/api',
          bookings: 'https://functions-bookings-fitscovery.azurewebsites.net/api',
          notifications: 'https://functions-notifications-fitscovery-node.azurewebsites.net/api',
        },
        nodeApi: {
          auth: 'https://api-auth-sea.fitscovery.com/api/v1',
          users: 'https://api-users-sea.fitscovery.com/api',
          payments: 'https://api-payments.fitscovery.com/api/v1',
          partners: 'https://api-partners.fitscovery.com/api/v1',
          partnerWebsites: 'https://api-partner-websites.fitscovery.com/api/v1'
        }
      }
    }
  }
};
