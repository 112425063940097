import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, filter, map, Observable, tap } from 'rxjs';

import { User, UserState } from '@fitscovery/user/data-access';
import { PartnerChecklist, WebsiteChecklistState, WebsiteChecklistService as Service, WebsiteChecklistAction, PartnerState } from '@fitscovery/partners/data-access';
import { AdminAppState, DrawerState } from '@fitscovery/admin-web/shared/store';
import { Selection, selections } from './common';
import { Guid } from '@fitscovery/common/types';

@Injectable()
export class WebsiteChecklistService {

  @Select(UserState.user) user$: Observable<User>;
  @Select(WebsiteChecklistState.checklist) checklist$: Observable<PartnerChecklist[]>;
  @Select(WebsiteChecklistState.percentage) percentage$: Observable<number>;
  @Select(AdminAppState.drawerState) drawerState$: Observable<DrawerState>;

  websiteChecklist$ = new BehaviorSubject<Selection[]>(selections);

  constructor(
    private store: Store,
    private service: Service
  ) { }

  getChecklist(): void {
    this.initializeWebsiteChecklist(
      !!this.store.select(WebsiteChecklistState.state)
    );
  }

  get websiteChecklistDrawerOpened$(): Observable<boolean> {
    return this.drawerState$.pipe(
      map((drawer: DrawerState) => drawer.state && drawer.type === 'checklist')
    );
  }

  private get partnerId(): Guid {
    return this.store.selectSnapshot(PartnerState.partnerId);
  }

  private initializeWebsiteChecklist(loaded: boolean): void {
    
    if (loaded) this.service.loadWebsiteChecklist();

    this.store.select(WebsiteChecklistState.checklist).pipe(
      filter(Boolean),
      tap((checklistItems: PartnerChecklist[]) => {
        this.websiteChecklist$.next(selections.map((selection: Selection) => {
          selection.completed = checklistItems.find(checklist => checklist.websiteChecklistItem === selection.websiteChecklistItem)?.completed!;
          return selection;
        }));
      })
    ).subscribe();
  }

  completeSelection({ item, completed }: any): void {
    if (item === 'CREATE_WORKOUT' && !completed) {
      this.store.dispatch(new WebsiteChecklistAction.UpdateWebsiteChecklist({
        partnerId: this.partnerId,
        partnerWebsiteChecklistItems: [{
          completed: !completed, websiteChecklistItem: item
        }]
      }));
    }
  }

}
