import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { RouterState } from '@ngxs/router-plugin';
import { BehaviorSubject, Observable, delay, filter, map, take, firstValueFrom, tap, switchMap } from 'rxjs';

import { AccountState, ApiAuthAction, SessionAction, SessionState, UserAccount } from '@fitscovery/auth/data-access';
import { PartnerAction } from '@fitscovery/partners/data-access';
import { PartnerWebsiteState, PartnerWebsite, PartnerWebsiteAction } from '@fitscovery/partner-websites/data-access';
import { PartnerRoleResponse, User, UserActions, UserState } from '@fitscovery/user/data-access';
import { WebPushNotificationActions } from '@fitscovery/notification/data-access';
import { WINDOW } from '@ng-web-apis/common';
import { SelectedVendor, websiteUrl } from './common';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { environment } from 'apps/admin-web/src/environments/environment';

@Injectable()
export class NavbarService {

  @Select(SessionState.authenticated) authenticated$: Observable<boolean>;
  @Select(AccountState.user) accountUser$: Observable<UserAccount>;
  @Select(UserState.user) user$: Observable<User>;
  @Select(UserState.partners) partners$: Observable<PartnerRoleResponse[]>;
  @Select(PartnerWebsiteState.partnerWebsite) partnerWebsite$: Observable<PartnerWebsite>;
  @Select(RouterState.state) routerState$: Observable<RouterStateSnapshot>;

  selectedVendorSource = new BehaviorSubject<SelectedVendor | null>(null);
  selectedVendor$ = this.selectedVendorSource.asObservable();

  constructor(
    @Inject(WINDOW) private window: any,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private snackbar: SnackbarService,
  ) { }

  initializeState(): void {
    this.authenticated$.pipe(
      filter(Boolean),
      tap(async () => {
      try {
        this.snackbar.openSnack({ message: 'Please wait...', duration: Infinity });

        await firstValueFrom(this.store.dispatch(new UserActions.GetCurrentUser));
        if (localStorage.getItem('new_vendor')) {
          await this.initializeNewVendor(localStorage.getItem('new_vendor')!);
          this.setSubdomainInQueryParams(localStorage.getItem('new_vendor')!, true);
        } else {
          await firstValueFrom(this.store.dispatch(new UserActions.GetUserPartnersByUserId(
            this.store.selectSnapshot(UserState.userId)
          )));
          const subdomain = this.store.selectSnapshot(UserState.partners)![0]?.partnerData?.subdomain!
          await firstValueFrom(this.store.dispatch([
            new PartnerAction.GetPartnerBySubdomain(subdomain),
            new PartnerWebsiteAction.GetPartnerWebsiteBySubdomain(subdomain)
          ]));
          this.updateSelectedVendor(subdomain);
          this.setSubdomainInQueryParams(subdomain);
        }
        
        this.snackbar.destroyAll();
      } catch (response) {
        if (response?.error?.errors[0] === 'User is not an admin or manager of any partner.') {
          this.window.location.href = environment.fitscoveryApps.onboardingUrl;
          return; // To do: revisit this if condition and remove this code
        }
        setTimeout(() => location.reload(), 500);
      }
      }),
      take(1)
    ).subscribe();
  }
  
  initializeNotificationState(): void {
    this.store.select(UserState.user).pipe(
      filter(Boolean),
      filter(() => this.store.selectSnapshot(SessionState.authenticated)),
      delay(15000),
      tap((user: User) => this.store.dispatch(new WebPushNotificationActions.InitializeWebPushNotification(user?.id || null, null, 'ADMIN'))),
      take(1)
    ).subscribe();
  }

  currentSubdomainObserver(): void {
    this.store.select(RouterState.state).pipe(
      filter(Boolean),
      map((e) => e.root.queryParams['p']?.toLowerCase()),
      switchMap((subdomain: string) => this.accountUser$.pipe(
        filter(Boolean),
        map(() => subdomain)
      )),
      tap(async (subdomain: string) => {
      try {
        if (localStorage.getItem('new_vendor')!) {
          return this.initializeNewVendor(localStorage.getItem('new_vendor')!);
        }

        if (subdomain && !this.getVendorBySubdomain(subdomain)) {
          return this.selectedVendor$.pipe(take(1)).subscribe(async () => {
            this.snackbar.openSnack({ message: 'Please wait...', duration: Infinity });
            await firstValueFrom(this.store.dispatch(new UserActions.GetUserPartnersByUserId(
              this.store.selectSnapshot(UserState.userId)
            )));
            this.snackbar.openSnack({ message: `You don't own a partner with the name "${subdomain}".`, duration: 5000 });
            this.selectPartner(this.store.selectSnapshot(UserState.partners)[0]);
          });
        }

        if (subdomain) {
          this.updateSelectedVendor(subdomain);
          await firstValueFrom(this.store.dispatch([
            new PartnerAction.GetPartnerBySubdomain(subdomain),
            new PartnerWebsiteAction.GetPartnerWebsiteBySubdomain(subdomain)
          ]));
          this.updateSelectedVendor(subdomain);
        } else {
          this.store.selectSnapshot(UserState.user)
          || await firstValueFrom(this.store.dispatch(new UserActions.GetCurrentUser));
          this.store.selectSnapshot(UserState.partners) 
          || await firstValueFrom(this.store.dispatch(new UserActions.GetUserPartnersByUserId(
            this.store.selectSnapshot(UserState.userId)
          )));
          subdomain = localStorage.getItem('selectedPartner') || this.store.selectSnapshot(UserState.partners)[0].partnerData.subdomain!;
          this.updateSelectedVendor(subdomain);
          this.setSubdomainInQueryParams(subdomain);
          this.store.dispatch([
            new PartnerAction.GetPartnerBySubdomain(subdomain),
            new PartnerWebsiteAction.GetPartnerWebsiteBySubdomain(subdomain)
          ]);
        }
      } catch (response) {
        if (response?.error?.errors?.[0] === 'User is not an admin or manager of any partner.') {
          this.window.location.href = environment.fitscoveryApps.onboardingUrl;
          return; // To do: revisit this if condition and remove this code
        }
        setTimeout(() => location.reload(), 500);
      }
      }),
      take(1)
    ).subscribe();
  }

  async checkSession(): Promise<void> {
    if (this.store.selectSnapshot(SessionState.authenticated)) return;
    try {
      localStorage.setItem('from_auth', '1');
      await firstValueFrom(this.store.dispatch(new SessionAction.CheckSession));
    } catch (error) {
      const origin = environment.fitscoveryApps.adminUrl;
      this.window.location.href = `${environment.fitscoveryApps.accountUrl}/auth/login/?origin=${origin}`;
    }
  }

  async checkSessionCookie(): Promise<void> {
    try {
      await firstValueFrom(this.store.dispatch(new SessionAction.CheckSession));
    } catch (error) {
      this.logout();
    }
  }

  async initializeNewVendor(subdomain: string): Promise<void> {
    this.snackbar.openSnack({ message: 'Please wait...', duration: Infinity })
      await firstValueFrom(this.store.dispatch(new UserActions.GetUserPartnersByUserId(
      this.store.selectSnapshot(AccountState.user).id as any
    )));
    await firstValueFrom(this.store.dispatch([
      new PartnerAction.GetPartnerBySubdomain(subdomain),
      new PartnerWebsiteAction.GetPartnerWebsiteBySubdomain(subdomain)
    ]));
    setTimeout(() => {
      this.updateSelectedVendor(subdomain);
      this.setSubdomainInQueryParams(subdomain);
      this.snackbar.destroyAll();
      localStorage.removeItem('new_vendor'); 
    });
  }

  async selectPartner(partner: PartnerRoleResponse): Promise<void> {

    const subdomain = partner.partnerData.subdomain!;
    const prevSubdomain = this.route.snapshot.queryParams['p'];

    if (prevSubdomain === subdomain) {
      this.snackbar.openSnack({ message: 'Partner is already selected.' });
      return;
    }

    this.updateSelectedVendor(subdomain);
    this.setSubdomainInQueryParams(subdomain);

    this.snackbar.openSnack({ message: 'Switching partner', duration: Infinity });
    await firstValueFrom(this.store.dispatch([
      new PartnerAction.GetPartnerBySubdomain(subdomain),
      new PartnerWebsiteAction.GetPartnerWebsiteBySubdomain(subdomain)
    ]));
    this.snackbar.openSnack({ message: `Switched to ${partner.partnerData.name}!` })
  }

  private updateSelectedVendor(subdomain: string): void {

    const vendors = this.store.selectSnapshot(UserState.partners)!;
    const vendor = subdomain ? this.getVendorBySubdomain(subdomain) : (vendors && vendors[0]);

    if (!vendor) return this.initializeState();

    this.selectedVendorSource.next({
      subdomain: vendor?.partnerData.subdomain!,
      logoUrl: vendor?.partnerData.logoUrl!,
      role: vendor?.role
    });
  }

  viewWebsite(): void {
    const subdomain = this.store.selectSnapshot(PartnerWebsiteState.subdomain)!;
    this.selectedVendor$.pipe(
      tap(() => this.window.open(websiteUrl(subdomain), '_blank')),
      take(1)
    ).subscribe();
  }

  logout(): void {
    if (!this.store.selectSnapshot(SessionState.authenticated)) return;
    localStorage.removeItem('selectedPartner');
    this.store.dispatch(new ApiAuthAction.SignOut);
  }

  private setSubdomainInQueryParams(subdomain: string, highlight?: boolean): Promise<boolean> {
    const route = this.store.selectSnapshot(RouterState.state)?.url.split('?')[0];
    const path = route === '/' ? [ '/dashboard' ] : [];
    return this.router.navigate(path, {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: highlight ? { p: subdomain, highlight } : { p: subdomain }
    });
  }

  getVendorBySubdomain(subdomain: string): PartnerRoleResponse | null {
    const vendors = this.store.selectSnapshot(UserState.partners);
    return vendors.find((partnerRole: PartnerRoleResponse) => partnerRole?.partnerData?.subdomain === subdomain) || null;
  }

}
