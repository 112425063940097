import { Environment } from './environment.interface';

export const environment: Environment = {
  local: false,
  production: "#{production}#",
  fitscoveryApps: {
    accountUrl: '#{fitscoveryAppsAccountUrl}#',
    onboardingUrl: '#{fitscoveryAppsOnboardingUrl}#',
    adminUrl: '#{fitscoveryAppsAdminUrl}#',
    myDiscoveryUrl: '#{fitscoveryAppsMyDiscoveryUrl}#',
    backOfficeUrl: '#{fitscoveryAppsBackOfficeUrl}#',
    embedUrl: '#{fitscoveryAppsEmbedUrl}#'
  },
  apiKeys: {
    appInsights: '#{apiKeysAppInsights}#',
    googleMaps: '#{apiKeysGoogleMaps}#',
    googleTagManager: {
      id: '#{apiKeysGoogleTagManagerId}#',
      authKey: '#{apiKeysGoogleTagManagerAuthKey}#',
      preview: '#{apiKeysGoogleTagManagerPreview}#'
    },
    recaptchaKey: '#{apiKeysRecapthaKey}#',
    segmentKey: '#{apiKeysSegmenKey}#',
    pushKey: '#{apiKeysPushKey}#',
    facebookAppId: '#{apiKeysFacebookAppId}#',
    facebookClientToken: '#{apiKeysFacebookClientToken}#'
  },
  azureFunctionsApi: {
    auth: '#{azureFunctionsApiAuth}#',
    events: '#{azureFunctionsApiEvents}#',
    memberships: '#{azureFunctionsApiMemberships}#',
    reports: '#{azureFunctionsApiReports}#',
    partners: '#{azureFunctionsApiPartners}#',
    users: '#{azureFunctionsApiUsers}#',
    workouts: '#{azureFunctionsApiWorkouts}#',
    products: '#{azureFunctionsApiProducts}#',
    payments: '#{azureFunctionsApiPayments}#',
    posts: '#{azureFunctionsApiPosts}#',
    bookings: '#{azureFunctionsApiBookings}#',
    notifications: '#{azureFunctionsApiNotifications}#',
    partnerWebsites: '#{azureFunctionsApiPartnerWebsites}#'
  },
  azureFunctions: {
    images: {
      uploadUrl: '#{azureFunctionsImagesUploadUrl}#',
      api: '#{azureFunctionsImagesApi}#',
      functionsKey: '#{azureFunctionsImagesFunctionsKey}#',
    },
  },
  paymentsApi: {
    paymongo: '#{paymentsApiPaymongo}#',
    paymongoKey: '#{paymentsApiPaymongoKey}#',
  },
  firebase: {
    apiKey: '#{firebaseApiKey}#',
    authDomain: '#{firebaseAuthDomain}#',
    databaseURL: '#{firebaseDatabaseUrl}#',
    projectId: '#{firebaseProjectId}#',
    storageBucket: '#{firebaseStorageBucket}#',
    messagingSenderId: '#{firebaseMessageSenderId}#',
    appId: '#{firebaseAppId}#',
    measurementId: '#{firebasemeasurementId}#',
  },
  version: '#{buildVersion}#',
  nodeApi: {
    auth: '#{nodeApiAuth}#',
    events: '#{nodeApiEvents}#',
    memberships: '#{nodeApiMemberships}#',
    reports: '#{nodeApiReports}#',
    partners: '#{nodeApiPartners}#',
    users: '#{nodeApiUsers}#',
    workouts: '#{nodeApiWorkouts}#',
    products: '#{nodeApiProducts}#',
    payments: '#{nodeApiPayments}#',
    posts: '#{nodeApiPosts}#',
    bookings: '#{nodeApiBookings}#',
    notifications: '#{nodeApiNotifications}#',
    partnerWebsites: '#{nodeApiPartnerWebsites}#'
  },
};
