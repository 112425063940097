import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiPartnersService, ApiAdminPartnersService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { PartnerAction, PartnerStateModel, Partner, PartnerOfferings, Classification, PartnerLinks, CancellationPolicy } from '.';
import { GetPartnerBySubdomainResponse, UpdateAdminCancellationPolicyResponse, UpdateAdminContactInfoResponse, UpdateAdminLinksResponse, UpdateAdminOfferingsResponse, UpdateAdminSettingsResponse } from '../interfaces';
import { errorHandler } from '@fitscovery/common/providers';
import { Guid } from '@fitscovery/common/types';

export const partnersStateDefaults: PartnerStateModel = null;

export const PARTNERS_STATE_TOKEN = new StateToken<PartnerStateModel>('api_partners');

@State<PartnerStateModel>({
  name: PARTNERS_STATE_TOKEN,
  defaults: partnersStateDefaults
})

@Injectable()
export class PartnerState {

  @Selector()
  static partner(state: PartnerStateModel): Partner {
    return state!;
  }

  @Selector()
  static partnerId(state: PartnerStateModel): Guid {
    return state?.id!.toString().toLowerCase()! as any
  }

  @Selector()
  static subdomain(state: PartnerStateModel): string {
    return state!.subdomain;
  }

  @Selector()
  static currency(state: PartnerStateModel): string {
    return state!.currency;
  }

  @Selector()
  static offerings(state: PartnerStateModel): PartnerOfferings {
    return state!.offerings;
  }

  @Selector()
  static cancellatioPolicy(state: PartnerStateModel): CancellationPolicy {
    return state!.cancellationPolicy;
  }

  @Selector()
  static links(state: PartnerStateModel): PartnerLinks {
    return state!.links;
  }

  @Selector()
  static classifications(): Classification[] {
    return [
      { id: 0, name: "Not Sure Yet" }, { id: 1, name: "Single-person trainer" }, { id: 2, name: "Group/Community" },
      { id: 3, name: "Fitness Studio" }, { id: 4, name: "Multi-studio/gym" }, { id: 10, name: "Other" } 
    ]; 
  }

  @Selector()
  static services(): Classification[] {
    return [
      { id: 1, name: "Online Classes" }, { id: 2, name: "Offline Classes" }, { id: 3, name: "Direct Bookings" },
      { id: 4, name: "On-Demand Workouts" }, { id: 5, name: "Sell Products" }, { id: 10, name: "Other" }
    ]; 
  }


  constructor(
    private partners: ApiPartnersService,
    private adminPartners: ApiAdminPartnersService,
    private snackbar: SnackbarService
  ) { }

  @Action(PartnerAction.GetPartnerBySubdomain)
  getPartnerBySubdomain(ctx: StateContext<PartnerStateModel>, action: PartnerAction.GetPartnerBySubdomain) {
    return this.partners.getPartnerBySubdomain(action.subdomain).pipe(
      errorHandler(this.snackbar),
      tap((response: GetPartnerBySubdomainResponse) => ctx.setState(response.data.partner))
    );
  }

  @Action(PartnerAction.UpdatePartnerSettings)
  updatePartnerSettings(ctx: StateContext<PartnerStateModel>, action: PartnerAction.UpdatePartnerSettings) {
    return this.adminPartners.updateAdminSettings(action.partnerId, action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: UpdateAdminSettingsResponse) => ctx.patchState({
        timezone: response.data.partnerSettings.timezone,
        countryCode: response.data.partnerSettings.countryCode,
        currency: response.data.partnerSettings.currency
      }))
    );
  }

  @Action(PartnerAction.UpdatePartnerContactInfo)
  updatePartnerContactInfo(ctx: StateContext<PartnerStateModel>, action: PartnerAction.UpdatePartnerContactInfo) {
    return this.adminPartners.updateAdminContactInfo(action.partnerId, action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: UpdateAdminContactInfoResponse) => ctx.patchState({
        contactInfo: {
          email: response.data.partnerContactInfo.email,
          phoneNumber: response.data.partnerContactInfo.phoneNumber
        }
      }))
    );
  }

  @Action(PartnerAction.UpdatePartnerOfferings)
  updatePartnerOfferings(ctx: StateContext<PartnerStateModel>, action: PartnerAction.UpdatePartnerOfferings) {
    return this.adminPartners.updateAdminOfferings(action.partnerId, action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: UpdateAdminOfferingsResponse) => ctx.patchState({ offerings: response.data.partnerOfferings }))
    );
  }

  @Action(PartnerAction.UpdatePartnerLinks)
  updatePartnerLinks(ctx: StateContext<PartnerStateModel>, action: PartnerAction.UpdatePartnerLinks) {
    return this.adminPartners.updateAdminLinks(action.partnerId, action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: UpdateAdminLinksResponse) => ctx.patchState({ links: response.data.partnerLinks }))
    );
  }

  @Action(PartnerAction.UpdatePartnerCancellationPolicy)
  updatePartnerCancellationPolicy(ctx: StateContext<PartnerStateModel>, action: PartnerAction.UpdatePartnerCancellationPolicy) {
    return this.adminPartners.updateAdminCancellationPolicy(action.partnerId, action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: UpdateAdminCancellationPolicyResponse) => ctx.patchState({ cancellationPolicy: response.data.result }))
    );
  }

}
