import { AuthProviderModule } from './auth-tracking';
import { BookingsProviderModule } from './bookings-tracking';
import { EventsProviderModule } from './events-tracking';
import { MembershipsProviderModule } from './memberships-tracking';
import { NotificationsProviderModule } from './notifications-tracking';
import { PartnerWebsitesProviderModule } from './partner-websites-tracking';
import { PartnersProviderModule } from './partners-tracking';
import { PaymentsProviderModule } from './payments-tracking';
import { PostsProviderModule } from './posts-tracking';
import { ReportsProviderModule } from './reports-tracking';
import { UserProviderModule } from './user-tracking';
import { WorkoutsProviderModule } from './workouts-tracking';

export const ProviderModules = [
  EventsProviderModule,
  AuthProviderModule,
  BookingsProviderModule,
  EventsProviderModule,
  MembershipsProviderModule,
  NotificationsProviderModule,
  PartnerWebsitesProviderModule,
  PartnersProviderModule,
  PaymentsProviderModule,
  PostsProviderModule,
  ReportsProviderModule,
  UserProviderModule,
  WorkoutsProviderModule
];
