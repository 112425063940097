import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, ActionType, Store } from '@ngxs/store';

import { EventsActions } from '@fitscovery/events/data-access';
import { AzureLoggerService } from '../azure-logger.service';
import { TrackingService } from '../tracking-service';

@Injectable()
export class EventsTrackingService extends TrackingService {

  constructor(
    store: Store,
    actions$: Actions,
    logger: AzureLoggerService
  ) {

    super(store, actions$, logger);

    const actions = [ EventsActions.GetEventById, EventsActions.GetEventByIdWithPartnerId, EventsActions.GetEventByIdAndSubdomain, EventsActions.GetEventsBySubdomain, EventsActions.CreateEvent, EventsActions.UpdateEvent, EventsActions.DuplicateEvent, EventsActions.CancelEvent, EventsActions.DeleteEvent, EventsActions.GetAttendeesWithIdByEventId, EventsActions.AddEventAttendees, EventsActions.UpdateEventAttendee, EventsActions.UpdateAllEventAttendee, EventsActions.DownloadCsvEventAttendees, EventsActions.BookNow, EventsActions.SoftReserve, EventsActions.CancelFreeEventSlot, EventsActions.CancelEventSlot ] as ActionType[];

    actions.forEach((action: ActionType) => this.trackDispatch(action));
  }

}

@NgModule()
export class EventsProviderModule {
  constructor(
    @Optional() service: EventsTrackingService
  ) { }
}
