import { Guid } from '@fitscovery/common/types';

export namespace EmailTemplateAction {

  export class GetEmailTemplates {
    static readonly type = '[Memberships API] GetEmailTemplates';
    constructor(public partnerId: Guid) { }
  }

}
