export * from './api-admin-payout-methods.service';
export * from './api-admin-partners.service';
export * from './api-payment-methods.service';
export * from './api-partners.service';

import { ApiAdminPayoutMethodsService } from './api-admin-payout-methods.service';
import { ApiAdminPartnersService } from './api-admin-partners.service';
import { ApiPaymentMethodsService } from './api-payment-methods.service';
import { ApiPartnersService } from './api-partners.service';

export const ApiServices = [
  ApiAdminPayoutMethodsService,
  ApiAdminPartnersService,
  ApiPaymentMethodsService,
  ApiPartnersService
];
