import { Component, EventEmitter, Input, Output } from '@angular/core';

import { expand, Selection } from '../../common';

@Component({
  selector: 'website-checklist-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss'],
  animations: [ expand ]
})
export class SelectionComponent {

  @Output() completed = new EventEmitter();
  @Input() selections: Selection[];

}
