import { Directive, HostListener } from '@angular/core';
import { AdminAppState, AdminAppStateAction } from '@fitscovery/admin-web/shared/store';
import { Store } from '@ngxs/store';

@Directive({
  selector: '[scrollListener]'
})
export class ScrollListenerDirective {

  constructor(
    private store: Store
  ) { }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {

    const scrollTop = event.target.scrollTop;
    const scrollEnd = (event.target.scrollHeight - event.target.clientHeight) - scrollTop;
    
    let show = scrollEnd;
    
    if (event.target.clientWidth < 839) {
      show = scrollEnd - 70;
    }
    if (event.target.clientWidth < 531) {
      show = scrollEnd - 90;
    }

    if (show < 1) {
      this.store.selectSnapshot(AdminAppState.websiteChecklistState)
      || this.store.dispatch(new AdminAppStateAction.WebsiteChecklistState(true));
    } else {
      !this.store.selectSnapshot(AdminAppState.websiteChecklistState)
      || this.store.dispatch(new AdminAppStateAction.WebsiteChecklistState(false));
    }
  }
}
