<div id="navbar" fxLayout="row" fxLayoutAlign="start center" animationToDefault class="pre-load mat-elevation-z2">

  <button mat-icon-button (click)="openSidenavDrawer()">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="logo-wrapper web" fxLayout="row" fxLayoutAlign="start center">
    <img src="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/fitscovery-black.png" alt="fitscovery-logo" height="45" routerLink="/" queryParamsHandling="merge">
  </div>
  <div class="logo-wrapper mobile">
    <img src="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/admin-ng/fitscovery-icon.png" alt="fitscovery-icon" height="30" routerLink="/" queryParamsHandling="merge">
  </div>

  <ng-container *ngIf="(service.accountUser$ | async) && (service.partnerWebsite$ | async)">

    <p class="view-website" (click)="service.viewWebsite()" [matTooltip]="(service.partnerWebsite$ | async)?.subdomain! | websiteUrl" [@opacityFadeIn]>View Website</p>

    <div fxLayout="row" fxLayoutAlign="center" [@opacityFadeIn]>

      <ng-container *ngIf="(service.selectedVendor$ | async)?.logoUrl;else selectedVendorDummyImage">
        <img
          class="user"
          alt="partner-image"
          defaultImage="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/default-image.png" 
          errorImage="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/default-image.png" 
          [lazyLoad]="(service.selectedVendor$ | async)?.logoUrl!"
          matTooltip="Change website"
          matTooltipPosition="below"
          (click)="toggleOverlay()">
      </ng-container>
      <ng-template #selectedVendorDummyImage>
        <dummy-image
          size="50"
          [ngStyle]="{ 'margin-right': '12px' }"
          [value]="(service.partnerWebsite$ | async)?.name!"
          matTooltip="Change website"
          matTooltipPosition="below"
          (click)="toggleOverlay()">
        </dummy-image>
      </ng-template>

      <div
        class="cdk-origin"
        cdkOverlayOrigin
        #userPartnerDetails="cdkOverlayOrigin">
      </div>
    </div>
    
    <div class="partner-details" fxLayout="column" fxLayoutAlign="start start" [@opacityFadeIn]>
      <p>{{ (service.partnerWebsite$ | async)?.name }}</p>
      <p>{{ (service.selectedVendor$ | async) | partnerRole }}</p>
    </div>

    <fitscovery-feature-notification-button
      [userId]="(service.user$ | async)?.id?.toString() || ''"
      [partnerId]="(service.partnerWebsite$| async)?.partnerId?.toString() || ''"
      [application]="'ADMIN'"
      [@opacityFadeIn]>
    </fitscovery-feature-notification-button>

    <ng-template
      cdkConnectedOverlay
      (overlayOutsideClick)="closeOverlay()"
      (overlayKeydown)="closeOverlay()"
      [cdkConnectedOverlayOrigin]="userPartnerDetails"
      [cdkConnectedOverlayOpen]="isUserPartnerDetailsOpened">
      <user-partner-details (closeOverlay)="closeOverlay()" (logout)="service.logout();closeOverlay();" [overlayState]="overlayState">
        <div *ngFor="let vendor of service.partners$ | async" (click)="service.selectPartner(vendor);closeOverlay()" class="partners">
    
          <ng-container *ngIf="vendor.partnerData.logoUrl;else partnerDummyImage">
            <img 
              defaultImage="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/default-image.png" 
              errorImage="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/default-image.png"
              [lazyLoad]="vendor.partnerData.logoUrl"
              [alt]="vendor.partnerData.name">
          </ng-container>
          <ng-template #partnerDummyImage>
            <dummy-image
              size="50"
              [value]="vendor.partnerData.name!">
            </dummy-image>
          </ng-template>
    
          <div fxFlexOffset="16px" fxLayout="column" fxLayoutAlign="start">
            <span class="menu-text">{{ vendor.partnerData.name }}</span>
            <span class="menu-text">{{ vendor | partnerRole }}</span>
          </div>

        </div>
      </user-partner-details>
    </ng-template>

  </ng-container>

</div>
