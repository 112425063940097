import { getActionTypeFromInstance } from '@ngxs/store';
import { RouterDataResolved, RouterNavigation } from '@ngxs/router-plugin';

import { AuthDataAccessModule, API_AUTH_STATE_TOKEN } from '@fitscovery/auth/data-access';
import { UserDataAccessModule, API_USER_STATE_TOKEN } from '@fitscovery/user/data-access';
import { PartnersDataAccessModule, PartnerState, WebsiteChecklistState } from '@fitscovery/partners/data-access';
import { PartnerWebsitesDataAccessModule, PartnerWebsiteState } from '@fitscovery/partner-websites/data-access';
import { EventsDataAccessModule, API_EVENTS_STATE_TOKEN } from '@fitscovery/events/data-access';
import { MembershipsDataAccessModule, API_MEMBERSHIPS_STATE_TOKEN, PMSEmailTemplateState, EmailTemplateState, MembershipHistoryState, PartnerMemberState, MemberState } from '@fitscovery/memberships/data-access';
import { ReportsDataAccessModule } from '@fitscovery/reports/data-access';
import { PaymentsDataAccessV3Module } from '@fitscovery/payments/data-access-v3';
import { BookingsDataAccessModule, API_BOOKINGs_STATE_TOKEN } from '@fitscovery/bookings/data-access';
import { AdminWebSharedStoreModule, AdminAppStateAction } from '@fitscovery/admin-web/shared/store';
// TODO: Refactor
import { PaymentDataAccessModule } from '@fitscovery/payments/data-access';
import { WorkoutState, ProgramState, WorkoutsDataAccessModule } from '@fitscovery/workouts/data-access';
import { NotificationDataAccessModule } from '@fitscovery/notification/data-access';

import { environment } from '../environments/environment';

export const STATES = [
  PartnerState,
  WebsiteChecklistState,
  PartnerWebsiteState,
  PMSEmailTemplateState,
  EmailTemplateState,
  MembershipHistoryState,
  PartnerMemberState,
  MemberState
];

export const STORED_STATES = [
  API_AUTH_STATE_TOKEN,
  API_USER_STATE_TOKEN,
  API_EVENTS_STATE_TOKEN,
  API_MEMBERSHIPS_STATE_TOKEN,
  API_BOOKINGs_STATE_TOKEN,
  // TODO: Refactor
  WorkoutState,
  ProgramState
];

export const STATE_MODULES_IMPORT = [
  AuthDataAccessModule.forRoot({ app: 'admin', environment }),
  UserDataAccessModule.forRoot({ app: 'admin', environment }),
  PartnersDataAccessModule.forRoot(environment),
  PartnerWebsitesDataAccessModule.forRoot(environment),
  EventsDataAccessModule.forRoot(environment),
  MembershipsDataAccessModule.forRoot(environment),
  ReportsDataAccessModule.forRoot(environment),
  PaymentsDataAccessV3Module.forRoot(environment),
  BookingsDataAccessModule.forRoot(environment),
  AdminWebSharedStoreModule,
  // TODO: Refactor
  PaymentDataAccessModule.forRoot(environment),
  WorkoutsDataAccessModule.forRoot(environment),
  NotificationDataAccessModule.forRoot(environment),
];

export const filterActionInNgxsLoggerPlugin = (action: any) => 
  getActionTypeFromInstance(action) !== RouterDataResolved.type
  && getActionTypeFromInstance(action) !== RouterNavigation.type
  && getActionTypeFromInstance(action) !== AdminAppStateAction.SwitchPartnerState.type
  && getActionTypeFromInstance(action) !== AdminAppStateAction.TabState.type
  && getActionTypeFromInstance(action) !== AdminAppStateAction.DropdownState.type
  && getActionTypeFromInstance(action) !== AdminAppStateAction.ManageWebsiteUpdateState.type;
