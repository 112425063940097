import { Directive, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, filter, firstValueFrom, takeUntil, tap } from 'rxjs';

import { AccountState, AccountsAction, SessionState } from '@fitscovery/auth/data-access';
import { SocialMediaAuthEmailDialogEventService } from './social-media-auth-email-dialog-event.service';
import { SnackbarService } from '@fitscovery/ui/snackbar';

@Directive({
  selector: '[authNoEmailObserver]'
})
export class AuthNoEmailObserverDirective implements OnInit {

  subscription$ = new Subject();

  constructor(
    private store: Store,
    private authEmailDialog: SocialMediaAuthEmailDialogEventService,
    private snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.store.select(SessionState.authenticated).pipe(
      filter(Boolean),
      tap(async () => {
        try {
          await firstValueFrom(this.store.dispatch(new AccountsAction.GetAuthDetails));
          if (this.store.selectSnapshot(AccountState.hasNoEmail)) {
            this.snackbar.destroyAll();
            await firstValueFrom(this.authEmailDialog.openDialog());
          }
        } catch (error) { }
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription$.next(null);
  }

}
