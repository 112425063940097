import { Injectable } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AdminAppState, AdminAppStateAction, DrawerState } from '@fitscovery/admin-web/shared/store';

@Injectable()
export class DrawerService {

  @Select(AdminAppState.drawerState) drawerState$: Observable<DrawerState>;

  #opened: boolean;
  #currentScrollbarPosition: [number, number];

  constructor(
    public store: Store,
    private viewPortScroller: ViewportScroller
  ) { }

  get opened(): boolean {
    return this.#opened
  }

  saveScrollPosition(): void {
    this.#opened = true;
    this.#currentScrollbarPosition = this.scrollPosition;
  }
  
  goToLastScrollPosition(): void {
    this.#opened = false;
    this.dispatchCloseDrawer();
    setTimeout(() => this.viewPortScroller.scrollToPosition(this.#currentScrollbarPosition), 10);
  }

  private get scrollPosition(): [number, number] {
    return this.viewPortScroller.getScrollPosition();
  }

  private dispatchCloseDrawer(): void {
    this.store.dispatch(new AdminAppStateAction.DrawerState(
      false, 
      this.store.selectSnapshot(AdminAppState.drawerState).type
    ));
  }

}
