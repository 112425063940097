import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import { ProviderModules } from './modules';
import { AzureLoggerService } from './azure-logger.service';
import { TrackingInterceptor } from './tracking.interceptor';
import { AZ_APP_INSIGHTS } from './common';

@NgModule({
  imports: ProviderModules
})
export class AzAppInsightsLoggerModule {

  static forRoot(instrumentationKey: string, providers: Provider[]): ModuleWithProviders<AzAppInsightsLoggerModule> {

    // Initialize Azure app insights
    const appInsights = new ApplicationInsights({
      version: 1,
      config: {
        instrumentationKey,
        enableDebugExceptions: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        disableInstrumentationKeyValidation: true
      }
    });
    appInsights.loadAppInsights();

    return {
      ngModule: AzAppInsightsLoggerModule,
      providers: [
        ...providers,
        AzureLoggerService,
        { provide: AZ_APP_INSIGHTS, useValue: appInsights },
        { provide: HTTP_INTERCEPTORS, useClass: TrackingInterceptor, multi: true }
      ]
    };
  }

  static forChild(): ModuleWithProviders<AzAppInsightsLoggerModule> {
    return {
      ngModule: AzAppInsightsLoggerModule,
      providers: [
        AzureLoggerService,
        { provide: HTTP_INTERCEPTORS, useClass: TrackingInterceptor, multi: true }
      ]
    };
  }

}
