import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiPartnersService } from '../../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { SubdomainAvailabilityAction, SubdomainAvailabilityStateModel } from '.';
import { CheckSubdomainAvailabilityResponse } from '../../interfaces';
import { errorHandler } from '@fitscovery/common/providers';

export const checkSubdomainStateDefaults: SubdomainAvailabilityStateModel = null;

export const CHECK_SUBDOMAIN_STATE_TOKEN = new StateToken<SubdomainAvailabilityStateModel>('api_partners_check_subdomain');

@State<SubdomainAvailabilityStateModel>({
  name: CHECK_SUBDOMAIN_STATE_TOKEN,
  defaults: checkSubdomainStateDefaults
})

@Injectable()
export class SubdomainAvailabilityState {

  @Selector()
  static available(state: SubdomainAvailabilityStateModel): boolean {
    return state!.available;
  }

  constructor(
    private partner: ApiPartnersService,
    private snackbar: SnackbarService
  ) { }

  @Action(SubdomainAvailabilityAction.CheckSubdomainAvailability)
  createPartner(ctx: StateContext<SubdomainAvailabilityStateModel>, action: SubdomainAvailabilityAction.CheckSubdomainAvailability) {
    return this.partner.checkSubdomainAvailability(action.subdomain).pipe(
      errorHandler(this.snackbar),
      tap((response: CheckSubdomainAvailabilityResponse) => ctx.setState({ available: response.data.available }))
    );
  }

}
