<div class="auth-email">

  <h1 class="auth-email--title">No email</h1>

  <p class="auth-email--description">We did not receive your email from your Facebook sign in. It is required in order to use the site. Please enter enter your email</p>

  <form (ngSubmit)="submit()" [formGroup]="service.form">
    <div class="icon-form-field">
      <div class="icon-form-field__icon icon-wrap">
        <mat-icon>mail</mat-icon>
      </div>
      <mat-form-field class="icon-form-field__field field-custom icon-form" appearance="fill">
        <input matInput placeholder="E-mail address" autocomplete="off" type="text" formControlName="email">
      </mat-form-field>
    </div>
    <div class="auth-email__actions">
      <fitscovery-ui-button 
        ripple buttonStyle="actionButton" 
        elevation="4" type="submit">
        Submit
      </fitscovery-ui-button>
    </div>
  </form>

</div>
