import { InjectionToken } from '@angular/core';

import { Environment } from '@fitscovery/common/environments';
import { App } from '@fitscovery/common/types';

export interface Token {
  app: App;
  environment: Environment
}

export const APP_TOKEN = new InjectionToken<Token>('Segment Logger - App Environments');
