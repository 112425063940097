import { Inject, Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { AngularFireAuth } from '@angular/fire/auth';
import { Action, NgxsOnInit, State, StateContext, StateToken, Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import firebase from 'firebase/app';

import { ApiAuthAction, FirebaseAction, SessionAction } from '../actions';
import { FirebaseStateModel } from '../models';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { APP_TOKEN, Token } from '../environment-injection';
import { SessionState } from './session.state';
import { emailAndPasswordAuth, socialMediaPopUpOrRedirectAuth } from '../utils';

export const FIREBASE_STATE_TOKEN = new StateToken<FirebaseStateModel>('firebase');

@State<FirebaseStateModel>({
	name: FIREBASE_STATE_TOKEN
})

@Injectable()
export class FirebaseState implements NgxsOnInit {

	constructor(
    @Inject(APP_TOKEN) private appToken: Token,
    private platform: Platform,
    private auth: AngularFireAuth,
    private store: Store,
    private snackbar: SnackbarService
  ) { }

	ngxsOnInit(ctx: StateContext<FirebaseStateModel>): void {
    this.auth.authState.subscribe(async (state) => {

      this.appToken.environment.production === "true"
      || console.log('\n\n\n@@@ Firebase (auth state)', state?.toJSON(), '\n\n\n\n');
      
      if (this.store.selectSnapshot(SessionState.authenticated) && !state) {
        try {
          await firstValueFrom(ctx.dispatch(new SessionAction.Authenticate));
        } catch (error) {
          ctx.dispatch(new ApiAuthAction.SignOut);
        }
      }
    });
	}

  @Action(FirebaseAction.SignInWithGoogle)
	async signInWithGoogle(_: StateContext<FirebaseStateModel>) {
    const provider = new firebase.auth.GoogleAuthProvider();
    await socialMediaPopUpOrRedirectAuth(provider, this.auth, this.platform, this.snackbar);
  }

  @Action(FirebaseAction.SignInWithFacebook)
	async signInWithFacebook(_: StateContext<FirebaseStateModel>) {
    const provider = new firebase.auth.FacebookAuthProvider();
    await socialMediaPopUpOrRedirectAuth(provider, this.auth, this.platform, this.snackbar);
  }

  @Action(FirebaseAction.SignInWithPassword)
	async signInWithPassword(_: StateContext<FirebaseStateModel>, action: FirebaseAction.SignInWithPassword) {
    await emailAndPasswordAuth(this.snackbar, async () => {
      await this.auth.signInWithEmailAndPassword(action.email, action.password);
    });
  }

  @Action(FirebaseAction.SignUpWithPassword)
	async signUpWithPassword(_: StateContext<FirebaseStateModel>, action: FirebaseAction.SignUpWithPassword) {
    await emailAndPasswordAuth(this.snackbar, async () => {
      await this.auth.createUserWithEmailAndPassword(action.email, action.password);
    });
  }

  @Action(FirebaseAction.SendPasswordResetEmail)
	async SendPasswordResetEmail(_: StateContext<FirebaseStateModel>, action: FirebaseAction.SendPasswordResetEmail) {
    await this.auth.sendPasswordResetEmail(action.email);
  }

}
