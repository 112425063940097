import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { User, UserState } from '@fitscovery/user/data-access';
import { WebsiteChecklistService } from './website-checklist.service';

@Component({
  selector: 'website-checklist',
  templateUrl: './website-checklist.component.html',
  styleUrls: ['./website-checklist.component.scss']
})
export class WebsiteChecklistComponent implements OnInit {

  @Select(UserState.user) user$: Observable<User>;

  @Output() close = new EventEmitter();

  constructor(
    public service: WebsiteChecklistService
  ) { }

  ngOnInit(): void {
    this.service.getChecklist();
  }

}
