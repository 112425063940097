/**
 * Enum for severity level.
 * Behavior varies depending on the fields used with it.
 * @readonly
 */
export enum SeverityLevel {
  /** Off all logging */
  Off,
  /** severity >= CRITICAL */
  Critical,
  /** severity >= WARNING */
  Warning,
  /** Verbose */
  Verbose
}
