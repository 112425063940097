import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, ActionType, Store } from '@ngxs/store';

import { GetProgramById, GetProgramsByPartnerId, GetProgramByIdWithSubdomain, GetProgramByIdAsAdmin, GetProgramsByPartnerIdWithPaging, AddProgramWorkoutUserInsight, CreateProgram, DeleteProgram, UpdateProgram, SetProgramOrder, GetWorkout, GetWorkoutByIdWithSubdomain, GetMemberOnlyWorkout, GetMemberOnlyWorkoutByIdWithSubdomain, GetWorkoutByIdAsAdmin, GetMemberOnlyWorkoutById, GetWorkoutsBySubdomain, GetWorkoutsBySubdomainWithPaging, AddWorkoutUserInsight, DeleteWorkout, CreateWorkout, UpdateWorkout, UpdateExercise, CreateExercise, DeleteExercise, SetWorkoutOrder } from '@fitscovery/workouts/data-access';
import { AzureLoggerService } from '../azure-logger.service';
import { TrackingService } from '../tracking-service';

@Injectable()
export class WorkoutsTrackingService extends TrackingService {

  constructor(
    store: Store,
    actions$: Actions,
    logger: AzureLoggerService
  ) {

    super(store, actions$, logger);
    
    const actions = [ GetProgramById, GetProgramsByPartnerId, GetProgramByIdWithSubdomain, GetProgramByIdAsAdmin, GetProgramsByPartnerIdWithPaging, AddProgramWorkoutUserInsight, CreateProgram, DeleteProgram, UpdateProgram, SetProgramOrder, GetWorkout, GetWorkoutByIdWithSubdomain, GetMemberOnlyWorkout, GetMemberOnlyWorkoutByIdWithSubdomain, GetWorkoutByIdAsAdmin, GetMemberOnlyWorkoutById, GetWorkoutsBySubdomain, GetWorkoutsBySubdomainWithPaging, AddWorkoutUserInsight, DeleteWorkout, CreateWorkout, UpdateWorkout, UpdateExercise, CreateExercise, DeleteExercise, SetWorkoutOrder ] as ActionType[];

    actions.forEach((action: ActionType) => this.trackDispatch(action));
  }
 
}

@NgModule()
export class WorkoutsProviderModule {
  constructor(
    @Optional() service: WorkoutsTrackingService
  ) { }
}


