<button
  [matMenuTriggerFor]="notificationsMenu"
  style="margin: 0"
  type="button"
  mat-icon-button
  [matBadge]="unseenNotificationIds.length"
  [matBadgeHidden]="unseenNotificationIds.length === 0"
  class="notification_button"
>
  <mat-icon class="material-icons-outlined">notifications</mat-icon>
</button>

<mat-menu class="notification-menu" panelClass="feature-notification" #notificationsMenu="matMenu">
  <div style="padding: 8px 16px; border-bottom: 1px solid gray" fxLayoutAlign="space-between center" (click)="seenAllNotifications($event)">
    <strong>Notifications</strong>
    <button mat-button class="mark_all_as_read_button"  [ngStyle]="{ padding: 0, 'font-size': '.75rem' }">Mark all as read</button>
  </div>

  <div class="notification-item-container">
    <div
      class="notification-item"
      *ngFor="let notification of notifications"
      fxLayout="column"
      style="padding: 8px 16px; border-bottom: 1px solid gray; cursor: pointer"
      [ngClass]="{ 'not-seen': !notification.seenAt }"
      (click)="goToNotification($event, notification)"
    >
      <div fxLayoutAlign="space-between start" style="margin-bottom: 8px">
        <strong [ngStyle]="{ 'overflow-wrap': 'anywhere' }">{{ notification.title }}</strong>
        <span class="unseen_indicator" *ngIf="!notification.seenAt" [ngStyle]="{ padding: '4px', 'font-size': '.75rem' }">*</span>
      </div>
      <span [ngStyle]="{ 'font-size': '.85rem', 'overflow-wrap': 'anywhere' }">{{ notification.message }}</span>
      <div fxLayout="row" fxLayoutAlign="end start" style="margin-top: 8px">
        <small [ngStyle]="{ 'font-size': '.75rem' }">{{ notification.createdAt | toLocalTime | date : 'MMM dd, y, hh:mm a' }}</small>
      </div>
    </div>

    <div *ngIf="gettingNotifications" fxLayoutAlign="center center" [ngStyle]="{ padding: '12px' }">
      <mat-spinner class="notification_loading" diameter="48"></mat-spinner>
    </div>

    <div
      *ngIf="!gettingNotifications && notifications.length === 0"
      fxLayoutAlign="center center"
      [ngStyle]="{ padding: '12px', 'font-size': '.75rem' }"
    >
      <span>No notifications yet.</span>
    </div>

    <button
      class="show_more_button"
      *ngIf="hasMoreNotification"
      style="width: 100%"
      mat-button
      [ngStyle]="{ 'font-size': '.75rem' }"
      (click)="loadMoreNotifications($event)"
    >
    Show More
    </button>
  </div>
</mat-menu>
