import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, ActionType, Store } from '@ngxs/store';

import { MembershipAction, PartnerMembershipAction, SubscriptionAction, EmailTemplateAction, MemberAction, MembershipHistoryAction, PartnerMemberAction, PMSEmailTemplateAction } from '@fitscovery/memberships/data-access';
import { AzureLoggerService } from '../azure-logger.service';
import { TrackingService } from '../tracking-service';

@Injectable()
export class MembershipsTrackingService extends TrackingService {

  constructor(
    store: Store,
    actions$: Actions,
    logger: AzureLoggerService
  ) {

    super(store, actions$, logger);
    
    const actions = [
      // Membership action
      MembershipAction.ImportMembers, MembershipAction.CreateMember, MembershipAction.ArchiveMembership, MembershipAction.UpdateMembership, MembershipAction.StopRecurringMembership, MembershipAction.GetMembersByStatus, MembershipAction.GetMembershipsByUserId, MembershipAction.GetMembershipsWithPartnerLogo, PartnerMembershipAction.GetMembershipsByPartnerIdAndMemberId, PartnerMembershipAction.GetActiveMembershipSubscriptionsByPartnerId, PartnerMembershipAction.ApplyDefaultMembership, PartnerMembershipAction.ApplyMembership,
      // Subscription action
      SubscriptionAction.GetMembershipSubscriptionById, SubscriptionAction.GetMembershipSubscriptionsByPartnerId, SubscriptionAction.GetMembershipSubscriptionsByPartnerIdAndByMemberId, SubscriptionAction.CreateMembershipSubscription, SubscriptionAction.UpdateMembershipSubscription, SubscriptionAction.DeleteMembershipSubscription, SubscriptionAction.CreateMembershipSubscriptionWithPMSEmailTemplate, SubscriptionAction.UpdateMembershipSubscriptionWithPMSEmailTemplate, SubscriptionAction.SetMembershipSubscriptionOrder, SubscriptionAction.HasAccessToWorkoutOrProgram,
      // Email template action
      EmailTemplateAction.GetEmailTemplates,
      // Member action
      MemberAction.GetMemberByPartnerId,
      // Membership history action
      MembershipHistoryAction.GetMembershipWithHistory,
      // Partner member action
      PartnerMemberAction.GetEventActiveMembers,
      // PMS email template action
      PMSEmailTemplateAction
    ] as ActionType[];

    actions.forEach((action: ActionType) => this.trackDispatch(action));
  }
 
}

@NgModule()
export class MembershipsProviderModule {
  constructor(
    @Optional() service: MembershipsTrackingService
  ) { }
}
