import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { PartnerState } from '../partner.state';
import { WebsiteChecklistAction, PartnerChecklistItem, WebsiteChecklistState } from '.';

@Injectable()
export class WebsiteChecklistService {

  constructor(
    private store: Store
  ) { }

  loadWebsiteChecklist(): void {
    const partnerId = this.store.selectSnapshot(PartnerState.partnerId);
    if (!partnerId) return;
    this.store.dispatch(new WebsiteChecklistAction.GetWebsiteChecklist(partnerId));
  }

  refreshWebsiteChecklist(item: PartnerChecklistItem): void {

    const checklist = this.store.selectSnapshot(WebsiteChecklistState.checklist);
    const completed = checklist?.find(e => e.websiteChecklistItem === item)?.completed;
    const partnerId = this.store.selectSnapshot(PartnerState.partnerId);

    if (completed || !partnerId) return;

    this.store.dispatch(new WebsiteChecklistAction.GetWebsiteChecklist(partnerId));
  }

}
