export enum PaymentFor {
  None,
  Events,
  Products,
  Workouts,
  Membership,
  ExtendMembership,
  RenewalMembership,
  Booking,
}
