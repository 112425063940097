import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiPMSEmailTemplateService } from '../../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { PMSEmailTemplateAction, PMSEmailTemplateStateModel } from '.';
import { PMSEmailTemplate } from '../../models';
import { errorHandler } from '@fitscovery/common/providers';

export const pmsEmailTemplateStateDefaults: PMSEmailTemplateStateModel = null;

export const PMS_EMAIL_TEMPLATE_STATE_TOKEN = new StateToken<PMSEmailTemplateStateModel>('api_memberships_pms_email_template');

@State<PMSEmailTemplateStateModel>({
  name: PMS_EMAIL_TEMPLATE_STATE_TOKEN,
  defaults: pmsEmailTemplateStateDefaults
})

@Injectable()
export class PMSEmailTemplateState {

  @Selector()
  static emailTemplates(state: PMSEmailTemplateStateModel): PMSEmailTemplate[] {
    return state!.emailTemplates || [];
  }

  constructor(
    private pmsEmailTemplateService: ApiPMSEmailTemplateService,
    private snackbar: SnackbarService
  ) { }

  @Action(PMSEmailTemplateAction.GetPMSEmailTemplates)
  getPMSEmailTemplates(ctx: StateContext<PMSEmailTemplateStateModel>, action: PMSEmailTemplateAction.GetPMSEmailTemplates) {
    return this.pmsEmailTemplateService.getPMSEmailTemplatesByPartnerId(action.partnerId).pipe(
      errorHandler(this.snackbar),
      tap((response: PMSEmailTemplate[]) => ctx.setState({ emailTemplates: response }))
    );
  }

}
