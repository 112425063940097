import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SubscriptionStore {

  protected subscription$: Subject<void>;

  constructor() {
    this.subscription$ = new Subject<void>();
  }

  protected destroySubscription(): void {
    if (this.subscription$) {
      this.subscription$.next();
      this.subscription$.complete();
    }
  }
  
}
