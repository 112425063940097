import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { RouterState } from '@ngxs/router-plugin';
import { distinctUntilChanged, filter, firstValueFrom, skip, take, tap } from 'rxjs';

import { UserActions, UserState } from '@fitscovery/user/data-access';
import { PartnerAction, PartnerState } from '@fitscovery/partners/data-access';
import { PartnerWebsiteAction, PartnerWebsiteState } from '@fitscovery/partner-websites/data-access';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable()
export class FeatureRootService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private gtm: GoogleTagManagerService
  ) { }

  /**
   * Handling of newly created vendors from the onboarding page
   * @returns void
   */
  handleNewVendorFromOnboarding(): void {
    // Subscribe to route events
    const routerEvents$ = this.router.events.subscribe((event: Event) => {
      // Only allow NavigationStart route event 
      if (!(event instanceof NavigationStart)) return;
      // Only allow url with query parameter of highlight to true 
      if (!event.url.includes('highlight=true')) return;
      // Get subdomain from the url
      const subdomain = event.url.split('p=')[1].split('&highlight=')[0];
      localStorage.setItem('new_vendor', subdomain);
      routerEvents$.unsubscribe();
    });
  }

  /**
   * Retrieves data for partners and their websites by subdomain when navigating,
   * if the data has not yet been stored in the state
   * @returns void
   */
  partnerCheckOnRouteChange(): void {
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd), // Filter only on navigation end
      filter((event: Event) => { // Exclude routes on sign in, (ex. /dashboard and /)
        event = event as NavigationEnd;
        return !event.url.includes('/dashboard') && event.url !== '/'
      }),
      distinctUntilChanged(),
      skip(1), // First dispatch happens in the navbar
      filter(() => { // Check if no user partners or partner or partner websites
        const hasNoUserPartners = this.store.selectSnapshot(UserState.partners)?.length < 1
        const hasNoPartner = !this.store.selectSnapshot(PartnerState.partner) || !this.store.selectSnapshot(PartnerWebsiteState.partnerWebsite);
        return hasNoUserPartners || hasNoPartner;
      }),
      tap(async () => {
        
        // Get current user
        await firstValueFrom(this.store.dispatch(new UserActions.GetCurrentUser));
        // Get user partners by user ID
        await firstValueFrom(this.store.dispatch(new UserActions.GetUserPartnersByUserId(
          this.store.selectSnapshot(UserState.userId)
        )));
        const subdomain = localStorage.getItem('selectedPartner') ||this.store.selectSnapshot(UserState.partners)![0]?.partnerData?.subdomain!
        // Get partner and partner website by subdomain
        await firstValueFrom(this.store.dispatch([
          new PartnerAction.GetPartnerBySubdomain(subdomain),
          new PartnerWebsiteAction.GetPartnerWebsiteBySubdomain(subdomain)
        ]));

        // Update 
        const _route = this.store.selectSnapshot(RouterState.state)?.url.split('?')[0];
        const path = _route === '/' ? [ '/dashboard' ] : [];
        return this.router.navigate(path, {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
          queryParams: { p: subdomain }
        });
      }),
      take(1) // Unsubscribe on completion/fulfillment 
    ).subscribe();
  }

  googleTagManagerInit(): void {
    this.gtm.addGtmToDom()
      .then(() => console.log('GTM Initialized.'))
      .catch(() => console.error('GTM could not start.'));
  }

}
