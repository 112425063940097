import { getActionTypeFromInstance, NGXS_PLUGINS } from "@ngxs/store";

import { ApiAuthAction, authStateDefaults } from "@fitscovery/auth/data-access";
import { userStateDefaults } from "@fitscovery/user/data-access";
import { eventStateDefaults } from "@fitscovery/events/data-access";
import { apiMembershipsStateDefaults, emailTemplateStateDefaults, memberStateDefaults, membershipHistoryStateDefaults, partnerMemberStateDefaults, pmsEmailTemplateStateDefaults } from "@fitscovery/memberships/data-access";
import { bookingsStateDefaults } from "@fitscovery/bookings/data-access";
import { apiReportsStateDefaults } from "@fitscovery/reports/data-access";
import { notificationStateDefaults, webPushNotificationStateDefaults } from "@fitscovery/notification/data-access";
import { workoutStateDefaults, programStateDefaults } from "@fitscovery/workouts/data-access";
import { adminMembershipInvoiceStateDefaults } from "@fitscovery/payments/data-access";
import { appStateDefaults } from "@fitscovery/admin-web/shared/store";
import { bookingsDefaults } from "@fitscovery/admin-web/bookings/shared/store";

function adminWebLogoutPlugin(state: any, action: any, next: any) {

  if (getActionTypeFromInstance(action) === ApiAuthAction.SignOut.type) {
    state = {
      api_auth: authStateDefaults,
      api_user: userStateDefaults,
      api_partners: null,
      api_partner_websites: null,
      api_events: eventStateDefaults,
      api_memberships: apiMembershipsStateDefaults,
      api_memberships_email_template: emailTemplateStateDefaults,
      api_memberships_member: memberStateDefaults,
      api_memberships_membership_with_history: membershipHistoryStateDefaults,
      api_memberships_partner_members: partnerMemberStateDefaults,
      api_memberships_pms_email_template: pmsEmailTemplateStateDefaults,
      api_reports: apiReportsStateDefaults,
      api_notification: notificationStateDefaults,
      api_bookings: bookingsStateDefaults,
      api_admin_membership_invoice: adminMembershipInvoiceStateDefaults,

      web_push_notification: webPushNotificationStateDefaults,
      workout: workoutStateDefaults,
      program: programStateDefaults,

      app_state: appStateDefaults,
      route: state.route,

      domain_bookings: bookingsDefaults
    };
  }

  return next(state, action);
}

export const AdminWebLogoutPluginProvider = {
  provide: NGXS_PLUGINS,
  useValue: adminWebLogoutPlugin,
  multi: true
};
