import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, ActionType, Store } from '@ngxs/store';

import { CreatePost, DeletePost, GetPostById, GetPostsByUserId, GetMemberPostsByPartnerId, GetUserPostsPointsByPartnerId, UpdatePost, GetPostsPointsByUserId, GetPublicPostsByPartnerId } from '@fitscovery/posts/data-access';
import { AzureLoggerService } from '../azure-logger.service';
import { TrackingService } from '../tracking-service';

@Injectable()
export class PostsTrackingService extends TrackingService {

  constructor(
    store: Store,
    actions$: Actions,
    logger: AzureLoggerService
  ) {

    super(store, actions$, logger);
    
    const actions = [ CreatePost, DeletePost, GetPostById, GetPostsByUserId, GetMemberPostsByPartnerId, GetUserPostsPointsByPartnerId, UpdatePost, GetPostsPointsByUserId, GetPublicPostsByPartnerId ] as ActionType[];

    actions.forEach((action: ActionType) => this.trackDispatch(action));
  }
 
}

@NgModule()
export class PostsProviderModule {
  constructor(
    @Optional() service: PostsTrackingService
  ) { }
}
