import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, ActionType, Store } from '@ngxs/store';

import { PartnerAction, AdminPartnersAction, CreatePartnerAction } from '@fitscovery/partners/data-access';
import { AzureLoggerService } from '../azure-logger.service';
import { TrackingService } from '../tracking-service';

@Injectable()
export class PartnersTrackingService extends TrackingService {

  constructor(
    store: Store,
    actions$: Actions,
    logger: AzureLoggerService
  ) {

    super(store, actions$, logger);
    
    const actions = [ PartnerAction.GetPartnerBySubdomain, PartnerAction.UpdatePartnerSettings, PartnerAction.UpdatePartnerContactInfo, PartnerAction.UpdatePartnerOfferings, AdminPartnersAction.GetAdminPartners, CreatePartnerAction.CreatePartner ] as ActionType[];

    actions.forEach((action: ActionType) => this.trackDispatch(action));
  }
 
}

@NgModule()
export class PartnersProviderModule {
  constructor(
    @Optional() service: PartnersTrackingService
  ) { }
}
