import { Component, Input } from '@angular/core';

import { Selection } from '../../../common';

@Component({
  selector: 'selection-header',
  template: `
    <p
      class="website-checklist__selection-header"
      (click)="selection.visibility = !selection.visibility">
      {{ selection.header }}
    </p>
  `,
  styles: [`
    .website-checklist__selection-header {
      font-size: 16px;
      cursor: pointer;
      user-select: text;
      margin-left: 24px;
    }
  `],
})
export class HeaderComponent {

  @Input() selection: Selection;

}
