import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoadingScreenComponent } from './loading-screen.component';

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule
];

const COMPONENTS = [
  LoadingScreenComponent
];

@NgModule({
  imports: [
    ...MODULES
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    LoadingScreenComponent
  ]
})
export class LoadingScreenModule {}
