import { NgModule, Optional, Injectable } from '@angular/core';
import { Actions, ActionType, Store } from '@ngxs/store';

import { AdminMembershipInvoiceActions, AdminRecurringPaymentActions, MyWebInvoiceActions } from '@fitscovery/payments/data-access';
import { AzureLoggerService } from '../azure-logger.service';
import { TrackingService } from '../tracking-service';

@Injectable()
export class PaymentsTrackingService extends TrackingService {

  constructor(
    store: Store,
    actions$: Actions,
    logger: AzureLoggerService
  ) {

    super(store, actions$, logger);
    
    const actions = [ AdminMembershipInvoiceActions.CreateMembershipInvoice, AdminMembershipInvoiceActions.GetMembershipInvoicesCount, AdminMembershipInvoiceActions.GetMembershipInvoices, AdminRecurringPaymentActions.GetRecurringPaymentsCount, AdminRecurringPaymentActions.GetRecurringPayments, MyWebInvoiceActions.GetInvoicesCount, MyWebInvoiceActions.GetInvoices ] as ActionType[];

    actions.forEach((action: ActionType) => this.trackDispatch(action));
  }
 
}

@NgModule()
export class PaymentsProviderModule {
  constructor(
    @Optional() service: PaymentsTrackingService
  ) { }
}




