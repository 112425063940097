import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiAdminPartnersService } from '../../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { PartnerChecklist, WebsiteChecklist, WebsiteChecklistAction, WebsiteChecklistStateModel } from '.';
import { GetWebsiteChecklistResponse, UpdateWebsiteChecklistResponse } from '../../interfaces';
import { errorHandler } from '@fitscovery/common/providers';

export const websiteChecklistStateDefaults: WebsiteChecklistStateModel = null;

export const WEBSITE_CHECKLIST_STATE_TOKEN = new StateToken<WebsiteChecklistStateModel>('api_partners_website_checklist');

@State<WebsiteChecklistStateModel>({
  name: WEBSITE_CHECKLIST_STATE_TOKEN,
  defaults: websiteChecklistStateDefaults
})

@Injectable()
export class WebsiteChecklistState {

  @Selector()
  static state(state: WebsiteChecklistStateModel): WebsiteChecklist {
    return state!;
  }

  @Selector()
  static checklist(state: WebsiteChecklistStateModel): PartnerChecklist[] {
    return state!.partnerWebsiteChecklistItems;
  }

  @Selector()
  static percentage(state: WebsiteChecklistStateModel): number {
    return state!.percentage;
  }

  constructor(
    private partner: ApiAdminPartnersService,
    private snackbar: SnackbarService
  ) { }

  @Action(WebsiteChecklistAction.GetWebsiteChecklist)
  getWebsiteChecklist(ctx: StateContext<WebsiteChecklistStateModel>, action: WebsiteChecklistAction.GetWebsiteChecklist) {
    return this.partner.getWebsiteChecklist(action.partnerId).pipe(
      errorHandler(this.snackbar),
      tap((response: GetWebsiteChecklistResponse) => ctx.setState(response.data))
    );
  }

  @Action(WebsiteChecklistAction.UpdateWebsiteChecklist)
  updateWebsiteChecklist(ctx: StateContext<WebsiteChecklistStateModel>, action: WebsiteChecklistAction.UpdateWebsiteChecklist) {
    return this.partner.updateWebsiteChecklist(action.checklist).pipe(
      errorHandler(this.snackbar),
      tap((response: UpdateWebsiteChecklistResponse) => ctx.setState(response.data))
    );
  }

}
