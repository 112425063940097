import { NgModule, Optional, Injectable, Inject } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Actions, Store } from '@ngxs/store';

import { SegmentAnalytics } from '../segment-analytics';
import { UserActions, UserState } from '@fitscovery/user/data-access';
import { ApiAuthAction, AuthAzureAction, FirebaseAction } from '@fitscovery/auth/data-access';
import { TrackingService } from '../tracking-service';
import { APP_TOKEN, Token } from '../common';
import { ACCOUNT_PAGES, AUTH_METHOD, AUTH_PAGES, USER_AUTH_EVENTS } from '../providers';

@Injectable()
export class AuthSegmentTrackingService extends TrackingService {

  constructor(
    @Inject(APP_TOKEN) appToken: Token,
    actions$: Actions,
    router: Router,
    store: Store,
    logger: SegmentAnalytics
  ) {

    super(actions$);

    router.events.subscribe((event: Event) => {
      if (!(event instanceof NavigationEnd)) return;

      if (event.url.includes('/auth/login')) {
        logger.page(AUTH_PAGES.LOGIN);
      } else if (event.url.includes('/auth/register')) {
        logger.page(AUTH_PAGES.SIGN_UP);
      } else if (event.url.includes('/user/details')) {
        logger.page(ACCOUNT_PAGES.USER_DETAILS);
      }
    });

    this.actionListener(
      UserActions.GetCurrentUser,
      () => logger.identify(store.selectSnapshot(UserState.userId).toString(), {
        email: store.selectSnapshot(UserState.user)?.email, 
        firstName: store.selectSnapshot(UserState.user)?.firstName,
        lastName: store.selectSnapshot(UserState.user)?.lastName
      })
    );

    this.actionListener(
      AuthAzureAction.SignUp,
      () => logger.trackEvent(USER_AUTH_EVENTS.SIGN_UP, { method: AUTH_METHOD.EMAIL_PASSWORD})
    );

    this.actionListener(
      FirebaseAction.SignInWithGoogle,
      () => logger.trackEvent(USER_AUTH_EVENTS.LOGIN, { method: AUTH_METHOD.GOOGLE })
    );

    this.actionListener(
      FirebaseAction.SignInWithFacebook,
      () => logger.trackEvent(USER_AUTH_EVENTS.LOGIN, { method: AUTH_METHOD.FACEBOOK })
    );

    this.actionListener(
      FirebaseAction.SignInWithPassword,
      () => logger.trackEvent(USER_AUTH_EVENTS.LOGIN, { method: AUTH_METHOD.EMAIL_PASSWORD })
    );

    this.actionListener(
      FirebaseAction.SignUpWithPassword,
      () => logger.trackEvent(USER_AUTH_EVENTS.SIGN_UP, { method: AUTH_METHOD.EMAIL_PASSWORD})
    );

    this.actionListener(
      FirebaseAction.SendPasswordResetEmail,
      () => logger.trackEvent(USER_AUTH_EVENTS.FORGOT_PASSWORD)
    );

    this.actionListener(
      ApiAuthAction.SignOut,
      () => logger.trackEvent(USER_AUTH_EVENTS.LOGOUT, { 'logout_site': appToken.app })
    );
  }

}

@NgModule()
export class AuthSegmentProviderModule {
  constructor(
    @Optional() service: AuthSegmentTrackingService
  ) { }
}
