import { Metadata, EventData } from '../interfaces';
import { ApplicationProvider } from './application.provider';

export class EmbedWebProvider extends ApplicationProvider {

  constructor(eventData: EventData) {
    super(eventData);
  }

  get routeMetadata(): Metadata {
    return {
      ... this.customMetadata,
      ...this.defaultMetadataConfig
    }
  }

  private get customMetadata(): Metadata | undefined {
    switch (this.path) {
      case 'auth/login': return {
        title: 'Login or sign up | Fitscovery',
        description: '',
      }
      case 'auth/forgot-password': return {
        title: 'Forgot password | Fitscovery',
        description: '',
      }
      case 'auth/register': return {
        title: 'Create Account | Fitscovery',
        description: '',
      }
      case 'user/details/profile': return {
        title: 'Update Account Profile | Fitscovery',
        description: '',
      }
      case 'user/details/basic-info': return {
        title: 'Update Basic Information | Fitscovery',
        description: '',
      }
      case 'user/details/contact-info': return {
        title: 'Update Contact Information | Fitscovery',
        description: '',
      }
      case 'user/details': return {
        title: 'Your Account | Fitscovery',
        description: '',
      }
      default: return {
        title: 'Embed | Fitscovery',
        description: '',
      };
    }
  }

}
