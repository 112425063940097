import { App } from '@fitscovery/common/types';
import { Environment, EnvironmentKeys } from './environment.interface';
import * as prod from './environment.prod';

export function keys(app: App): EnvironmentKeys {
  return {
    get environment(): Environment {
      return {
        ...prod.keys(app).environment,
        azureFunctionsApi: {
          auth: 'https://api-auth-fitscovery-eus.azurewebsites.net/api',
          events: 'https://api-events-fitscovery-eus.azurewebsites.net/api',
          memberships: 'https://functions-memberships-fitscovery-eus.azurewebsites.net/api',
          reports: 'https://prod-functions-reports-fitscovery-eus.azurewebsites.net/api',
          partners: 'https://api-partners-fitscovery-eus.azurewebsites.net/api',
          users: 'https://api-users-fitscovery-eus.azurewebsites.net/api',
          workouts: 'https://api-workouts-fitscovery-eus.azurewebsites.net/api',
          products: 'https://functions-products-fitscovery-eus.azurewebsites.net/api',
          payments: 'https://functions-payments-fitscovery-eus.azurewebsites.net/api',
          posts: 'https://functions-posts-fitscovery-eus.azurewebsites.net/api',
          bookings: 'https://functions-bookings-fitscovery-eus.azurewebsites.net/api',
          notifications: 'https://functions-notifications-fitscovery-node-eus.azurewebsites.net/api',
        },
        nodeApi: {
          auth: 'https://api-auth-eus.fitscovery.com/api/v1',
          users: 'https://api-users-eus.fitscovery.com/api',
          payments: 'https://api-payments-eus.fitscovery.com/api/v1',
          partners: 'https://prod-partners-api-fitscovery-eus.azurewebsites.net/api/v1',
          partnerWebsites: 'https://prod-partner-websites-api-fitscovery-eus.azurewebsites.net/api/v1'
        }
      }
    }
  }
};
