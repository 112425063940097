import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { removeItem, patch } from '@ngxs/store/operators';
import { of } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';

import { ApiEventService, ApiEventPartnerService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';

import { EventsStateModel, Event, EventsResponse, EventAttendeeResponse, EventAttendee, EventDownloadEventAttendeesResponse, UpdateEventAttendeeResponse } from '../models';
import { EventsActions } from '../actions';
import { errorHandler } from '@fitscovery/common/providers';

export const eventStateDefaults: EventsStateModel = {
	events: null,
  selectedEvent: null,
	attendees: null,
  bookingResponse: null
};

export const API_EVENTS_STATE_TOKEN = new StateToken<EventsStateModel>('api_events');

@State<EventsStateModel>({
	name: API_EVENTS_STATE_TOKEN,
	defaults: eventStateDefaults
})

@Injectable()
export class EventsState implements NgxsOnInit {

	@Selector()
	static events(state: EventsStateModel): Event[] {
		return state.events!;
	}

	@Selector()
	static selectedEvent(state: EventsStateModel): Event {
		return state.selectedEvent!;
	}

	@Selector()
	static attendees(state: EventsStateModel): EventAttendee[] {
		return state.attendees!;
	}

	@Selector()
	static bookingResponse(state: EventsStateModel): EventAttendeeResponse {
		return state.bookingResponse!;
	}

	constructor(
    @Inject(WINDOW) private window: any,
    private eventService: ApiEventService,
    private eventPartnerService: ApiEventPartnerService,
    private snackbar: SnackbarService
  ) { }

	ngxsOnInit(): void {
	}

  @Action(EventsActions.GetEventById)
	getEventById(ctx: StateContext<EventsStateModel>, action: EventsActions.GetEventById) {
    return this.eventService.getEventById(action.eventId).pipe(
      errorHandler(this.snackbar),
      tap((response: EventsResponse) => ctx.patchState({ selectedEvent: response.data[0] }))
    );
	}

  @Action(EventsActions.GetEventByIdWithPartnerId)
	getEventByIdWithPartnerId(ctx: StateContext<EventsStateModel>, action: EventsActions.GetEventByIdWithPartnerId) {
    return this.eventService.getEventByIdWithPartnerId(action.eventId, action.partnerId).pipe(
      errorHandler(this.snackbar),
      tap((response: EventsResponse) => ctx.patchState({ selectedEvent: response.data[0] }))
    );
	}

  @Action(EventsActions.GetEventByIdAndSubdomain)
	getEventByIdAndSubdomain(ctx: StateContext<EventsStateModel>, action: EventsActions.GetEventByIdAndSubdomain) {
    return this.eventService.getEventByIdAndSubdomain(action.eventId, action.subdomain).pipe(
      errorHandler(this.snackbar),
      tap((response: EventsResponse) => ctx.patchState({ selectedEvent: response.data[0] }))
    );
	}

  @Action(EventsActions.GetEventsBySubdomain)
	getEventsBySubdomain(ctx: StateContext<EventsStateModel>, action: EventsActions.GetEventsBySubdomain) {
    return this.eventPartnerService.getEventsBySubdomain(action.subdomain).pipe(
      errorHandler(this.snackbar),
      tap((response: EventsResponse) => ctx.patchState({ events: response.data }))
    );
	}

  @Action(EventsActions.CreateEvent)
	createEvent(_: StateContext<EventsStateModel>, action: EventsActions.CreateEvent) {
    return this.eventService.createEvent(action.event).pipe(
      errorHandler(this.snackbar)
    );
  }

  @Action(EventsActions.UpdateEvent)
	updateEvent(_: StateContext<EventsStateModel>, action: EventsActions.UpdateEvent) {
    return this.eventService.updateEvent(action.event).pipe(
      errorHandler(this.snackbar)
    );
	}

  @Action(EventsActions.DuplicateEvent)
	duplicateEvent(_: StateContext<EventsStateModel>, action: EventsActions.UpdateEvent) {
    return this.eventService.duplicateEvent(action.event).pipe(
      errorHandler(this.snackbar)
    );
	}

  @Action(EventsActions.CancelEvent)
	cancelEvent(_: StateContext<EventsStateModel>, action: EventsActions.CancelEvent) {
    return this.eventService.cancelEvent(action.eventId, action.partnerId).pipe(
      errorHandler(this.snackbar)
    );
	}

  @Action(EventsActions.DeleteEvent)
	deleteEvent(ctx: StateContext<EventsStateModel>, action: EventsActions.DeleteEvent) {
    return this.eventService.deleteEvent(action.eventId, action.partnerId).pipe(
      errorHandler(this.snackbar),
      tap(() => ctx.setState(patch<any>({
        events: removeItem<any>((event) => event!.eventId! === action.eventId)
      })))
    );
	}
  
  @Action(EventsActions.GetAttendeesWithIdByEventId)
	getAttendeesWithIdByEventId(ctx: StateContext<EventsStateModel>, action: EventsActions.GetAttendeesWithIdByEventId) {
    return this.eventService.getAttendeesWithIdByEventId(action.eventId).pipe(
      errorHandler(this.snackbar),
      tap((response: EventAttendeeResponse) => ctx.patchState({ attendees: response.data }))
    );
	}

  @Action(EventsActions.AddEventAttendees)
	addEventAttendees(_: StateContext<EventsStateModel>, action: EventsActions.AddEventAttendees) {
    return this.eventService.addEventAttendees(action.eventId, action.userList).pipe(
      errorHandler(this.snackbar)
    );
	}

  @Action(EventsActions.UpdateEventAttendee)
	updateEventAttendee(ctx: StateContext<EventsStateModel>, { userId, partnerId, eventId, isPresent }: EventsActions.UpdateEventAttendee) {
    return this.eventPartnerService.updateEventAttendee(userId, partnerId, eventId, isPresent).pipe(
      errorHandler(this.snackbar),
      tap(() => ctx.dispatch(new EventsActions.GetAttendeesWithIdByEventId(eventId))),
      delay(500),
      tap((response: UpdateEventAttendeeResponse) => {
        const isPresent = response.request.isPresent;
        const message = isPresent ? 'Successfully confirmed attendee!' : 'Successfully cancelled attendee!';
        this.snackbar.openSnack({ message });
      })
    );
	}
  
  @Action(EventsActions.UpdateAllEventAttendee)
	updateAllEventAttendee(ctx: StateContext<EventsStateModel>, { partnerId, eventId, isPresent }: EventsActions.UpdateAllEventAttendee) {
    return this.eventPartnerService.updateAllEventAttendee(partnerId, eventId, isPresent).pipe(
      errorHandler(this.snackbar),
      tap(() => ctx.dispatch(new EventsActions.GetAttendeesWithIdByEventId(eventId))),
      delay(500),
      tap((response: UpdateEventAttendeeResponse) => {
        const isPresent = response.request.isPresent;
        const message = isPresent ? 'Successfully confirmed all attendees!' : 'Successfully cancelled all attendees!';
        this.snackbar.openSnack({ message });
      })
    );
	}

  @Action(EventsActions.DownloadCsvEventAttendees)
	downloadCsvEventAttendees(ctx: StateContext<EventsStateModel>, action: EventsActions.DownloadCsvEventAttendees) {
    return this.eventPartnerService.downloadCsvEventAttendees(action.eventId, action.partnerId).pipe(
      errorHandler(this.snackbar),
      tap((response: EventDownloadEventAttendeesResponse) => {
        const downloadUrl = response.data.downloadUrl;
        this.window.open(downloadUrl);
      })
    );
	}

  @Action(EventsActions.SoftReserve)
  softReserve(_: StateContext<EventsStateModel>, action: EventsActions.SoftReserve) {
    return this.eventService.softReserve(action.eventId, action.quantity).pipe(
      errorHandler(this.snackbar)
    );
  }

  @Action(EventsActions.BookNow)
  bookNow(ctx: StateContext<EventsStateModel>, action: EventsActions.BookNow) {
    return this.eventService.bookNow(action.eventId, action.quantity).pipe(
      catchError((response: HttpErrorResponse) => of(response.error)),
      tap((bookingResponse: EventAttendeeResponse) => ctx.patchState({ bookingResponse }))
    );
  }

  @Action(EventsActions.CancelFreeEventSlot)
  cancelFreeEventSlot(ctx: StateContext<EventsStateModel>, action: EventsActions.CancelFreeEventSlot) {
    return this.eventService.cancelFreeEventSlot(action.eventId).pipe(
      catchError((response: HttpErrorResponse) => of(response.error)),
      tap((bookingResponse: EventAttendeeResponse) => ctx.patchState({ bookingResponse }))
    );
  }

  @Action(EventsActions.CancelEventSlot)
  cancelEventSlot(ctx: StateContext<EventsStateModel>, action: EventsActions.CancelEventSlot) {
    return this.eventService.cancelEventSlot(action.eventId).pipe(
      catchError((response: HttpErrorResponse) => of(response.error)),
      tap((bookingResponse: EventAttendeeResponse) => ctx.patchState({ bookingResponse }))
    );
  }

  @Action(EventsActions.RemoveSelectedEvent)
	removeSelectedEvent(ctx: StateContext<EventsStateModel>) {
    ctx.patchState({ selectedEvent: null });
	}

  @Action(EventsActions.RemoveEventAttendees)
	removeSelectedEventAttendees(ctx: StateContext<EventsStateModel>) {
    ctx.patchState({ attendees: null });
	}
  
  @Action(EventsActions.GetLatestAttendeesByEventId)
	getLatestAttendeesByEventId(ctx: StateContext<EventsStateModel>, action: EventsActions.GetLatestAttendeesByEventId) {
    return this.eventService.getLatestAttendeesByEventId(action.eventId).pipe(
      errorHandler(this.snackbar),
      tap((response: EventAttendeeResponse) => ctx.patchState({ attendees: response.data }))
    );
	}

}
