import { Guid } from '@fitscovery/common/types';

export namespace MembershipHistoryAction {

  export class GetMembershipWithHistory {
    static readonly type = '[Memberships API] GetMembershipWithHistory';
    constructor(public partnerId: Guid, public memberId: Guid) { }
  }

}
