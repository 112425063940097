export * from './api-partners.service';
export * from './api-admin-partners.service';
export * from './api-classifications.service';

import { ApiPartnersService } from './api-partners.service';
import { ApiAdminPartnersService } from './api-admin-partners.service';
import { ApiClassificationsService } from './api-classifications.service';
// To do: move this to other barrel file
import { WebsiteChecklistService } from '../store/website-checklist/website-checklist.service';

export const ApiServices = [
  ApiPartnersService,
  ApiAdminPartnersService,
  ApiClassificationsService,
  WebsiteChecklistService
];
