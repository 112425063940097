import { Guid } from "@fitscovery/common/types";
import { UpdateWebsiteChecklistRequest } from "../../interfaces";

export namespace WebsiteChecklistAction {

  export class GetWebsiteChecklist {
    static readonly type = '[Partners API] GetWebsiteChecklist';
    constructor(public partnerId: Guid) { }
  }

  export class UpdateWebsiteChecklist {
    static readonly type = '[Partners API] UpdateWebsiteChecklist';
    constructor(public checklist: UpdateWebsiteChecklistRequest) { }
  }

}
