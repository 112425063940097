import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'completionPercentage'
})
export class CompletionPercentagePipe implements PipeTransform {

  transform(percentage$: Observable<number>): Observable<string> {
    return percentage$.pipe(
      map((percent: number) => percent ? `${percent}%` : '0%')
    );
  }

}
