import { NgModule } from '@angular/core';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import {
  STATES,
  STORED_STATES,
  STATE_MODULES_IMPORT,
  filterActionInNgxsLoggerPlugin
} from './app-store.provider';

import { environment } from '../environments/environment';

@NgModule({
  imports: [
    NgxsModule.forRoot(STATES, {
      compatibility: {
        strictContentSecurityPolicy: true
      },
      executionStrategy: NoopNgxsExecutionStrategy,
      developmentMode: environment.production !== "true"
    }),
    NgxsStoragePluginModule.forRoot({
      key: STORED_STATES,
      storage: StorageOption.LocalStorage
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.local,
      filter: filterActionInNgxsLoggerPlugin
    }),
    ...STATE_MODULES_IMPORT
  ]
})
export class AppStoreModule { }
