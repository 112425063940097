export namespace FirebaseAction {

  export class SignUpWithPassword {
    static readonly type = '[Auth API] SignUpWithPassword';
    constructor(public email: string, public password: string) { }
  }

  export class SignInWithGoogle {
    static readonly type = '[Auth API] SignInWithGoogle';
    constructor() { }
  }

  export class SignInWithPassword {
    static readonly type = '[Auth API] SignInWithPassword';
    constructor(public email: string, public password: string) { }
  }

  export class SignInWithFacebook {
    static readonly type = '[Auth API] SignInWithFacebook';
    constructor() { }
  }

  export class SendPasswordResetEmail {
    static readonly type = '[Auth API] SendPasswordResetEmail';
    constructor(public email: string) { }
  }

}
