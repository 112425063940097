import { Injectable } from '@angular/core';
import { errorHandler } from '@fitscovery/common/providers';
import { FitscoveryCRUDOperationError, FitscoveryError } from '@fitscovery/common/types';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { CreatePost, DeletePost, GetPostById, GetPostsByUserId, UpdatePost, GetMemberPostsByPartnerId, GetUserPostsPointsByPartnerId, GetPublicPostsByPartnerId } from '../actions';
import { Post, GetUserPostsPointsByPartnerIdResponse } from '../models';
import { ApiPostService } from '../services';

export interface PostStateModel {
  isLoaded: boolean;
  isProcessing: boolean;
  posts: Post[];
  selectedPost: Post;
  userPostsPoints: GetUserPostsPointsByPartnerIdResponse | null;
}

export const postDefaultStateValues: PostStateModel = {
  isLoaded: false,
  isProcessing: false,
  posts: [],
  selectedPost: {} as Post,
  userPostsPoints: null
};

@State<PostStateModel>({
  name: 'posts',
  defaults: postDefaultStateValues
})
@Injectable()
export class PostState {

  constructor(
    private postService: ApiPostService,
    private snackbar: SnackbarService
    ) {
  }

  @Selector()
  static posts(state: PostStateModel): Post[] {
    return state.posts!;
  }

  @Selector()
  static selectedPost(state: PostStateModel): Post {
    return state.selectedPost!;
  }

  @Selector()
  static isLoaded(state: PostStateModel): boolean {
    return state.isLoaded;
  }

  @Selector()
  static isProcessing(state: PostStateModel): boolean{
    return state.isProcessing;
  }

  @Action(CreatePost)
  createPost(ctx: StateContext<PostStateModel>, action: CreatePost) {
    this.switchIsProcessingTo(true, ctx);
    return this.postService.create(action.post).pipe(
      tap((test)=> {
        this.switchIsProcessingTo(false, ctx);

      }),
      errorHandler(this.snackbar)
    );
  }

  @Action(DeletePost)
  async deletePost(ctx: StateContext<PostStateModel>, action: DeletePost): Promise<void> {
    const state: PostStateModel = ctx.getState();
    this.switchIsProcessingTo(true, ctx);

    if (state.posts?.length === 0) {
      throw new FitscoveryError({
        message: 'Cannot proceed with delete opertaion. No posts found.',
        code: FitscoveryCRUDOperationError.EmptyArrayError
      });
    }
    try {
      await this.postService.delete(action.postId).toPromise();
      state.posts = state.posts?.filter((post: Post) => post.id !== action.postId)!;
      ctx.patchState({
        posts: state.posts
      });
      this.switchIsProcessingTo(false, ctx);
    } catch (error) {
      this.switchIsProcessingTo(false, ctx);
    }
  }

  @Action(GetPostById)
  async getPostById(ctx: StateContext<PostStateModel>, action: GetPostById): Promise<void> {
    const state: PostStateModel = ctx.getState();
    const post: Post = state.posts?.find((post: Post) => post.id === action.postId) as any;

    if (post) {
      ctx.patchState({
        selectedPost: post
      });
    } else {
      throw new FitscoveryError({
        message: 'Post id to select does not exist.',
        code: FitscoveryCRUDOperationError.ObjectNotFound
      });
    }
  }

  @Action(GetPostsByUserId)
  async getPostsByUserId(ctx: StateContext<PostStateModel>, action: GetPostsByUserId): Promise<void> {
    this.switchIsProcessingTo(true, ctx);

    try {
      const posts = await this.postService.getPostsByUserId(action.userId, action.pageNumber, action.pageSize).toPromise() as any;
      ctx.patchState({
        isLoaded: true,
        isProcessing: false,
        posts: posts.data
      });
    } catch (error) {
      this.switchIsProcessingTo(false, ctx);
    }
  }

  @Action(GetMemberPostsByPartnerId)
  async getPostsByPartnerId(ctx: StateContext<PostStateModel>, action: GetMemberPostsByPartnerId): Promise<void> {
    const state: PostStateModel = ctx.getState();
    this.switchIsProcessingTo(true, ctx);

    try {
      const posts: Post[] = await this.postService.getMemberPostsByPartnerId(action.partnerId, action.pageNumber, action.pageSize).toPromise() as any;

      ctx.patchState({
        // ...state,
        isLoaded: true,
        isProcessing: false,
        posts: [...state.posts!, ...posts]
      });
    } catch (error) {
      this.switchIsProcessingTo(false, ctx);
    }
  }

  @Action(GetPublicPostsByPartnerId)
  async getPublicPostsByPartnerId(ctx: StateContext<PostStateModel>, action: GetPublicPostsByPartnerId): Promise<void> {
    const state: PostStateModel = ctx.getState();
    this.switchIsProcessingTo(true, ctx);

    try {
      const posts: Post[] = await this.postService.getPublicPostsByPartnerId(action.partnerId, action.pageNumber, action.pageSize).toPromise() as any;

      ctx.patchState({
        // ...state,
        isLoaded: true,
        isProcessing: false,
        posts: [...state.posts!, ...posts]
      });
    } catch (error) {
      this.switchIsProcessingTo(false, ctx);
    }
  }

  @Action(UpdatePost)
  async updatePost(ctx: StateContext<PostStateModel>, action: UpdatePost): Promise<void> {
    const state: PostStateModel = ctx.getState();
    this.switchIsProcessingTo(true, ctx);

    try {
      await this.postService.update(action.post).toPromise();
      const index: number = state.posts?.findIndex((post: Post) => post.id === action.post.id)!;
      if (state.posts) {
        state.posts[index] = action.post;
      }

      ctx.patchState({
        isProcessing: false,
        posts: state.posts,
      })
    } catch (error) {
      this.switchIsProcessingTo(false, ctx);
    }
  }

  private switchIsProcessingTo(isProcessing: boolean, ctx: StateContext<PostStateModel>): void {
    ctx.patchState({
      isProcessing: isProcessing
    });
  }
}
