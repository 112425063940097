import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GetPartnerPayoutDetailsResponse, PartnerPayoutRequest, UpdatePartnerPayoutDetailsResponse } from '../interfaces';
import { Guid } from '@fitscovery/common/types';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiAdminPartnersService {
    
  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getPartnerPayoutDetails(partnerId: Guid): Observable<GetPartnerPayoutDetailsResponse> {
    return this.http.get<GetPartnerPayoutDetailsResponse>(`${this.env.nodeApi.payments}/admin/partners/${partnerId}/payout-details`);
  }

  updatePartnerPayoutDetails(partnerId: Guid, partnerPayout: PartnerPayoutRequest): Observable<UpdatePartnerPayoutDetailsResponse> {
    return this.http.put<UpdatePartnerPayoutDetailsResponse>(`${this.env.nodeApi.payments}/admin/partners/${partnerId}/payout-details`, partnerPayout);
  }

}
