import { Component } from '@angular/core';

@Component({
  selector: 'website-checklist-brand-image',
  template: `
    <div class="website-checklist__brand-image">
      <img
        src="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/fitscovery-black.png" alt="fitscovery-logo"
        routerLink="/" queryParamsHandling="merge">
    </div>
  `,
  styleUrls: ['./brand-image.component.scss'],
})
export class BrandImageComponent {}
