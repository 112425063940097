export namespace MyWebInvoiceActions {
  export class GetInvoicesCount {
    static readonly type = '[MyWebInvoiceActions] GetInvoicesCount';
    constructor() {}
  }

  export class GetInvoices {
    static readonly type = '[MyWebInvoiceActions] GetInvoices';
    constructor(public readonly loadMore?: boolean) {}
  }
}
