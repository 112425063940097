import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { AdminAppStateAction } from './store.action';
import { DrawerState, StateModel } from './store.model';

export const appStateDefaults: StateModel = {
  dropdownState: false,
  switchPartnerState: false,
  tabState: false,
  drawerState: { state: false, type: 'sidenav' },
  manageWebsiteUpdateState: false,
  websiteChecklistState: false
};

export const APP_STATE_TOKEN = new StateToken<StateModel>('app_state');

@State<StateModel>({
  name: APP_STATE_TOKEN,
  defaults: appStateDefaults
})

@Injectable()
export class AdminAppState implements NgxsOnInit {

  @Selector()
  static dropdownState(state: StateModel): boolean {
    return state.dropdownState;
  }

  @Selector()
  static switchPartnerState(state: StateModel): boolean {
    return state.switchPartnerState;
  }

  @Selector()
  static tabState(state: StateModel): boolean {
    return state.tabState;
  }

  @Selector()
  static drawerState(state: StateModel): DrawerState {
    return state.drawerState;
  }

  @Selector()
  static manageWebsiteUpdateState(state: StateModel): boolean {
    return state.manageWebsiteUpdateState;
  }

  @Selector()
  static websiteChecklistState(state: StateModel): boolean {
    return state.websiteChecklistState;
  }

  constructor() { }

  ngxsOnInit(): void { }

  @Action(AdminAppStateAction.DropdownState)
  dropdownState(ctx: StateContext<StateModel>, action: AdminAppStateAction.DropdownState): void {
    ctx.patchState({ dropdownState: action.state });
  }

  @Action(AdminAppStateAction.SwitchPartnerState)
  switchPartnerState(ctx: StateContext<StateModel>, action: AdminAppStateAction.SwitchPartnerState): void {
    ctx.patchState({ switchPartnerState: action.state });
  }

  @Action(AdminAppStateAction.TabState)
  tabState(ctx: StateContext<StateModel>, action: AdminAppStateAction.TabState): void {
    ctx.patchState({ tabState: action.state });
  }

  @Action(AdminAppStateAction.DrawerState)
  drawerState(ctx: StateContext<StateModel>, action: AdminAppStateAction.DrawerState): void {
    ctx.patchState({ drawerState: { state: action.state, type: action.type } });
  }

  @Action(AdminAppStateAction.ManageWebsiteUpdateState)
  manageWebsiteUpdateState(ctx: StateContext<StateModel>, action: AdminAppStateAction.ManageWebsiteUpdateState): void {
    ctx.patchState({ manageWebsiteUpdateState: action.state });
  }

  @Action(AdminAppStateAction.WebsiteChecklistState)
  websiteChecklistState(ctx: StateContext<StateModel>, action: AdminAppStateAction.WebsiteChecklistState): void {
    ctx.patchState({ websiteChecklistState: action.state });
  }

}
