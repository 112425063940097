import { Component, Input, OnInit } from '@angular/core';

import { SidenavService } from './sidenav.service';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  providers: [ SidenavService ]
})
export class SidenavComponent implements OnInit {

  @Input() hasLogo: boolean

  constructor(
    public service: SidenavService
  ) { }

  ngOnInit(): void {
  }

}
