import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetRecurringPaymentsByAdminRequest,
  GetRecurringPaymentsByAdminResponse,
  GetRecurringPaymentsCountByAdminRequest,
  GetRecurringPaymentsCountByAdminResponse,
} from '../dtos';
import { APP_ENVIRONMENT } from '../environment-injection';
import { RecurringPayment } from '../models';

@Injectable()
export class ApiAdminRecurringPaymentService {

  constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

  getRecurringPaymentsCountByAdmin(requestQuery: GetRecurringPaymentsCountByAdminRequest): Observable<number> {
    const query = qs.stringify(requestQuery);
    return this.http.get(`${this.env.nodeApi.payments}/admin/recurring-payments/count${query ? `?${query}` : ''}`).pipe(
      map((result: Object) => {
        return (result as GetRecurringPaymentsCountByAdminResponse).data.count
      })
    )
  }

  getRecurringPaymentsByAdmin(requestQuery: GetRecurringPaymentsByAdminRequest): Observable<RecurringPayment[]> {
    const query = qs.stringify(requestQuery);
    return this.http.get(`${this.env.nodeApi.payments}/admin/recurring-payments${query ? `?${query}` : ''}`).pipe(
      map((result: Object) => {
        return (result as GetRecurringPaymentsByAdminResponse).data.recurringPayments
      })
    )
  }
}
