import { App } from '@fitscovery/common/types';
import { Environment, EnvironmentKeys } from './environment.interface';
import { values_prod } from './app-environment';

export function keys(app: App): EnvironmentKeys {
  return {
    get environment(): Environment {
      return {
        local: false,
        production: "true",
        fitscoveryApps: {
          accountUrl: 'https://account.fitscovery.com',
          onboardingUrl: 'https://onboarding.fitscovery.com',
          adminUrl: 'https://admin.fitscovery.com',
          myDiscoveryUrl: 'https://my.fitscovery.com',
          backOfficeUrl: 'https://backoffice.fitscovery.com/',
          embedUrl: 'https://embed.fitscovery.com'
        },
        apiKeys: {
          appInsights: values_prod.appInsights[app],
          segmentKey: values_prod.segmentKey[app],
          pushKey: values_prod.pushKey[app],
          googleTagManager: {
            id: 'GTM-WFXP33S',
            authKey: 'uxAGgUX4Xfha04QB1ngIcA',
            preview: 'env-1'
          },
          googleMaps: 'AIzaSyCG6h0oTrQpMGJg-GvllPAPiFhxIbiiqQk',
          recaptchaKey: '6LcM8sYUAAAAAPqG3fKDggiImWc0ZwTgxFEv6uFt',
          facebookAppId: '457939397980694',
          facebookClientToken: '7690929d4a88a7a5ceef63425698da88'
        },
        azureFunctionsApi: {
          auth: 'https://api-auth-fitscovery.azurewebsites.net/api',
          events: 'https://api-events-fitscovery.azurewebsites.net/api',
          memberships: 'https://functions-memberships-fitscovery.azurewebsites.net/api',
          reports: 'https://prod-functions-reports-fitscovery-sea.azurewebsites.net/api',
          partners: 'https://api-partners-fitscovery.azurewebsites.net/api',
          users: 'https://api-users-fitscovery.azurewebsites.net/api',
          workouts: 'https://api-workouts-fitscovery.azurewebsites.net/api',
          products: 'https://functions-products-fitscovery.azurewebsites.net/api',
          payments: 'https://functions-payments-fitscovery.azurewebsites.net/api',
          posts: 'https://functions-posts-fitscovery.azurewebsites.net/api',
          bookings: 'https://functions-bookings-fitscovery.azurewebsites.net/api',
          notifications: 'https://functions-notifications-fitscovery-node.azurewebsites.net/api',
        },
        azureFunctions: {
          images: {
            uploadUrl: 'https://prodfitscoverypartnerimg.blob.core.windows.net/for-image-compression',
            api: 'https://functions-images-fitscovery-node-sea.azurewebsites.net/api',
            functionsKey: 'zaagBiVUlVN2amCryUMZZEIEp/LfwmQggbWiqToNIs/izvzO0GQkzw=='
          },
        },
        paymentsApi: {
          paymongo: 'https://api.paymongo.com/v1',
          paymongoKey: 'pk_live_sXQgPyorWJAvB9yDGJQcm4ev',
        },
        firebase: {
          apiKey: 'AIzaSyCznfqyRFwLsEE_mAT7Ax7hscxb601nm14',
          authDomain: 'beta-fitscovery.firebaseapp.com',
          databaseURL: 'https://beta-fitscovery.firebaseio.com',
          projectId: 'beta-fitscovery',
          storageBucket: 'beta-fitscovery.appspot.com',
          messagingSenderId: '1097482133217',
          appId: '1:1097482133217:web:f8389a049ebf255d4621a0',
          measurementId: 'G-ZD9VEB43PL',
        },
        nodeApi: {
          auth: 'https://api-auth-sea.fitscovery.com/api/v1',
          users: 'https://api-users-sea.fitscovery.com/api',
          payments: 'https://api-payments.fitscovery.com/api/v1',
          partners: 'https://api-partners.fitscovery.com/api/v1',
          partnerWebsites: 'https://api-partner-websites.fitscovery.com/api/v1'
        },
        version: '0.0.0'
      }
    }
  }
};
