import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiMemberService } from '../../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { MemberAction, MemberStateModel } from '.';
import { MemberAndSubscriptionsResponse, MemberAndSubscriptions } from '../../models';
import { errorHandler } from '@fitscovery/common/providers';

export const memberStateDefaults: MemberStateModel = null;

export const MEMBER_STATE_TOKEN = new StateToken<MemberStateModel>('api_memberships_member');

@State<MemberStateModel>({
  name: MEMBER_STATE_TOKEN,
  defaults: memberStateDefaults
})

@Injectable()
export class MemberState {

  @Selector()
  static member(state: MemberStateModel): MemberAndSubscriptions {
    return state!;
  }

  constructor(
    private memberService: ApiMemberService,
    private snackbar: SnackbarService
  ) { }

  @Action(MemberAction.GetMemberByPartnerId)
  getMemberByPartnerId(ctx: StateContext<MemberStateModel>, action: MemberAction.GetMemberByPartnerId) {
    return this.memberService.getMemberByPartnerId(action.partnerId, action.memberId).pipe(
      errorHandler(this.snackbar),
      tap((response: MemberAndSubscriptionsResponse) => ctx.setState(response.data))
    );
  }

}
