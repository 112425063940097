// background color
const action_dropdown_bg = '#F1F2F5';
const action_navigation_bg = '#C76262';
const action_grey_bg = '#F2F3F6';
const action_field_bg = '#F1F2F5';
const action_card_color = '#575F6B';

// font color
const action_grey_color = '#575F6B';


const actionDefault = {
  display: 'flex',
  'flex-flow': 'row nowrap',
  'justify-content': 'center',
  'align-items': 'center'
}

const actionSpaceBetween = {
  ...actionDefault,
  'justify-content': 'space-between',
}

export const actionDropdownFlex = {
  ...actionSpaceBetween,
  'padding': '0 24px 0 24px',
  'background': action_dropdown_bg,
  'color': action_grey_color,
  'font-weight': 'bold',
  'letter-spacing': 0,
  'height': '58.813px',
  'border-radius': '8px',
  '_icon': {
    'color': 'black',
    'font-size': '25px',
    'height': '25px',
    'width': '25px'
  }
}

export const actionDropdown = (width: number) => ({
  ...actionDropdownFlex,
  'width': `${width}px`
})

export const actionNavigation = {
  ...actionDefault,
  'background': action_navigation_bg,
  'color': 'white',
  'font-weight': 800,
  'font-size': '11.25px',
  'letter-spacing': 0,
  'width': '220px',
  'height': '49px',
  'border-radius': '49px',
  '_icon': {
    'font-weight': 600,
    'font-size': '13px',
    'height': '13px',
    'width': '13px',
    'margin-right': '15px'
  }
}

export const actionButton = {
  ...actionDefault,
  padding: '12px 48px',
  background: action_navigation_bg,
  color: 'white',
  'font-weight': 600,
  'font-size': '14px',
  height: '20px',
  'border-radius': '49px',
  '_icon': {
    'font-weight': 600,
    'font-size': '16px',
    height: '16px',
    width: '16px',
    'margin-right': '8px',
  }
}

export const actionGreyButton = {
  ...actionButton,
  background: action_grey_bg,
  color: action_grey_color
}

export const actionGrey = {
  ...actionNavigation,
  'background': action_grey_bg,
  'color': action_grey_color
}

export const actionSiteButton = {
  'background': '#E34564',
  'color': 'white',
  'font-size': '15.5px',
  'font-weight': '300',
  'letter-spacing': '0.5px',
  'padding': '14px 28px'
}

export const actionInfoButton = {
  ...actionDefault,
  'padding': '8px 12px',
  color: action_grey_color,
  background: action_grey_bg,
  'border-radius': '8px',
  'font-weight': 600,
  'font-size': '14px',
  '_icon': {
    'margin-right': '8px',
  }
}

/*
  SOC: Login page button
*/

export const vendorBackButton = {
  ...actionDefault,
  position: 'relative',
  padding: '19.5px 48px',
  background: action_field_bg,
  color: action_card_color,
  'font-weight': 600,
  'font-size': '14px',
  'border-radius': '10px',
  '_icon': {
    'font-weight': 600,
    'font-size': '20px',
    height: '20px',
    width: '20px',
    'margin-right': '12px',
  }
}

/*
  EOC: Login page button
*/

/*
  SOC: Login page button
*/

export const buttonSignInDefault = {
  ...actionDefault,
  position: 'relative',
  padding: '12px 48px',
  background: action_navigation_bg,
  color: 'white',
  'font-weight': 600,
  'font-size': '14px',
  'border-radius': '49px',
  '_icon': {
    'font-weight': 600,
    'font-size': '20px',
    height: '20px',
    width: '20px',
    'margin-right': '8px',
  }
}

/*
  EOC: Login page button
*/

/*
  SOC: Social media button styles
*/

const buttonSocialSignIn = {
  ...buttonSignInDefault,
  'border-radius': '49px*0.2',
  background: '#e6e6e6',
  color: action_grey_color,
  padding: '12px 0',
  width: '100%',
  '_icon': {
    ...buttonSignInDefault._icon,
    'font-size': '25px',
    height: '25px',
    width: '25px'
  }
}

export const buttonFacebookSignIn = {
  ...buttonSocialSignIn,
  '_icon': {
    ...buttonSocialSignIn._icon,
    color: '#3b5998'
  }
}

export const buttonGoogleSignIn = {
  ...buttonSocialSignIn,
  '_icon': {
    ...buttonSocialSignIn._icon,
    color: '#ea4335',
    'margin-right': '14px',
  }
}

/*
  EOC: Social media button styles
*/

/*
  SOC: Create Fitness Log Button Styles
*/

export const uploadFitnessLogButtonWorkout = {
  'background': '#E63946',
  'border-radius': '50px',
  'color': 'white',
  'font-size': '12px',
  'padding': '8px 32px'
};

export const uploadFitnessLogButtonNutrition = {
  ...uploadFitnessLogButtonWorkout,
  'background': '#669A65',
  '_class': 'uploadFitnessLogButtonNutrition'
};

/*
  EOC: Create Fitness Log Button Styles
*/



/*
  SOC: my web Subscriptions Button Styles
*/

export const outlineLightBlue = {
  'border': `1px solid #4A74A2`,
  'border-radius': '12px',
  'font-size': '11.7px',
  'color': '#4A74A2',
  'padding': '2px 8px',
  'width': '120px'
}

export const fullLightBlueMedium = {
  'background': '#4A74A2',
  'border-radius': '12px',
  'font-size': '11.7px',
  'padding': '4px 8px',
  'color': 'white',
  'width': '120px'
}

/*
  EOC: my web Subscriptions Button Styles
*/


/*
  SOC: my web bookings Button Styles
*/

export const outlinePink = {
  'border': `1px solid '#E63946'`,
  'border-radius': '12px',
  'font-size': '11.7px',
  'color': '#E63946',
  'padding': '2px 8px'
}

export const fullGreenMedium = {
  'background': '#669A65',
  'border-radius': '12px',
  'font-size': '11.7px',
  'padding': '4px 8px',
  'color': 'white'
}

/*
  EOC: my web bookings Button Styles
*/
