import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ActionType, ofActionCompleted, ActionCompletion, Store } from '@ngxs/store';
import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { tap } from 'rxjs';

import { PartnerState } from '@fitscovery/partners/data-access';
import { UserState } from '@fitscovery/user/data-access';
import { AzureLoggerService } from './azure-logger.service';
import { errorMessage } from '@fitscovery/common/providers';

export abstract class TrackingService {

  constructor(
    private store: Store,
    private actions$: Actions,
    private logger: AzureLoggerService
  ) { }

  protected trackDispatch(action: ActionType) {
    this.actions$.pipe(
      ofActionCompleted(action),
      tap((data: ActionCompletion<any, Error>) => data.result.successful
        ? this.trackEvent(action, data)
        : this.trackException(action, data))
    ).subscribe();
  }

  private trackEvent(action: ActionType, data: ActionCompletion<any, Error>): void {
    delete data.action.password;
    const traceEvent = () => this.logger.trackTrace(
      { message: `${action.type}` },
      this.customProperties(action, data, 'Success')
    );
    const trackEvent = () => this.logger.trackEvent(
      { name: `${action.type}` },
      this.customProperties(action, data, 'Success')
    );
    action.type.includes(' Get') ? traceEvent() : trackEvent();
  }

  private trackException(action: ActionType, data: ActionCompletion<any, Error>): void {
    this.logger.trackException(
      data.result.error?.message!,
      this.customProperties(action, data, 'Error', {
        Status: `${(data.result.error as HttpErrorResponse).status}`,
        ErrorMessage: errorMessage(data.result.error as HttpErrorResponse)
      })
    );
  }

  private customProperties(action: ActionType, data: ActionCompletion<any, Error>, status: 'Success' | 'Error', properties?: Record<string, string>): ICustomProperties {
    return {
      Name: `${status}: ${action.type}`,
      Payload: data.action,
      UserId: this.store.selectSnapshot(UserState.userId) || '',
      PartnerDomain: this.store.selectSnapshot(PartnerState.subdomain) || '',
      ...properties
    };
  }
 
}
