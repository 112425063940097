import { DrawerType } from "./store.model";

export namespace AdminAppStateAction {

  export class DropdownState {
    static readonly type = '[App State] DropdownState';
    constructor(public state: boolean) { }
  }

  export class SwitchPartnerState {
    static readonly type = '[App State] SwitchPartnerState';
    constructor(public state: boolean) { }
  }

  export class TabState {
    static readonly type = '[App State] TabState';
    constructor(public state: boolean) { }
  }

  export class DrawerState {
    static readonly type = '[App State] DrawerState';
    constructor(public state: boolean, public type: DrawerType) { }
  }

  export class ManageWebsiteUpdateState {
    static readonly type = '[App State] ManageWebsiteUpdateState';
    constructor(public state: boolean) { }
  }
  
  export class WebsiteChecklistState {
    static readonly type = '[App State] WebsiteChecklistState';
    constructor(public state: boolean) { }
  }

}
