import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import "animate.css";

import { AuthSocialMediaAuthEmailDialogModule } from '@fitscovery/auth/social-media-auth-email-dialog';
import { SharedRouteObserverModule } from '@fitscovery/shared/route-observer';
import { SharedNetworkConnectionObserverModule } from '@fitscovery/shared/network-connection-observer';
import { NavbarModule } from './features/navbar';
import { DrawerModule } from './features/drawer';
import { SidenavModule } from './features/sidenav';
import { FooterModule } from './features/footer';
import { WebsiteChecklistBubbleModule } from './features/website-checklist-bubble';
import { LoadingScreenModule } from '@fitscovery/shared/maintenance-page';

import { FeatureRootComponent } from './feature-root/feature-root.component';
import { environment } from 'apps/admin-web/src/environments/environment';

const MODULES = [
  CommonModule,
  RouterModule,
  FlexLayoutModule
];

const LIBRARIES = [
  SharedNetworkConnectionObserverModule,
  NavbarModule,
  DrawerModule,
  SidenavModule,
  FooterModule,
  WebsiteChecklistBubbleModule,
  LoadingScreenModule
];

const COMPONENTS = [
  FeatureRootComponent
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES,
    SharedRouteObserverModule.forRoot({ app: 'admin', environment }),
    AuthSocialMediaAuthEmailDialogModule.forRoot()
  ],
  declarations: [
    ...COMPONENTS
  ]
})
export class AdminWebFeatureRootModule {}
