import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FlexModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { UserPartnerDetailsModule } from '../user-partner-details';
import { NotificationFeatureNotificationButtonModule } from '@fitscovery/notification/feature-notification-button';
import { SharedAnimationToDefaultModule } from '@fitscovery/shared/animation-to-default';
import { SharedDummyImageModule } from '@fitscovery/shared/dummy-image';

import { SnackbarService } from '@fitscovery/ui/snackbar';
import { NavbarService } from './navbar.service';

import { NavbarComponent } from './navbar.component';
import { pipes } from './common';

const MODULES = [
  CommonModule,
  RouterModule,
  HttpClientModule,
  FlexModule,
  LazyLoadImageModule,
  OverlayModule,
  MatRippleModule,
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatTooltipModule
];

const LIBRARIES = [
  UserPartnerDetailsModule,
  SharedAnimationToDefaultModule,
  NotificationFeatureNotificationButtonModule,
  SharedDummyImageModule
];

const PROVIDERS = [
  NavbarService,
  SnackbarService
];

const COMPONENTS = [
  NavbarComponent,
  pipes
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES
  ],
  declarations: [
    ...COMPONENTS
  ],
  providers: [
    ...PROVIDERS
  ],
  exports: [
    NavbarComponent
  ]
})
export class NavbarModule {}
