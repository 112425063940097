import { Guid } from "@fitscovery/common/types";
import { CancellationPolicy, Links, Offerings } from "./partner.model";
import { UpdateAdminContactInfoRequest, UpdateAdminSettingsRequest } from "../interfaces";

export namespace PartnerAction {

  export class GetPartnerBySubdomain {
    static readonly type = '[Partners API] GetPartnerBySubdomain';
    constructor(public subdomain: string) { }
  }

  export class UpdatePartnerSettings {
    static readonly type = '[Partners API] UpdatePartnerSettings';
    constructor(public partnerId: Guid, public request: UpdateAdminSettingsRequest) { }
  }

  export class UpdatePartnerContactInfo {
    static readonly type = '[Partners API] UpdatePartnerContactInfo';
    constructor(public partnerId: Guid, public request: UpdateAdminContactInfoRequest) { }
  }

  export class UpdatePartnerOfferings {
    static readonly type = '[Partners API] UpdatePartnerOfferings';
    constructor(public partnerId: Guid, public request: Offerings) { }
  }

  export class UpdatePartnerLinks {
    static readonly type = '[Partners API] UpdatePartnerLinks';
    constructor(public partnerId: Guid, public request: Links) { }
  }

  export class UpdatePartnerCancellationPolicy {
    static readonly type = '[Partners API] UpdatePartnerCancellationPolicy';
    constructor(public partnerId: Guid, public request: CancellationPolicy) { }
  }

}
