import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { WINDOW } from '@ng-web-apis/common';
import { firstValueFrom } from 'rxjs';

import { SessionAction, SessionState } from '@fitscovery/auth/data-access';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { Token, TOKEN } from '../common';

@Component({
  selector: 'fitscovery-feature-origin',
  templateUrl: './feature-origin.component.html'
})
export class FeatureOriginComponent implements OnInit {

  redirecting: boolean;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(TOKEN) private token: Token,
    private router: Router,
    private store: Store,
    private snackbar: SnackbarService
  ) { }
  
  async ngOnInit(): Promise<void> {

    this.redirecting = true;

    try {
      if (this.store.selectSnapshot(SessionState.authenticated)) {
        return this.navigate();
      }
      await firstValueFrom(this.store.dispatch(new SessionAction.CheckSession));
      await firstValueFrom(this.store.dispatch(new SessionAction.SignInWithCustomToken(
        this.store.selectSnapshot(SessionState.customToken)
      )));
      this.navigate();
    } catch (error) {
      if (error.status) {
        this.snackbar.destroyAll();
        this.redirectToAuth();
      } else {
        this.redirecting = false;
      }
    }
  }

  navigate(): void {
    this.router.navigate([ this.token.route ], {
      queryParamsHandling: 'preserve',
      queryParams: {
        authenticated: null
      }
    });
  }

  redirectToAuth(): void {
    switch (this.token.app) {
      case 'admin': {
        localStorage.setItem('from_auth', '1');
        const origin = this.token.environment.fitscoveryApps.adminUrl;
        this.window.location.href = `${this.token.environment.fitscoveryApps.accountUrl}/auth/login/?origin=${origin}`;
        break;
      }
      case 'my': {
        const origin = this.token.environment.fitscoveryApps.myDiscoveryUrl;
        this.window.location.href = `${this.token.environment.fitscoveryApps.accountUrl}/auth/login/?origin=${origin}`;
        break;
      }
      case 'onboarding': {
        const origin = this.token.environment.fitscoveryApps.onboardingUrl;
        this.window.location.href = `${this.token.environment.fitscoveryApps.accountUrl}/auth/login/?origin=${origin}`;
        break;
      }
    }
  }

}
