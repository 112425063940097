import { App } from '@fitscovery/common/types';
import { Environment, EnvironmentKeys } from './environment.interface';
import * as dev from './environment.dev';

export function keys(app: App): EnvironmentKeys {
  return {
    get environment(): Environment {
      return {
        ...dev.keys(app).environment,
        local: true,
        fitscoveryApps: {
          accountUrl: 'http://localhost:4201',
          onboardingUrl: 'http://localhost:4202',
          adminUrl: 'http://localhost:4203',
          myDiscoveryUrl: 'http://localhost:4204',
          backOfficeUrl: 'http://localhost:4205',
          embedUrl: 'http://localhost:4206'
        },
        nodeApi: {
          ...dev.keys(app).environment.nodeApi,
          auth: 'http://localhost:8080/api/v1'
        }
      }
    }
  }
};
