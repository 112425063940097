import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GetPartnerPaymentMethodsResponse } from '../interfaces';
import { Guid } from '@fitscovery/common/types';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiPartnersService {
    
  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getPartnerPaymentMethods(partnerId: Guid): Observable<GetPartnerPaymentMethodsResponse> {
    return this.http.get<GetPartnerPaymentMethodsResponse>(`${this.env.nodeApi.payments}/partners/${partnerId}/payment-methods`);
  }

  updatePartnerPaymentMethods(partnerId: Guid, paymentMethodIds: string[]): Observable<any> {
    return this.http.put<any>(`${this.env.nodeApi.payments}/partners/${partnerId}/payment-methods`, { paymentMethodIds });
  }

}
