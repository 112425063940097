import { ClassProvider, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Store } from '@ngxs/store';
import { firstValueFrom, Observable, of } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { SessionAction, SessionState } from '@fitscovery/auth/data-access';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	constructor (
    private auth: AngularFireAuth,
    private store: Store
  ) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.store.selectSnapshot(SessionState.accessToken);

    if (!token) return next.handle(request);

    return this.isTokenExpired(token)
      ? this.refreshAndSetNewTokenToState(request, next) 
      : this.reuseToken(request, next, token);
	}

  private reuseToken(request: HttpRequest<any>, next: HttpHandler, token: string): Observable<HttpEvent<any>> {
    const headers = request.clone({ setHeaders: { Authorization: token } });
    return next.handle(headers)
  }

  private refreshAndSetNewTokenToState(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of(null).pipe(
      first(),
      switchMap(() => this.getAndSetNewTokenToState()),
      map((token: string) => (!!token ? request.clone({ setHeaders: { Authorization: token } }) : request)),
      mergeMap((request: HttpRequest<any>) => next.handle(request))
    );
  }

  async getAndSetNewTokenToState(): Promise<string> {
    const currentUser = await firstValueFrom(this.auth.authState);
    const token = await currentUser?.getIdToken(true);
    const stateToken = this.store.selectSnapshot(SessionState.accessToken);
    if (this.isTokenExpired(stateToken)) {
      await firstValueFrom(this.store.dispatch(new SessionAction.SetAccessToken(token!)));
    }
    return token || stateToken;
  }

  private isTokenExpired(token: string): boolean {
    const helper = new JwtHelperService();
    return helper.isTokenExpired(token);
  }

}

export const TokenInterceptorProvider: ClassProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: TokenInterceptor,
	multi: true
};
