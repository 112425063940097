import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard, NotAuthenticatedGuard } from '@fitscovery/admin-web/utils/route-guards';
import { AuthFeatureOriginModule } from '@fitscovery/auth/feature-origin';

import { environment } from 'apps/admin-web/src/environments/environment';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@fitscovery/auth/feature-origin').then(m => m.AuthFeatureOriginModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('@fitscovery/admin-web/dashboard/feature-dashboard-shell').then(m => m.AdminWebDashboardFeatureDashboardShellModule),
    canActivate: [ AuthenticatedGuard ]
  },
  {
    path: 'manage-website',
    loadChildren: () => import('@fitscovery/admin-web/manage-website/feature-manage-website-shell').then(m => m.AdminWebManageWebsiteFeatureManageWebsiteShellModule),
    canActivate: [ AuthenticatedGuard ]
  },
  {
    path: 'analytics',
    loadChildren: () => import('@fitscovery/admin-web/analytics/feature-analytics-shell').then(m => m.AdminWebAnalyticsFeatureAnalyticsShellModule),
    canActivate: [ AuthenticatedGuard ]
  },
  {
    path: 'memberships',
    loadChildren: () => import('@fitscovery/admin-web/memberships/feature-shell').then(m => m.AdminWebMembershipsFeatureShellModule),
    canActivate: [ AuthenticatedGuard ]
  },
  {
    path: 'settings',
    loadChildren: () => import('@fitscovery/admin-web/settings/feature-settings-shell').then(m => m.AdminWebSettingsFeatureSettingsShellModule),
    canActivate: [ AuthenticatedGuard ]
  },
  {
    path: 'integrations',
    loadChildren: () => import('@fitscovery/admin-web/integrations/feature-integrations-shell').then(m => m.AdminWebIntegrationsFeatureIntegrationsShellModule),
    canActivate: [ AuthenticatedGuard ]
  },
  {
    path: 'attendance',
    loadChildren: () => import('@fitscovery/admin-web/attendance/feature-attendance-shell').then(m => m.AdminWebAttendanceFeatureAttendanceShellModule),
    canActivate: [ AuthenticatedGuard ]
  },
  {
    path: 'events',
    loadChildren: () => import('@fitscovery/admin-web/events/feature-shell').then(m => m.AdminWebEventsFeatureShellModule),
    canActivate: [ AuthenticatedGuard ]
  },
  {
    path: 'workouts',
    loadChildren: () => import('@fitscovery/admin-web/workouts/feature-shell').then(m => m.AdminWebWorkoutsFeatureShellModule),
    canActivate: [ AuthenticatedGuard ]
  },
  {
    path: 'bookings',
    loadChildren: () => import('@fitscovery/admin-web/bookings/feature-bookings-shell').then(m => m.AdminWebBookingsFeatureBookingsShellModule),
    canActivate: [ AuthenticatedGuard ]
  },
  {
    path: 'maintenance',
    loadChildren: () => import('@fitscovery/shared/maintenance-page').then(m => m.SharedMaintenancePageModule),
  },
  {
    path: '**',
    loadChildren: () => import('@fitscovery/shared/page-not-found').then(m => m.SharedPageNotFoundModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules
    }),
    AuthFeatureOriginModule.forRoot({
      environment,
      app: 'admin',
      route: '/dashboard'
    })
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthenticatedGuard,
    NotAuthenticatedGuard
  ]
})
export class AppRoutingModule { }
