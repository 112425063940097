import { Pipe, PipeTransform } from '@angular/core';

import { MenuOption } from '../common';

@Pipe({
  name: 'util'
})
export class UtilPipe implements PipeTransform {

  transform(path: string, data: MenuOption): unknown {
    path = path?.split('?')[0];
    const route = data?.route?.split('?')[0];
    return data.type === 'menu' && route === path;
  }

}
