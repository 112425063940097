import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dummy-image',
  templateUrl: './dummy-image.component.html',
  styleUrls: ['./dummy-image.component.scss'],
})
export class DummyImageComponent implements OnInit {

  @Input() value: string;
  @Input() size: string;
  @Input() box: string;
  @Input() responsive: string;

  constructor() { }

  ngOnInit(): void {
  }

}
