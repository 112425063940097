import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetInvoicesRequest,
  GetInvoicesResponse,
  GetInvoicesCountRequest,
  GetInvoicesCountResponse,
} from '../dtos';
import { APP_ENVIRONMENT } from '../environment-injection';
import { Invoice } from '../models';

@Injectable()
export class ApiInvoiceService {

  constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {
  }

  getInvoicesCount(requestQuery: GetInvoicesCountRequest): Observable<number> {
    const query = qs.stringify(requestQuery);
    return this.http.get(`${this.env.nodeApi.payments}/invoices/count${query ? `?${query}` : ''}`).pipe(
      map((result: Object) => {
        return (result as GetInvoicesCountResponse).data.count
      })
    )
  }

  getInvoices(requestQuery: GetInvoicesRequest): Observable<Invoice[]> {
    const query = qs.stringify(requestQuery);
    return this.http.get(`${this.env.nodeApi.payments}/invoices${query ? `?${query}` : ''}`).pipe(
      map((result: Object) => {
        return (result as GetInvoicesResponse).data.invoices
      })
    )
  }
}
