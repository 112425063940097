import { Metadata, EventData } from '../interfaces';
import { ApplicationProvider } from './application.provider';

export class AdminWebProvider extends ApplicationProvider {

  constructor(eventData: EventData) {
    super(eventData);
  }

  get routeMetadata(): Metadata {
    return {
      ...this.customMetadata,
      ...this.defaultMetadataConfig
    }
  }

  private get customMetadata(): Metadata | undefined {

    const path = this.defaultMetadataConfig.url!;

    if (path.includes('auth/login')) return {
      title: 'Admin Portal',
      description: '',
    }
    if (path.includes('auth/recovery')) return {
      title: 'Forgot password | Admin Portal',
      description: '',
    }
    if (path.includes('dashboard')) return {
      title: 'Dashboard | Admin Portal',
      description: '',
    }
    if (path.includes('manage-website')) return {
      title: 'Manage Website | Admin Portal',
      description: '',
    }
    if (path.includes('analytics')) return {
      title: 'Analytics | Admin Portal',
      description: '',
    }
    if (path.includes('settings') && path.includes('active_tab=business')) return {
      title: 'Business Account | Admin Portal',
      description: '',
    }
    if (path.includes('settings') && path.includes('active_tab=payments')) return {
      title: 'Payment Information | Admin Portal',
      description: '',
    }
    if (path.includes('settings') && path.includes('active_tab=customize_offerings')) return {
      title: 'Customize Offerings | Admin Portal',
      description: '',
    }
    if (path.includes('settings')) return {
      title: 'Business Account | Admin Portal',
      description: '',
    }
    if (path.includes('integrations') && path.includes('active_tab=links')) return {
      title: 'Links | Admin Portal',
      description: '',
    }
    if (path.includes('integrations') && path.includes('active_tab=embedding')) return {
      title: 'Embedding | Admin Portal',
      description: '',
    }
    if (path.includes('integrations')) return {
      title: 'Links | Admin Portal',
      description: '',
    }
    if (path.includes('memberships/subscription') && path.includes('#create')) return {
      title: 'Create Subscription',
      description: '',
    }
    if (path.includes('memberships/subscription') && path.includes('#update')) return {
      title: 'Edit Subscription',
      description: '',
    }
    if (path.includes('memberships')) return {
      title: 'Memberships | Admin Portal',
      description: '',
    }
    if (path.includes('events/create') && path.includes('#duplicate-event')) return {
      title: 'Duplicate Event',
      description: '',
    }
    if (path.includes('events/create')) return {
      title: 'Create Event',
      description: '',
    }
    if (path.includes('events/update')) return {
      title: 'Update Event',
      description: '',
    }
    if (path.includes('events')) return {
      title: 'Schedules | Admin Portal',
      description: '',
    }
    if (path.includes('attendance')) return {
      title: 'Attendance Tracking | Admin Portal',
      description: '',
    }
    if (path.includes('workouts')) return {
      title: 'On-Demand Workouts | Admin Portal',
      description: '',
    }
    if (path.includes('workouts/create')) return {
      title: 'Create Workouts',
      description: '',
    }
    if (path.includes('workouts/create/program')) return {
      title: 'Create Workout Program',
      description: '',
    }
    if (path.includes('bookings')) return {
      title: 'Direct Bookings | Admin Portal',
      description: '',
    }
    if (path.includes('workouts/update/program')) return {
      title: 'Update Workout Program',
      description: '',
    }
    if (path.includes('workouts/update')) return {
      title: 'Update Workouts',
      description: '',
    }
    return undefined;
  }

}
