
<loading-screen></loading-screen>

<drawer routeObserver networkPrompt authNoEmailObserver *ngIf="maintenancePage.loaded$ | async">

  <div id="app-root" fxLayout="row wrap" fxLayoutAlign="center" [ngStyle]="{ 'padding-top': (authenticated$ | async) ? '80px' : '0' }" [@opacityFade]>

    <navbar
      class="navbar"
      *ngIf="authenticated$ | async">
    </navbar>

    <sidenav
      class="sidenav"
      *ngIf="authenticated$ | async">
    </sidenav>

    <div [fxFlex]="(authenticated$ | async) ? '0 1 830px' : ''">
      <router-outlet></router-outlet>
      <footer *ngIf="authenticated$ | async"></footer>
    </div>
   
    <website-checklist-bubble
      *ngIf="authenticated$ | async">
    </website-checklist-bubble>

  </div>

</drawer>
