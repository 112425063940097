<div id="footer" fxLayout="row wrap" fxLayoutAlign="center center">
<div fxFlex="0 1 1130px" fxLayout="row" fxLayoutAlign="space-between center">
  
  <div fxLayout="row">
    <p class="brand">©{{ currentYear }} <span><a href="https://fitscovery.com/" target="_break">Fitscovery</a></span>. All Rights Reserved</p>
  </div>
  <div fxLayout="row">
    <p class="menu first"><a href="https://fitscovery.com/about" target="_break">About</a></p>
    <p class="pipe first">|</p>
    <p class="menu second"><a href="https://fitscovery.com/terms-and-conditions" target="_break">Terms & Conditions</a></p>
    <p class="pipe second">|</p>
    <p class="menu third"><a href="https://fitscovery.com/privacy-policy" target="_break">Privacy Policy</a></p>
    <p class="pipe third">|</p>
    <p class="menu fourth"><a href="mailto:hello@fitscovery.com">Report an Issue</a></p>
  </div>

</div>
</div>
  