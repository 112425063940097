import { ModuleWithProviders, NgModule } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import { NgxsModule } from '@ngxs/store';

import { SnackbarService } from '@fitscovery/ui/snackbar';

import { OnboardingStoreState } from './store';
import { APP_ENVIRONMENT } from '../lib/environment-injection';

const PROVIDERS = [
  SnackbarService
];

@NgModule({
  imports: [
    NgxsModule.forFeature([ OnboardingStoreState ])
  ],
  providers: [
    ...PROVIDERS
  ]
})
export class OnboardingWebSharedStoreModule {
  public static forRoot(environment: Environment): ModuleWithProviders<OnboardingWebSharedStoreModule> {
    return {
      ngModule: OnboardingWebSharedStoreModule,
      providers: [
        { provide: APP_ENVIRONMENT, useValue: environment }
      ]
    };
  }
}
