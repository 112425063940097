import { PaginationRequest } from "@fitscovery/common/types";

export namespace AdminPartnersAction {

  export class GetAdminPartners {
    static readonly type = '[Partners API] GetAdminPartners';
    constructor(public request: Partial<PaginationRequest>) { }
  }

}
