import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiAccountService } from '../services';
import { AccountsAction } from '../actions';
import { AccountStateModel, AuthDetailsResponse, DecodedToken, UserAccount } from '../models';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { errorHandler } from '@fitscovery/common/providers';

export const ACCOUNT_STATE_TOKEN = new StateToken<AccountStateModel>('api_auth_account');

@State<AccountStateModel>({
	name: ACCOUNT_STATE_TOKEN,
  defaults: null
})

@Injectable()
export class AccountState implements NgxsOnInit {

	@Selector()
	static user(state: AccountStateModel): UserAccount {
		return state!.user;
	}

	@Selector()
	static decodedToken(state: AccountStateModel): DecodedToken {
		return state!.decodedToken;
	}

	@Selector()
	static hasNoEmail(state: AccountStateModel): boolean {
		return !state!.user.email;
	}

	constructor(
    private account: ApiAccountService,
    private snackbar: SnackbarService
  ) { }

	ngxsOnInit(ctx: StateContext<AccountStateModel>): void {
	}

  @Action(AccountsAction.GetAuthDetails)
	getAuthDetails(ctx: StateContext<AccountStateModel>) {
    return this.account.getAuthDetails().pipe(
      errorHandler(this.snackbar),
      tap((response: AuthDetailsResponse) => ctx.setState(response.data))
    );
  }

  @Action(AccountsAction.UpdateEmail)
	updateEmail(_: StateContext<AccountStateModel>, action: AccountsAction.UpdateEmail) {
    return this.account.updateEmail(action.email).pipe(
      errorHandler(this.snackbar)
    );
  }

}
