import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';

import { ApiPartnerWebsitesService, ApiAdminPartnerWebsitesService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { PartnerWebsiteAction, PartnerWebsiteStateModel, PartnerWebsite, PartnerImageDescription, MediaIdentifier } from '.';
import { GetPartnerWebsiteBySubdomainResponse, UpdateAdminBannerResponse, UpdateAdminMediaResponse, UpdateAdminSocialMediaLinksResponse } from '../interfaces';
import { errorHandler } from '@fitscovery/common/providers';
import { parseBanner } from './partner-website.helper';
import { Guid } from '@fitscovery/common/types';

export const partnerWebsitesStateDefaults: PartnerWebsiteStateModel = null;

export const PARTNER_WEBSITES_STATE_TOKEN = new StateToken<PartnerWebsiteStateModel>('api_partner_websites');

@State<PartnerWebsiteStateModel>({
  name: PARTNER_WEBSITES_STATE_TOKEN,
  defaults: partnerWebsitesStateDefaults
})

@Injectable()
export class PartnerWebsiteState {

  @Selector()
  static partnerWebsite(state: PartnerWebsiteStateModel): PartnerWebsite {
    return state!;
  }

  @Selector()
  static partnerId(state: PartnerWebsiteStateModel): Guid {
    return state?.partnerId!.toString().toLowerCase()! as any
  }

  @Selector()
  static subdomain(state: PartnerWebsiteStateModel): string {
    return state?.subdomain!;
  }

  @Selector()
  static banner(state: PartnerWebsiteStateModel): PartnerImageDescription {
    return state?.imageDescriptions.find((imageDescription: PartnerImageDescription) => {
      return imageDescription.identifier === MediaIdentifier.BannerImage
    })!;
  }

  static media(identifier: MediaIdentifier): (state: PartnerWebsiteStateModel) => PartnerImageDescription {
    return createSelector([PartnerWebsiteState], (state: PartnerWebsiteStateModel): PartnerImageDescription => {
      return state?.imageDescriptions.find((imageDescription: PartnerImageDescription) => {
        return imageDescription.identifier === identifier
      })!;
    });
  }

  constructor(
    private partnerWebsites: ApiPartnerWebsitesService,
    private adminPartnerWebsites: ApiAdminPartnerWebsitesService,
    private snackbar: SnackbarService
  ) { }

  @Action(PartnerWebsiteAction.GetPartnerWebsiteBySubdomain)
  getPartnerWebsiteBySubdomain(ctx: StateContext<PartnerWebsiteStateModel>, action: PartnerWebsiteAction.GetPartnerWebsiteBySubdomain) {
    return this.partnerWebsites.getPartnerWebsiteBySubdomain(action.subdomain).pipe(
      errorHandler(this.snackbar),
      tap((response: GetPartnerWebsiteBySubdomainResponse) => ctx.setState(response.data.partner_website))
    );
  }

  @Action(PartnerWebsiteAction.UpdatePartnerWebsiteLogo)
  updatePartnerWebsiteLogo(ctx: StateContext<PartnerWebsiteStateModel>, action: PartnerWebsiteAction.UpdatePartnerWebsiteLogo) {
    return this.adminPartnerWebsites.updateAdminLogo(action.partnerId, action.request).pipe(
      errorHandler(this.snackbar),
      tap(() => ctx.patchState({ logoUrl: action.request.logoUrl }))
    );
  }

  @Action(PartnerWebsiteAction.UpdatePartnerWebsiteBanner)
  updatePartnerWebsiteBanner(ctx: StateContext<PartnerWebsiteStateModel>, action: PartnerWebsiteAction.UpdatePartnerWebsiteBanner) {
    return this.adminPartnerWebsites.updateAdminBanner(action.partnerId, action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: UpdateAdminBannerResponse) => ctx.setState(parseBanner(ctx, response)))
    );
  }

  @Action(PartnerWebsiteAction.UpdatePartnerWebsiteMedia)
  updatePartnerWebsiteMedia(ctx: StateContext<PartnerWebsiteStateModel>, action: PartnerWebsiteAction.UpdatePartnerWebsiteMedia) {
    return this.adminPartnerWebsites.updateAdminMedia(action.partnerId, action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: UpdateAdminMediaResponse) => ctx.setState(response.data.partner_website))
    );
  }

  @Action(PartnerWebsiteAction.UpdatePartnerWebsiteSocialMedia)
  updatePartnerWebsiteSocialMedia(ctx: StateContext<PartnerWebsiteStateModel>, action: PartnerWebsiteAction.UpdatePartnerWebsiteSocialMedia) {
    return this.adminPartnerWebsites.updateAdminSocialMediaLinks(action.partnerId, action.request).pipe(
      errorHandler(this.snackbar),
      tap((response: UpdateAdminSocialMediaLinksResponse) => ctx.patchState({ socialMediaLinks: response.data.partner_website.socialMediaLinks }))
    );
  }

}
